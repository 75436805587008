
import { Fragment } from 'react'
import Link from 'next/link'
import { PrismicNextLink } from '@prismicio/next'


interface TotalsProps {
    uid?: any | null
    megaMenus?: any | null
    type?: any | null
    profile?: any | null
}

const Totals:React.FC<TotalsProps>  = ({ uid, megaMenus, type, profile }) => {
    

    return (
        <section className="w-screen fixed top-0 bg-white z-[21]">
            <div className="max-w-[1440px] mx-auto px-4 lg:pr-4 h-[72px] md:h-[68px] flex items-center relative">
                <ul className="relative w-full">
                    
                </ul>
            </div>
        </section>
    )
}

export default Totals
