'use client'
import { useState, useRef, useEffect } from 'react' 
import NextImage from 'next/image'
import { getCountryData } from '@/components/getCountryData'
import Calendar from 'react-calendar';
import { ImageCropper } from '@/components'
import Collection from '@/assets/icons/collection.svg'
import Category from '@/assets/icons/category.svg'
import Arrow from '@/assets/icons/right-arrow.svg'
import Close from '@/assets/icons/close.svg'
import _ from 'lodash'
import Moment from 'moment'

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

interface PromotionPanelAdminProps {
    lang?: any | null
    dataSet?: any | null
    uid?: any | null
}

    const PromotionPanelAdmin:React.FC<PromotionPanelAdminProps>  = ({ lang, dataSet, uid }) => {

        // console.log(dataSet)

        // setup defaults
        const details = dataSet && dataSet[0] ? dataSet[0]?.details_json : null;
        const products = dataSet && dataSet[0] ? dataSet[0]?.rules_json?.products : null;
        const rules = dataSet && dataSet[0] ? dataSet[0]?.rules_json?.rules : null;
        const all = dataSet && dataSet[0] ? dataSet[0]?.rules_json : null;
        const schedule = dataSet && dataSet[0] ? dataSet[0]?.rules_json?.schedule : null;
        const status = dataSet && dataSet[0] ? dataSet[0]?.status : null;

        const { currency, priceColumn } = getCountryData(lang);

        const [promotionType, setPromotionType] = useState(details?.type ? details.type : null) as any;
        const [productResults, setProductResults] = useState([]) as any;
        const [projectResults, setProjectResults] = useState([]) as any;
        const [categoryResults, setCategoryResults] = useState([]) as any;
        const [productSelect, setProductSelect] = useState(false) as any;
        const [projectSelect, setProjectSelect] = useState(false) as any;
        const [categorySelect, setCategorySelect] = useState(false) as any;
        const [promotionName, setPromotionName] = useState(details?.name ? details.name : "Name") as any
        const [promotionAdditionalTerms, setPromotionAdditionalTerms] = useState() as any;
        const [productSet, setProductSet] = useState(products ? products : []) as any
        const [ruleSet, setRuleSet] = useState(rules ? rules : {}) as any
        const [promotionDescription, setPromotionDescription] = useState(details?.description ? details.description : "") as any
        const [scheduleSet, setScheduleSet] = useState<Value>(schedule ? schedule : new Date()) as any
        const [stage, setStage] = useState(1)
        const [vouchers, setVouchers] = useState([]) as any
        const [voucherCheck, setVoucherCheck] = useState(false)
        const [promotionImage, setPromotionImage] = useState(details?.image ? details.image : null) as any 
        const [promotionCode, setPromotionCode] = useState() as any
        const [promotionButton, setPromotionButton] = useState(details?.button ? details?.button : "") as any
        const [promotionLink, setPromotionLink] = useState(details?.link ? details?.link : "") as any
        const [showPopOverPreview, setShowPopOverPreview] = useState(false)
        const [promotionTheme, setPromotionTheme] = useState(details?.theme ? details.theme : false) // false is dark : true is light
        const [promotionStatus, setPromotionStatus] = useState(status ? status : false)
        const [saved, setSaved] = useState(false)
        const [alwaysOn, setAlwaysOn] = useState(all?.always_on ? all?.always_on : false)
        const [hideOffer, setHideOffer] = useState(all?.hide_offer ? all?.hide_offer : "")
        const [promotionEmail, setPromotionEmail] = useState(details?.email ? details.email : false)
        const [emailValue, setEmailValue] = useState(false)

        const imageCropperRef = useRef() as any

        const toggleSwitch = () => {
            setPromotionTheme(!promotionTheme);
        };

        const searchProducts = async (name: string) => {
            if(name.length >=3) {
                setProductSelect(true)
                const searchProducts = await fetch(`/api/promotions/products?prod=${name}`);
                const productsResult = await searchProducts.json();
                setProductResults(productsResult)
            }
        }

        const searchProjects = async () => {
            setProjectSelect(true)
            if(projectResults?.length < 1) {
                const searchProjects = await fetch(`/api/promotions/projects`);
                const projectResult = await searchProjects.json();
                setProjectResults(projectResult)
            }
        }

        const searchCategories = async () => {
            setCategorySelect(true)
            if(categoryResults?.length < 1) {
                const searchCategories = await fetch(`/api/promotions/categories`);
                const categoriesResult = await searchCategories.json();
                setCategoryResults(categoriesResult)
            }
        }

        const setAllProducts = async() => {
            setCategorySelect(false)
            setProjectSelect(false)
            setProductSelect(false)
            if(productSet[0] === "all") {
                setProductSet([])
            }
            else {
                setProductSet(['all'])
            }
        }

        const addToPromotion =(item: any) => {

            setCategorySelect(false)
            setProjectSelect(false)
            setProductSelect(false)
            const productList = [...productSet]

            if (_.includes(productList, "all")) {
                _.remove(productList, (element) => element === "all");
            }
            
            if(item?.categorydata?.id) {
                    // console.log('itemcat: ', item)
                    productList.push({category: {
                                        id: item?.categorydata?.id,
                                        name: item?.categorydata?.name
                                    }})
            }
            if(item?.projectname){
                    productList.push(item)
            }
            if(item?.productdata) {
                    const trimmedProduct = {
                            productdata: {
                                id: item?.productdata?.id,
                                name: item?.productdata?.name,
                                images: [{
                                    link: item?.productdata?.images[0]?.link
                                }],
                                productOptions: [
                                    {
                                        id: item?.productdata?.productOptions[0]?.id,
                                        image: item?.productdata?.productOptions[0]?.image,
                                        option1: item?.productdata?.productOptions[0]?.option1,
                                    }
                                ]
                            }
                    } as any
                    const itemToAdd = trimmedProduct?.productdata?.productOptions[0]?.id ? trimmedProduct : item
                    productList.push(itemToAdd)
            }
            setProductSet(productList)
            return null
        }

        const loadPromotion = async (id: any) => {
            // console.log('clicked')
            return null
        }

        const getVouchers = async () => {
            const searchVouchers = await fetch(`/api/klaviyo/coupons`);
            const vouchersResult = await searchVouchers.json();
            vouchersResult?.length > 0  ? setVouchers(vouchersResult) : setVoucherCheck(true)
            
        } 

        const formatDate = (date: Date | null) => {
            return date ? Moment(date).format('MMMM Do YYYY') : 'Not set';
        }

        const addToRules = (item: any, value: any) => {
            const tempRules = {...ruleSet} as any
            
            tempRules.type = promotionType

            switch(item) {
                case 'amount':
                    tempRules.amount = Number(value);
                break;
                case 'min':
                    tempRules.min = Number(value);
                break;
                case 'max':
                    tempRules.max = Number(value);
                break;
                case 'voucher':
                    tempRules.voucher = value;
                break;
                default:
            }
            setRuleSet(tempRules)
        }

        const removeItem = (index: any) => {
            const productList = [...productSet]
            _.pullAt(productList, index);
            setProductSet(productList)
        }

        const handleGetFile = () => {
            if (imageCropperRef.current) {
                const file = imageCropperRef.current.getFile();
                if (file) {
                    // console.log('Selected File:', file);
                    // You can now perform any operations with the file, like uploading it to a server
                    return file;
                } else {
                    // console.log('No file selected');
                }
            }
        };

        
        const savePromotion = async (phase: any) => {

            const saveState = phase === 'draft' ? false : phase === 'publish' ? true : false

            const file = handleGetFile();
            
            
            const defSchedule = [Moment().subtract(1,'days').format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"), Moment().add(20,'years').format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")];

        
            const saveData = {
                id: dataSet && dataSet[0]?.id && phase !== "duplicate" ? dataSet[0].id : null,
                status: saveState,
                rules_json: {
                    rules: ruleSet,
                    schedule: alwaysOn === true ? defSchedule : scheduleSet,
                    products: productSet,
                    voucher: promotionCode,
                    hide_offer: hideOffer,
                    always_on: alwaysOn
                },
                details_json: {
                    name: phase === "duplicate" ? "Copy of "+promotionName : promotionName,
                    type: promotionType,
                    description: promotionDescription,
                    terms: promotionAdditionalTerms,
                    theme: promotionTheme, 
                    email: promotionEmail,
                    image: promotionImage,
                    button: promotionButton, 
                    link: promotionLink
                }
            } as any

            // Create a FormData object and append the blob and JSON data
            const formData = new FormData();
            file?.name ? formData.append('file', file, promotionName+file.name) : null // only sed a file if a new one exists
            formData.append('json', JSON.stringify(saveData)); // Append JSON data as a string


            // console.log(formData)

            const saveRequest = await fetch('/api/promotions', {method: "post", body: formData})
            const saveResult = await saveRequest.json();

            // console.log(saveResult)

            setPromotionStatus(saveState)
            setSaved(true);

            phase === "duplicate" ? window.open(`/manage/promotions/${saveResult.id}`) : null;

            return null
        }

        useEffect(()=>{
            if(promotionName !== "Name" && promotionType) {
                setStage(2)
                if(productSet.length > 0) {
                    setStage(3)
                    if(ruleSet.amount > 0) {
                        setStage(4)
                        if(scheduleSet.length > 0) {
                            setStage(5)
                        }
                    }
                }
            }

        },[promotionName,promotionType,productSet, ruleSet, scheduleSet])

        // console.log('RR: ', rules ,rules?.voucher )
        return (
            <>
            {showPopOverPreview == true ? 
                <div className="z-40 fixed top-0 left-0 w-screen h-screen bg-warmcharcoal/50 flex items-center justify-center">
                    <div className="relative pb-16">
                        <div className="absolute bottom-0 left-[45%] flex items-center justify-center bg-offwhite/60 p-2 px-8 rounded-md font-norma text-[14px[ font-medium">
                            Theme <div
                                className={`relative ml-4 inline-block w-12 h-6 transition duration-200 ease-in ${
                                    promotionTheme ? 'bg-offwhite' : 'bg-warmgrey'
                                } rounded-full cursor-pointer`}
                                onClick={toggleSwitch}
                            >
                                <span
                                    className={`absolute left-1 top-1 w-4 h-4 bg-warmcharcoal rounded-full transition transform duration-100 ease-in ${
                                        promotionTheme ? 'translate-x-6' : ''
                                    }`}
                                />
                            </div>
                        </div>
                        <div className={"max-w-[880px] w-screen h-[463px] p-6 relative " + (promotionTheme === true ? "bg-offwhite text-warmcharcoal" : "bg-warmcharcoal text-offwhite")}>
                            <p onClick={()=>setShowPopOverPreview(!showPopOverPreview)} className="cursor-pointer hover:opacity-60 absolute right-4 top-4 z-[102]  text-[14px] font-norma ">Close <Close className={"inline-block ml-2 " + (promotionTheme === true ? "stroke-warmcharcoal" : "stroke-offwhite")}/></p>
                            <img src={promotionImage} className="inline-block object-cover"/>
                            <div className="inline-block absolute top-0 right-12 p-6 w-[280px] pt-[80px] h-full">
                                <h2 className="font-sangbleu font-medium text-[21px] leading-[32px] mb-8">{promotionName}</h2>
                                <p className="font-norma text-[16px] leading-[22px]">{promotionDescription}</p>
                                {promotionCode && promotionCode !=="" ? 
                                <p className="font-norma text-[24px] leading-[22px] mt-12">{promotionCode}</p>
                                : <></>}
                                {promotionEmail === true ? 
                                <input type="text" className=" h-[40px] mt-8 border-none p-4 w-full font-norma text-[14px] focus:outline-none focus:ring-[1px] focus:ring-warmcharcoal focus:ring-opacity-80 text-warmcharcoal" placeholder="Email address" onChange={(e: any) => { setEmailValue(e.target.value) }}/>   
                                : <></>}         
                                <button type="button" className={"absolute bottom-6 left-6 border-[1px] h-[40px] px-4 text-[16px] font-norma font-medium w-48 text-left " + (promotionTheme === true ? "border-warmcharcoal" : "border-offwhite")}>Get offer <Arrow className={" inline-block rotate-180 ml-[60px] " + (promotionTheme === true ? "stroke-warmcharcoal" : "stroke-offwhite")}/></button>
                            </div>
                        </div>
                    </div>
                </div>
            : <></>}
            <div className="grid grid-cols-12 gap-5 mx-4 lg:mx-[50px]">
                <div className="col-span-12 md:col-span-2 pr-8">
                    <h3 className="font-norma font-medium text-[16px] "><span className="bg-warmcharcoal rounded-full text-offwhite inline-block w-6 h-6 text-center mr-2">1</span>Create</h3>
                    <input type="text" className=" h-[40px] mt-8 border-none p-4 w-full font-norma text-[14px] focus:outline-none focus:ring-[1px] focus:ring-warmcharcoal focus:ring-opacity-80" placeholder="promotion Name" defaultValue={details?.name ? details.name : promotionName} onBlur={(e: any) => { setPromotionName(e.target.value) }}/>            
                    <h3 className="font-norma text-[14px] mt-8">Promotion type</h3>
                    <button className={"block my-3 h-[40px] font-norma text-[14px] w-full " +(promotionType=="percentage" ? "bg-warmcharcoal text-offwhite font-medium" : "bg-none text-warmcharcoal border-[1px] border-warmcharcoal")} type="button" onClick={()=>setPromotionType('percentage')}>Percentage discount</button>
                    {/* <button className={"block my-3 h-[40px] font-norma text-[14px] w-full " +(promotionType=="amount" ? "bg-warmcharcoal text-offwhite font-medium" : "bg-none text-warmcharcoal border-[1px] border-warmcharcoal")} type="button" onClick={()=>setPromotionType('amount')}>Amount discount</button> */}
                    {/* <button className={"block my-3 h-[40px] font-norma text-[14px] w-full " +(promotionType=="buy x get x" ? "bg-warmcharcoal text-offwhite font-medium" : "bg-none text-warmcharcoal border-[1px] border-warmcharcoal")} type="button" onClick={()=>setPromotionType('buy x get x')}>Buy x get x free</button>
                    <button className={"block my-3 h-[40px] font-norma text-[14px] w-full " +(promotionType=="bundles" ? "bg-warmcharcoal text-offwhite font-medium" : "bg-none text-warmcharcoal border-[1px] border-warmcharcoal")} type="button" onClick={()=>setPromotionType('bundles')}>Bundles</button> */}
                    <h3 className="font-norma text-[14px] mt-8">Description</h3>
                    <textarea className=" h-[200px] mt-2 mb-4 border-none p-4 w-full font-norma text-[14px] focus:outline-none focus:ring-[1px] focus:ring-warmcharcoal focus:ring-opacity-80" placeholder="Description" defaultValue={details?.description} onBlur={(e: any) => { setPromotionDescription(e.target.value) }}></textarea>            
                    <h3 className="font-norma text-[14px]">Button text</h3>
                    <input type="text" className=" h-[40px] mb-5 mt-2 border-none p-4 w-full font-norma text-[14px] focus:outline-none focus:ring-[1px] focus:ring-warmcharcoal focus:ring-opacity-80" placeholder="Get offer" defaultValue={details?.button ? details.button : promotionButton} onBlur={(e: any) => { setPromotionButton(e.target.value) }}/>            
                    <h3 className="font-norma text-[14px]">Button link</h3>
                    <input type="text" className=" h-[40px] mb-5 mt-2 border-none p-4 w-full font-norma text-[14px] focus:outline-none focus:ring-[1px] focus:ring-warmcharcoal focus:ring-opacity-80" placeholder="/link-to-page" defaultValue={details?.link ? details.link : promotionLink} onBlur={(e: any) => { setPromotionLink(e.target.value) }}/>            
                    <h3 className="font-norma text-[14px] -mb-5">Pop over Image</h3>
                    <ImageCropper promotionImage={promotionImage} setImage={setPromotionImage} ref={imageCropperRef}/>
                    <h3 className="font-norma text-[14px] mt-4">Email form</h3>
                    <button className={"block my-3 h-[40px] font-norma text-[14px] w-full " +(promotionEmail===true ? "bg-warmcharcoal text-offwhite font-medium" : "bg-none text-warmcharcoal border-[1px] border-warmcharcoal")} type="button" onClick={()=>setPromotionEmail(!promotionEmail)}>Require email</button>
                    <h3 className="font-norma text-[14px] mt-4">Additional Terms</h3>
                    <textarea className=" h-[200px] mt-4 mb-4 border-none p-4 w-full font-norma text-[14px] focus:outline-none focus:ring-[1px] focus:ring-warmcharcoal focus:ring-opacity-80" placeholder="Any additional terms not covered in the GSL general terms" defaultValue={details?.terms} onBlur={(e: any) => { setPromotionAdditionalTerms(e.target.value) }}></textarea>            
                </div>
                <div className="col-span-6 relative w-full">
                    <div className={stage >= 2 ? "opacity-100" : "opacity-10"}>
                        <h3 className="font-norma font-medium text-[16px] "><span className="bg-warmcharcoal rounded-full text-offwhite inline-block w-6 h-6 text-center mr-2">2</span>Add some products</h3>
                        <div className="grid grid-cols-12 gap-5">
                            <div className="col-span-3 ">
                                <div className="w-full">
                                    <input type="text" className=" h-[40px] mt-8 border-none p-4 w-full font-norma text-[14px] focus:outline-none focus:ring-[1px] focus:ring-warmcharcoal focus:ring-opacity-80" placeholder="Enter product name or id" onChange={(e: any) => { searchProducts(e.target.value); }} onFocus={(e: any) => { searchProducts(e.target.value); }}/>
                                    <div className="overflow-y-scroll h-[40vh] mt-4 bg-offwhite">
                                        {productSelect === true && productResults?.length > 0 && productResults.map((item:any, index: number) => (
                                            <div className="grid grid-cols-12 bg-white my-2 p-2 cursor-pointer h-[60px] relative z-10" onClick={() => addToPromotion(item)} key={index}>
                                                <div className="col-span-2">
                                                    <img src={item.productdata.images[0]?.link} className="h-[50px] rounded-md aspect-square w-full object-cover font-norma font-medium text-[16px] text-warmcharcoal"/>
                                                </div>
                                                <div className="col-span-10 flex items-center pl-4">
                                                    <p className="font-norma text-[12px] text-warmcharcoal">{item.productdata.name}{item.productdata.productOptions[0].option1 ? " – " + item.productdata.productOptions[0].option1 : ""}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-3">
                                <div className="w-full">
                                    <button type="button" className={"h-[40px] mt-8 border-[1px] w-full font-norma text-[14px] border-warmcharcoal " + (projectSelect === true ? " bg-warmcharcoal text-offwhite" : "")} onClick={() => searchProjects()}>
                                        <Collection className={(projectSelect === false ? "stroke-warmcharcoal" : "stroke-offwhite") + " inline-block mr-2 scale-[0.8]"}/>
                                        Collections
                                    </button>
                                    <div className="w-full overflow-y-scroll h-[40vh] mt-4 bg-offwhite">
                                        {projectSelect === true && projectResults?.length > 0 && projectResults.map((item:any, index: number) => (
                                            <div className="grid grid-cols-12 bg-white my-2 p-2 cursor-pointer h-[60px] relative z-10" onClick={(e) => {addToPromotion(item)}} key={index}>
                                                <div className="col-span-10 flex items-center pl-4">
                                                    <p className="font-norma text-[12px] text-warmcharcoal">{item.projectname}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-3">
                                <div className="w-full">
                                    <button type="button" className={" h-[40px] mt-8 border-[1px] w-full font-norma text-[14px] border-warmcharcoal " + (categorySelect === true ? " bg-warmcharcoal text-offwhite" : "")} onClick={() =>searchCategories()}>
                                        <Category className={(categorySelect === false ? "fill-warmcharcoal" : "fill-offwhite") + " inline-block mr-2 scale-[0.7]"}/>
                                        Categories
                                    </button>
                                    <div className="w-full overflow-y-scroll h-[40vh] mt-4 bg-offwhite">
                                        {categorySelect === true &&  categoryResults?.length > 0 && categoryResults.map((item:any, index: number) => {
                                            return (
                                            <div className="grid grid-cols-12 bg-white my-2 p-2 cursor-pointer  h-[60px] relative z-10" onClick={(e) => addToPromotion(item)} key={index}>
                                                <div className="col-span-10 flex items-center pl-4">
                                                    <p className="font-norma text-[12px] text-warmcharcoal">{item.categorydata?.name}</p>
                                                </div>
                                            </div>
                                            )}
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-3">
                                <div className="w-full">
                                    <button type="button" className={" h-[40px] mt-8 border-[1px] w-full font-norma text-[14px] border-warmcharcoal " + (productSet[0] === "all" && productSet.length === 1 ? " bg-warmcharcoal text-offwhite" : "")} onClick={() =>setAllProducts()}>All Products</button>
                                </div>
                            </div>
                        </div>
                        <div className="absolute w-full top-[120px] px-5 mt-9">
                            <div className="relative w-full">
                            <div className="relative w-full grid grid-cols-3 gap-5">
                                {productSet?.map((item: any, index: number)=>{

                                    return (
                                        <div className="relative rounded-lg grid grid-cols-12 bg-offwhite my-2 p-2 cursor-pointer hover:opacity-100 opacity-80 h-[60px] border-warmgrey border-dashed border-[1px]" key={index}>
                                            <div onClick={()=>removeItem(index)} className="absolute top-0 left-0 w-full h-[58px] bg-offwhite opacity-0 transition-opacity duration-500 hover:opacity-100 flex items-center justify-center  text-center font-norma text-[12px] font-medium rounded-md">Remove</div>
                                                <div className="col-span-3 flex items-center">
                                                {item.productdata ?  
                                                <img src={item.productdata.images[0]?.link} className="h-[42px] rounded-sm aspect-square w-full object-cover font-norma font-medium text-[16px] text-warmgrey"/>
                                                : item.projectname ?
                                                    <Collection className="stroke-warmgrey"/>
                                                : <Category className="fill-warmgrey"/>
                                                }
                                            </div>
                                            <div className="col-span-9 flex items-center pl-4">
                                                {item.productdata ? 
                                                    <p className="font-norma font-medium leading-[16px] text-[12px] text-warmgrey">{item.productdata.name}{item.productdata.productOptions[0].option1 ? " – " + item.productdata.productOptions[0].option1 : ""}</p>
                                                    : item.projectname ?
                                                    <p className="font-norma font-medium leading-[16px] text-[12px] text-warmgrey">{item.projectname}</p>
                                                : <p className="font-norma font-medium leading-[16px] text-[12px] text-warmgrey">{item.category?.name}</p>}
                                                {item === "all"  ? <p className="font-norma font-medium leading-[16px] text-[12px] text-warmgrey">All products</p>:<></>}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="relative w-full">
                                <div className={(stage >= 3 ? "opacity-100" : "opacity-10")}>
                                    <h3 className="font-norma font-medium text-[16px] mt-8"><span className="bg-warmcharcoal rounded-full text-offwhite inline-block w-6 h-6 text-center mr-2">3</span>Set some {promotionType} based rules</h3>
                                    {promotionType==="percentage" || promotionType === "amount" ? 
                                        <div className="grid grid-cols-12 w-full gap-x-8">
                                            <div className="col-span-3 pt-8">
                                                <p className="font-norma text-[14px] mb-2">{promotionType==="percentage" ? "% discount" : "$ discount"}</p>
                                                <input type="text" className=" h-[40px] border-none p-4 w-full font-norma text-[14px] focus:outline-none focus:ring-[1px] focus:ring-warmcharcoal focus:ring-opacity-80" placeholder={promotionType==="percentage" ? "20%" : "$100"} defaultValue={rules?.amount} onBlur={(e: any) => { addToRules('amount', e.target.value) }}/>            
                                            </div>
                                            <div className="col-span-3 pt-8">
                                                <p className="font-norma text-[14px] mb-2">min $ order value</p>
                                                <input type="text" className=" h-[40px] border-none p-4 w-full font-norma text-[14px] focus:outline-none focus:ring-[1px] focus:ring-warmcharcoal focus:ring-opacity-80" placeholder="$0.00" defaultValue={rules?.min} onBlur={(e: any) => { addToRules('min', e.target.value) }}/>            
                                            </div>
                                            <div className="col-span-3 pt-8">
                                                <p className="font-norma text-[14px] mb-2">max $ order value</p>
                                                <input type="text" className=" h-[40px] border-none p-4 w-full font-norma text-[14px] focus:outline-none focus:ring-[1px] focus:ring-warmcharcoal focus:ring-opacity-80" placeholder="$1,000" defaultValue={rules?.max} onBlur={(e: any) => { addToRules('max', e.target.value) }}/>            
                                            </div>
                                            <div className="col-span-3 pt-8">
                                                <p className="font-norma text-[14px] mb-2">requires coupon</p>
                                                <select
                                                    className="h-[40px] border-none pl-3 w-full font-norma text-[14px] focus:outline-none focus:ring-[1px] focus:ring-warmcharcoal focus:ring-opacity-80 bg-white"
                                                    value={ruleSet.voucher || "false"}  // Ensure this matches the option values
                                                    onChange={(e: any) => {
                                                        const selectedValue = e.target.value;
                                                        // console.log("Selected value:", selectedValue);
                                                        if (selectedValue === "Get Coupons") {
                                                            getVouchers();
                                                        } else {
                                                            addToRules('voucher', selectedValue);
                                                        }
                                                    }}
                                                >
                                                    {vouchers.length > 0 ? (
                                                        <>
                                                            <option value="false">Choose...</option>
                                                            {vouchers.map((item: any, index: number) => (
                                                                <option value={item.id} key={index}>
                                                                    {item.id}
                                                                </option>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <option value="false">{ruleSet.voucher ? ruleSet.voucher : "None"}</option>
                                                            {!voucherCheck && <option value="Get Coupons">Get Coupons</option>}
                                                        </>
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                    : <></>}
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-span-4 ml-8">
                    {dataSet && dataSet[0] || saved === true ? 
                    <p className="absolute right-[50px] font-norma font-medium text-[14px] cursor-pointer hover:opacity-60" onClick={()=>savePromotion("duplicate")}>Duplicate this promotion?</p>
                    : <></>}
                    <div className={stage >= 4 ? "opacity-100" : "opacity-10"}>
                        <h3 className="font-norma font-medium text-[16px] mb-8"><span className="bg-warmcharcoal rounded-full text-offwhite inline-block w-6 h-6 text-center mr-2">4</span>Schedule it</h3>
                        <Calendar onChange={setScheduleSet} value={scheduleSet} className="bg-offwhite font-norma w-full p-4 pb-6" selectRange={true}/>
                        <div className="grid grid-cols-12 mt-4">
                            <div className="col-span-12 md:col-span-6">
                                <div className=" relative text-[14px] text-warmcharcoal font-norma mb-4" onClick={() => setAlwaysOn(!alwaysOn)}>
                                    <span className={"rounded-full h-[30px] w-[30px] inline-block mr-3 p-2 bg-white"}>
                                        <span className={(alwaysOn === true ? "bg-warmcharcoal" : "bg-white") + " w-full h-full rounded-full block cursor-pointer"}></span>
                                    </span> 
                                    <span className="absolute top-2 inline-block leading-[18px]">No start nor end dates</span>
                                </div>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                            <div className="bg-white relative h-[42px]">
                                <select className="w-[calc(100%_-_10px)] h-[42px] px-3 bg-white font-norma text-[14px]" onChange={(e:any)=>setHideOffer(e?.target?.value)} value={hideOffer}>
                                    <option>Offer visibility:</option>
                                    <option value="hide_pop_up">Always hiddden</option>
                                    <option value="newsletter">Hide after newsletter subscribe</option>
                                    <option value="purchase">Hide after purchase</option>
                                    <option value="customer">Hide if a customer</option>
                                    <option value="not_customer">Hide if not a customer</option>
                                </select>
                            </div>
                            </div>
                        </div>
                        <p className="relative w-full font-norma text-[12px] mt-4">Start: {Array.isArray(scheduleSet) ? formatDate(scheduleSet[0]) : formatDate(scheduleSet)}
                        <span className="absolute right-0">End: {Array.isArray(scheduleSet) && scheduleSet[1] ? formatDate(scheduleSet[1]) : 'Not set'}</span></p>
                    </div>
                    <div className={stage >= 5 ? "opacity-100" : "opacity-10"}>
                        <h3 className="font-norma font-medium text-[16px] mt-8"><span className="bg-warmcharcoal rounded-full text-offwhite inline-block w-6 h-6 text-center mr-2">5</span>Save or publish</h3>
                        
                        <div className="mt-8">
                            <p className="font-norma text-[14px] font-medium">Overview:</p>
                            <p className="font-norma text-[22px] py-4 font-medium">{promotionName}</p>
                            <ul className="list-disc ml-4">
                                <li className="font-norma text-[14px] py-2">{ruleSet?.type === "percentage" ? ruleSet?.amount+"% off" : " $"+ruleSet?.amount+"% off"} promotion</li>
                                <li className="font-norma text-[14px] py-2">{ruleSet?.min > 0 ? "$"+ruleSet.min+" minimum spend" : "with no minimum spend"}</li>
                                <li className="font-norma text-[14px] py-2">{ruleSet?.max > 0 ? " up to $"+ruleSet.max+" maximum" : "with no maximum spend"}</li>
                                <li className="font-norma text-[14px] py-2">Starts on {Array.isArray(scheduleSet) ? formatDate(scheduleSet[0]) : formatDate(scheduleSet)} and ends on {Array.isArray(scheduleSet) && scheduleSet[1] ? formatDate(scheduleSet[1]) : 'Not set'}</li>
                            </ul>
                            
                        </div>
                        <button type="button" className={"col-span-6 h-[40px] mt-8 border-[1px] border-warmcharcoal w-full font-norma text-[14px]"} onClick={() =>setShowPopOverPreview(true)}>Preview popover</button>
                            
                        <div className="grid grid-cols-12 gap-5">
                            {promotionStatus=== false ? <button type="button" className={"col-span-6 h-[40px] mt-8 border-[1px] border-warmcharcoal w-full font-norma text-[14px]"} onClick={() =>savePromotion('draft')}>Save for later</button>: <div className="col-span-6"></div>}
                            <button type="button" className={"col-span-6 h-[40px] mt-8 border-[1px] border-warmcharcoal w-full font-norma text-[14px] bg-warmcharcoal text-offwhite"} onClick={() =>promotionStatus === false ? savePromotion('publish') : savePromotion('draft')}>{promotionStatus === false ? "Publish Promotion" : "Unpublish"} </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PromotionPanelAdmin
