import { Fragment } from 'react'
import NextLink from 'next/link'
import { SharePanel } from '@/components'

interface BreadCrumbProps {
    page?: any | null
    image?: any | null
    lang?: any | null
}

const BreadCrumb:React.FC<BreadCrumbProps>  = ({ page, image, lang }) => {
    
    const breadcrumb = Object.values(page) as any

    return (
        <section className="hidden lg:block w-full">
            <div className="relative">
                <ul className="w-auto m-0 p-0 font-norma pr-[15vw] text-left ">
                    {breadcrumb?.map((item: string, index: number)=>{
                    
                    let itemLink =`/${lang}/`;

                    switch(index) {
                        case 1:
                            itemLink ="/"+item;
                        break;
                        case 2:
                            itemLink ="/"+breadcrumb[1]+"/"+item;
                        break;
                        case 3:
                            itemLink ="/"+breadcrumb[1]+"/"+breadcrumb[2]+"/"+item;
                        break;
                        case 4:
                            itemLink ="/"+breadcrumb[1]+"/"+breadcrumb[2]+"/"+breadcrumb[3]+"/"+item;
                        break;
                        default: 
                    }

                        if(index > 0) return (
                            <li className="inline text-[12px] text-warmgrey capitalize m-0 p-0 text-left " key={index}>
                                {index < 3 ?  <><NextLink href={itemLink}>{item?.trim()?.replace(/-/g, " ")}</NextLink>
                                <span className="inline-block px-1">{index < breadcrumb?.length -1 ? ">" : ""}</span> </>
                                :<>{item?.trim()?.replace(/-/g, " ")}
                                <span className="inline-block px-1">{index < breadcrumb?.length -1 ? ">" : ""}</span> </>}
                            </li>
                        )
                    })}
                </ul>
                <SharePanel image={image}/>
            </div>
        </section>
    )
} 

export default BreadCrumb

