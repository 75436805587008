import NextImage from 'next/image'
import NextLink from 'next/link'
import { getCountryData } from '@/components/getCountryData'
import { getServerSession } from "next-auth/next"
import { authOptions } from "@/auth-options"
import { TheLookAdmin, CarouselTile} from '@/components'

import _ from 'lodash'

interface ProductCompleteTheLookProps {
    productId?: any | null
    lookData?: any | null
    lang?: any | null
    slice?: any | null
}

async function getTheLook(productId: any, lookData:any, lang:any, slice: any) {

    const session = await getServerSession(authOptions) as any
    const { currency, priceColumn } = getCountryData(lang);
    
    return (
        <section
            data-slice-type="ProductCompleteTheLook"
            className="pb-[104px] w-screen bg-offwhite"
        >
            {lookData?.length > 0 && session?.user?.admin !== true ? <>
                <CarouselTile lookupData={lookData} session={session} currency={currency} lang={lang} priceColumn={priceColumn} slice={slice}/>
            </>
        : <></>}
        {session?.user?.admin === true ?
            <>
                <h2 className="font-sangbleu font-medium text-[24px] leading-[32px] text-warmcharcoal mt-12 p-5 lg:px-[45px] pb-8">{slice?.primary?.heading ? slice?.primary?.heading : 'Complete the look'}</h2>
                <TheLookAdmin productId={productId} lookData={lookData} lang={lang}/>
            </>
        : <></>}
        </section>
    )
}

const ProductCompleteTheLook:React.FC<ProductCompleteTheLookProps>  = ({ productId, lookData, lang, slice }) => {
        return getTheLook(productId, lookData, lang, slice)
}

export default ProductCompleteTheLook
