    const currencyMap = {
        'GLOBAL': '$USD',
        'AU': '$AUD',
        'NZ': '$NZD',
        // Add more country codes and their currencies as needed - note add the currency prefix ie: $ or €
    } as any;

    const cin7PriceMap = {
        'GLOBAL': 'usdusd',
        'AU': 'audaud',
        'NZ': 'retailNZD',
        // Add more country codes and their cin7 columns as needed
    } as any;
    
    export function getCountryData(lang: string) {
        const langOnly = lang.toUpperCase();
        const cin7Price = lang.toUpperCase();
        return { currency: currencyMap[langOnly], priceColumn: cin7PriceMap[cin7Price] } || {currency: 'USD', priceColumn: 'usdusd'}; // Default to 'USD' if the lang is not found
    }
    