import { sql } from '@vercel/postgres';

export async function checkPromotions(product: number) {
    const productJson = `[{"id": ${product}}]`;

    try {
        // const { rows } = await sql`
        //     SELECT
        //         p.has_product_option,
        //         p.within_schedule,
        //         p.discount_type,
        //         p.discount_amount,
        //         p.min_value,
        //         p.max_value
        //     FROM (
        //         SELECT
        //             CASE
        //                 WHEN rules_json #> '{products,0,productdata,productOptions}' @> ${productJson}::jsonb THEN TRUE
        //                 ELSE FALSE
        //             END AS has_product_option,
        //             CURRENT_TIMESTAMP BETWEEN (rules_json #>> '{schedule,0}')::timestamp AND (rules_json #>> '{schedule,1}')::timestamp AS within_schedule,
        //             rules_json #>> '{rules,type}' AS discount_type,
        //             (rules_json #>> '{rules,amount}')::numeric AS discount_amount,
        //             (rules_json #>> '{rules,min}')::numeric AS min_value,
        //             (rules_json #>> '{rules,max}')::numeric AS max_value,
        //             ROW_NUMBER() OVER (PARTITION BY rules_json #>> '{products,0,productdata,productOptions}' ORDER BY (rules_json #>> '{rules,amount}')::numeric DESC) AS rn
        //         FROM
        //             public.promotions
        //         WHERE
        //             rules_json #> '{products,0,productdata,productOptions}' @> ${productJson}::jsonb
        //             AND CURRENT_TIMESTAMP BETWEEN (rules_json #>> '{schedule,0}')::timestamp AND (rules_json #>> '{schedule,1}')::timestamp
        //             AND status = TRUE
        //     ) p
        //     WHERE
        //         p.rn = 1;
        // `;

        const { rows } = await sql`
            SELECT
                p.has_product_option,
                p.within_schedule,
                p.discount_type,
                p.discount_amount,
                p.min_value,
                p.max_value
            FROM (
                SELECT
                    CASE
                        WHEN rules_json #> '{products,0,productdata,productOptions}' @> ${productJson}::jsonb THEN TRUE
                        ELSE FALSE
                    END AS has_product_option,
                    CURRENT_TIMESTAMP BETWEEN (rules_json #>> '{schedule,0}')::timestamp AND (rules_json #>> '{schedule,1}')::timestamp AS within_schedule,
                    rules_json #>> '{rules,type}' AS discount_type,
                    (rules_json #>> '{rules,amount}')::numeric AS discount_amount,
                    (rules_json #>> '{rules,min}')::numeric AS min_value,
                    (rules_json #>> '{rules,max}')::numeric AS max_value,
                    ROW_NUMBER() OVER (PARTITION BY rules_json #>> '{products,0,productdata,productOptions}' ORDER BY (rules_json #>> '{rules,amount}')::numeric DESC) AS rn
                FROM
                    public.promotions
                WHERE
                    rules_json #> '{products,0,productdata,productOptions}' @> ${productJson}::jsonb
                    AND CURRENT_TIMESTAMP BETWEEN (rules_json #>> '{schedule,0}')::timestamp AND (rules_json #>> '{schedule,1}')::timestamp
                    AND status = TRUE
                    AND (rules_json #>> '{rules,voucher}') IS NULL
            ) p
            WHERE
                p.rn = 1;
        `;

        if (rows.length === 0) {
            // console.log("No promotions found for the given product.");
            return { rules: null };
        }

        // console.log("found: ", rows);

        return { rules: rows[0] };
    } catch (error: any) {
        // console.log("Error executing query:", error);
        return { rules: null, error: error.message };
    }
}
