'use client';
import { useState, useEffect, useRef } from 'react';
import NextLink from 'next/link'
import Image from 'next/image'
import _ from 'lodash'
import TinyArrow from '@/assets/icons/tiny-arrow.svg'
import Slider from 'react-slick'

interface CarouselTileProps {
    lookupData?: any | null
    session?: any | null
    lang?: any | null
    currency?: any | null
    priceColumn?: any | null
    adminTiles?: any | null
    contextData?: any | null
    slice?: any | null
    rules?: any | null
}

const CarouselTile: React.FC<CarouselTileProps> = ({ lookupData, session, currency, lang, priceColumn, slice, rules }) => {

    const ulRef = useRef(null) as any

    const [tile, setTile] = useState(0);

    const slickRef = useRef(null) as any

    const showTile =(item: number) =>{
        if(tile+item > -1 && tile+item < lookupData?.length) {
            setTile(tile+item)
        }
    }

    function findBestRuleForProduct(product: any, rules: any) {
        let bestRule: any = null;
        let highestAmount = 0;
      
        rules?.forEach((rule: any) => {
            rule.rules_json?.products?.forEach((rulesProducts: any) => {
                if (rulesProducts.category?.id && product.categoryIdArray?.includes(rulesProducts.category.id)) {
                    // console.log('cat: ', rulesProducts.category?.id);
                    if (rule.rules_json.rules.amount > highestAmount) {
                        highestAmount = rule.rules_json.rules.amount;
                        bestRule = rule;
                    }
                }
                if (rulesProducts.projectname && product.projectName === rulesProducts.projectname) {
                    // console.log('proj: ', rulesProducts.projectname);
                    if (rule.rules_json.rules.amount > highestAmount) {
                        highestAmount = rule.rules_json.rules.amount;
                        bestRule = rule;
                    }
                }
                if (rulesProducts.productdata && rulesProducts.productdata.id === product.id) {
                    // console.log('prods: ', rulesProducts.productdata.id);
                    if (rule.rules_json.rules.amount > highestAmount) {
                        highestAmount = rule.rules_json.rules.amount;
                        bestRule = rule;
                    }
                }
                if (rulesProducts === "all") {
                    // console.log('all: ', rulesProducts.category?.id);
                    if (rule.rules_json.rules.amount > highestAmount) {
                        highestAmount = rule.rules_json.rules.amount;
                        bestRule = rule;
                    }
                }
            });
        });
        return bestRule?.rules_json?.rules;
    };

    const settings = {
        arrows: false,
        dots: false,
        slidesToShow:3,
        speed: 500,
        responsive: [  
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
            }
        }
        ]
    
    } as any

    return  (
        <>
            <h2 className="font-sangbleu font-medium text-[20px] leading-[32px] md:text-[25px] md:leading-[32px] px-5 lg:px-[54px] pb-8">{slice?.primary?.heading ? slice?.primary.heading :'Complete the look'}</h2>
            <div className="relative border-t-[1px] border-t-warmcharcoal px-4 lg:px-[40px] overflow-scroll-y w-screen">
                {/* <div className="grid grid-cols-12 mb-16 gap-x-4 lg:gap-x-8 relative min-h-[110vw] md:min-h-0 w-full"> */}
                <Slider {...settings} ref={slickRef}>
                {lookupData?.map((item: any, index: any)=>{
                    const retailValues = _.flatMap(item, product => item.productdata.productOptions.map((option : any) => option.priceColumns?.[priceColumn]));
                    const nonZeroRetailValues = _.filter(retailValues, value => value !== 0);
                    const lowestRetail = _.min(nonZeroRetailValues);
                    if(item.productdata.images[0]?.link && lowestRetail > 0) {
        
                    const applicablePromotion = findBestRuleForProduct(item.productdata, rules) as any
        
                    let discountLabelType = "";
                    let discountPrice = lowestRetail
                    let discounted = 0
        
                    if (applicablePromotion) {
                        // console.log(item.productdata, applicablePromotion)
                        discounted = applicablePromotion.amount;
                        discounted = applicablePromotion.type === "percentage" ? discounted / 100 : discounted;
        
                        discountPrice = lowestRetail - lowestRetail * discounted;
                        discountLabelType = applicablePromotion.type === "percentage" ? `${applicablePromotion.amount}% off` : `${currency} ${applicablePromotion.amount} off`;
                    }
        
                    return (
                        <div key={index} className="px-2 lg:px-4 relative">
                    {/* <div className="col-span-6 lg:col-span-4 origin-center cursor-pointer duration-300 relative" key={index}> */}
                        {discountLabelType ? <div className="absolute top-0 right-2 md:right-4 bg-warmgrey text-white font-norma font-medium flex items-center justify-center text-[14px] h-[30px] px-4 z-[10]">{discountLabelType}</div>:<></>}
                        <NextLink href={("/"+lang+"/"+item.productdata.productType+"/"+item.productdata.productSubtype?.trim()).toLowerCase().replace(/ /g, "-").replace(/-&-/g, "---").replace(/-+-/g, "---")+"/"+item.productdata.name?.trim()?.toLowerCase().replace(/ /g, "-")+"/"+item.productdata.productOptions[0].option1?.trim()?.toLowerCase().replace(/ /g, "-")}>
                        <div className="relative w-full aspect-square">
                            <div className="duration-300 opacity-0 md:hover:opacity-100 cursor-pointer aspect-square bg-warmcharcoal/15 absolute top-0 left-0 right-0 bottom-0 z-10 flex items-center justify-center mix-blend-color-burn/80"><p className="text-offwhite font-norma font-medium text-[16px]">See product</p></div>
                            <Image src={item.productdata.images[0].link} className="w-full object-cover aspect-square" fill sizes="(max-width: 419px) 380px, (max-width: 640px) 300px, 600px" alt={item?.productdata?.name+". From $"+Number(lowestRetail).toFixed(2)+". Available at George Street Linen"}/>
                            {item.num_colors > 1 ? <div className="absolute bottom-0 left-0 bg-warmgrey text-white font-norma font-medium flex items-center justify-center text-[14px] h-[30px] w-full md:w-auto md:px-4 z-[1]">{item.num_colors} colours available</div>:<></>}
                        </div>
                        <div className="grid grid-cols-12 py-6 md:h-[100px]">
                            <div className="col-span-12 md:col-span-6 lg:col-span-7">
                            <p className="font-norma font-medium text-[16px] leading-[24px]">{item.productdata.name}</p>
                            </div>
                            <div className="col-span-12 md:col-span-6 lg:col-span-5 flex md:justify-end ">
                                <p className="font-norma font-normal text-[16px] leading-[24px] md:text-right  ">
                                    {Number(lowestRetail-lowestRetail*discounted).toFixed(2) !== Number(lowestRetail).toFixed(2) ?
                                    <><span className="line-through text-warmred inline-block">{currency} {Number(lowestRetail).toFixed(2)}</span><br/></>: <></>}
                                    From {currency} {Number(lowestRetail-lowestRetail*discounted).toFixed(2)}
                                </p>
                            </div>
                        </div>
                        </NextLink>
                    </div>
                    )}})}
                {/* </div> */}
                </Slider>
                <div className="block pb-6 text-center mx-auto w-[200px]">
                    <TinyArrow className="inline-block mr-3 cursor-pointer stroke-warmcharcoal" onClick={()=>{showTile(-1); slickRef?.current?.slickPrev()}}/> 
                    <span className="font-norma text-[14px]">{tile+1} / {lookupData?.length} </span>
                    <TinyArrow className="inline-block rotate-180 ml-3 cursor-pointer stroke-warmcharcoal" onClick={()=>{showTile(1); slickRef?.current?.slickNext()}}/>
                </div>
            </div>
        </>
    )
}

export default CarouselTile
