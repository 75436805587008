'use client'
import { useRef, useState, useEffect } from 'react';
import Trash from '@/assets/icons/trash.svg'
import { getCountryData } from '@/components/getCountryData';
import _ from 'lodash'

interface PromoPanelProps {
    lang?: any | null
    setOrderVoucher?: any | null
    cartTotal?: any | null
    setCartTotal?: any | null
    cartItems?: any | null
    setCartItems?: any | null
    setVoucherDetails?: any | null
    showCart?: any | null
    checkout?: any | null
}

const PromoPanel: React.FC<PromoPanelProps> = ({ setOrderVoucher, cartTotal, setCartTotal, lang, cartItems, setCartItems, setVoucherDetails, showCart, checkout}) => {

    const [promoCode, setPromoCode] = useState<string | null>(null);
    const [promoValue, setPromoValue] = useState<string | null>(null);
    const [checking, setChecking] = useState<string>('Apply code');
    const [originalCartTotal, setOriginalCartTotal] = useState<number | null>(cartTotal);
    const [originalCartItems, setOriginalCartItems] = useState(cartItems) as any
    const [isVoucherEmpty, setIsVoucherEmpty] = useState<boolean>(true);
    const voucherRef = useRef<HTMLInputElement>(null);
    const [voucherType, setVoucherType] = useState('Gift Voucher')
    const [balance, setBalance] = useState(0)
    const [discount, setDiscount] = useState(0)
    const { currency, priceColumn } = getCountryData(lang);

    useEffect(() => {
        const handleInputChange = () => {
            if (voucherRef.current?.value) {
                setIsVoucherEmpty(false);
            } else {
                setIsVoucherEmpty(true);
            }
        };

        if (voucherRef.current) {
            voucherRef.current.addEventListener('input', handleInputChange);
        }

        return () => {
            if (voucherRef.current) {
                voucherRef.current.removeEventListener('input', handleInputChange);
            }
        };
    }, []);

    const checkProductValidity = async (cartBlob: any, voucherData: any) => {

        const getProducts = await fetch(`/api/coupons/?coupon_code=${voucherData.code}`);
        const getProductsResult = await getProducts.json();
    
        // console.log(getProductsResult)
        let updateCount = 0; 
    
        cartBlob?.forEach((item: any) => {
            const isMatch = _.some(getProductsResult, (product: any) => {
                if (product === "all") {
                    return true;
                } else {
                    return _.get(product, 'productdata.productOptions[0].id') === item.options[0]?.id;
                }
            });
    
            if (!isMatch && !item.promotion) {  // Ensure no match and no existing promotion
                let discounted = voucherData.value;
                let itemPrice = item.options[0]?.priceColumns[priceColumn];
    
                discounted = voucherData.coupon_type === "percentage" ? discounted / 100 : discounted;
    
                item.discountPrice = itemPrice - itemPrice * discounted;
                item.discountLabel = voucherData.coupon_type === "percentage" ? `${voucherData.value}% off` : `${currency} ${voucherData.value} off`;
    
                const discountedValue = cartTotal - (itemPrice - item.discountPrice);

                setCartTotal(discountedValue);
                updateCount++;
            }
    
            if (isMatch && !item.promotion) {  // Match found, and no existing promotion
                let discounted = voucherData.value;
                let itemPrice = item.options[0]?.priceColumns[priceColumn];
    
                discounted = voucherData.coupon_type === "percentage" ? discounted / 100 : discounted;
    
                item.discountPrice = itemPrice - itemPrice * discounted;
                item.discountLabel = voucherData.coupon_type === "percentage" ? `${voucherData.value}% off` : `${currency} ${voucherData.value} off`;
    
                const discountedValue = cartTotal - (itemPrice - item.discountPrice);

                setCartTotal(discountedValue);
                updateCount++;
            }

            
        });
    
        return updateCount;
    }
    
    const getPromoCode = async () => {
        setChecking('Checking...');
        setIsVoucherEmpty(true);
        try {
            const voucher = voucherRef.current?.value || '';
            const checkVoucher = await fetch(`/api/orders/vouchers?voucher=${voucher}`);
            const voucherResult = await checkVoucher.json();
            
            if (voucherResult?.voucher?.type !== "Coupon" && voucherResult?.voucher?.balance > 0) {
                setDiscount(voucherResult?.voucher?.value);
                setPromoCode(voucherResult?.voucher?.code);
                setOrderVoucher(voucherResult?.voucher?.code);
                setPromoValue(voucherResult?.voucher?.balance >= cartTotal ? cartTotal : voucherResult?.voucher?.balance);

                voucherResult.voucher.cartTotal = originalCartTotal

                setVoucherDetails(voucherResult.voucher)
                // Calculate the new cart total after applying the voucher
                let discountedValue = cartTotal - voucherResult?.voucher?.balance;
    
                // If discounted value is negative, set it to 0 and calculate the remaining balance
                if (discountedValue < 0) {
                    setCartTotal(0);
                    setBalance(parseFloat(Math.abs(discountedValue).toFixed(2))); // Remaining balance
                } else {
                    setCartTotal(parseFloat(discountedValue.toFixed(2)));
                    setBalance(0); // No remaining balance
                }
    
                setChecking('Apply');
            } else if (voucherResult?.voucher?.type === "Coupon") {
                const updated = await checkProductValidity(cartItems, voucherResult?.voucher);
                if (updated > 0) {
                    setDiscount(voucherResult?.voucher?.value);
                    setPromoCode(voucherResult?.voucher?.code);
                    setOrderVoucher(voucherResult?.voucher?.coupon_code);
                    setVoucherDetails(voucherResult?.voucher);
                    setVoucherType(voucherResult?.voucher?.type);
                    setChecking('Apply');
                } else {
                    setChecking('Sorry no products match');
                }
            } else {
                setChecking('Sorry invalid code');
                setTimeout(() => setChecking('Try again'), 1000);
            }
        } catch (error: any) {
            setChecking('Invalid Code');
        }
    };

    const removePromoCode = () => {
        
        if(voucherType === "Coupon") {
            setCartTotal(originalCartTotal)
            setCartItems(originalCartItems)
        } else {
            setCartTotal(cartTotal+promoValue);
        }
        
        setPromoCode(null);
        setPromoValue(null);
        if (voucherRef.current) {
            voucherRef.current.value = '';
        }
        setIsVoucherEmpty(true);
        setChecking('Apply code');
        
    }

    useEffect(()=>{
        if(showCart === false || checkout === false) {
            setPromoCode(null);
            setPromoValue(null);
            setCartTotal(originalCartTotal)
            setCartItems(originalCartItems)
            setPromoCode(null);
            setPromoValue(null);
            if (voucherRef.current) {
                voucherRef.current.value = '';
            }
            setIsVoucherEmpty(true);
            setChecking('Apply code');
        }
    }, [showCart, checkout])

    return (
        <div className="py-4">
            {!promoCode ? 
            <>
            <p className="font-norma font-medium text-[16px] md:text-[14px] text-warmcharcoal">
                Apply a coupon code (one per order)<br/>
            </p>
            <div className="grid grid-cols-12 w-full gap-4 my-3">
                <div className="col-span-12 md:col-span-8">
                    <input
                        ref={voucherRef}
                        className="uppercase focus:outline-none focus:ring-warmcharcoal focus:ring-[1px] w-full h-[40px] font-norma font-medium text-[16px] md:text-[14px] text-warmcharcoal px-4"
                        placeholder="XXXX"
                        type="text"
                    />
                </div>
                <div className="col-span-12 md:col-span-4">
                        <button
                            className="border-[1px] border-warmcharcoal w-full h-[40px] font-norma font-medium text-[16px] md:text-[14px] text-warmcharcoal "
                            type="button"
                            onClick={getPromoCode}
                        >
                            {checking}
                        </button>
                </div>
            </div>
            </>
            :
            <div className="grid grid-cols-12 w-full gap-4 my-3 font-norma text-[14px] font-medium">
                <div className="col-span-8 relative">
                    <p className="pl-6 uppercase"><Trash onClick={()=>removePromoCode()} className="inline-block mr-2 w-[20px] cursor-pointer hover:opacity-60 absolute left-0"/>
                        {voucherType} - {promoCode} <br/><span className="font-normal text-[12px]">{voucherType === "Coupon" ? "" : `balance after purchase - ${currency} ${balance.toFixed(2)}`}</span>
                    </p>
                </div>
                <div className="col-span-4 flex justify-end">
                    {voucherType === "Coupon" ? 
                    <p className="">{Math.ceil(Number(discount))}% OFF</p>
                    : <p className="">{currency} -{Number(promoValue).toFixed(2)}</p>
                    }
                </div>
            </div>
            }
        </div>
    );
}

export default PromoPanel;
