'use client'
import { useState } from 'react'
import { useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { getCountryData } from '@/components/getCountryData';

interface CheckoutFormProps {
    total?: number | null;
    lang?: any | null;
    orderData?: any | null;
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({ lang, total, orderData }) => {

    const stripe = useStripe();
    const elements = useElements();
    const { currency, priceColumn } = getCountryData(lang);
    const [paymentState, setPaymentState] = useState(<span>Pay {currency} {Number(total)?.toFixed(2)}</span>) as any

    const [error, setError] = useState(null) as any
    const handleSubmit = async (event: any) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        setPaymentState("Processing Payment...")

        if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
        }

        const result = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: `${process.env.NEXT_PUBLIC_URL}/${lang}/checkout/${orderData?.ref}/?success=true&p=AU Stripe`,
            },
        });

        if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
        setPaymentState(<span>Pay {currency} {Number(total)?.toFixed(2)}</span>)
        setError(result.error.message);
        } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
        }
    };

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <p className="py-4 font-norma font-medium text-warmred text-[16px] text-center">{error}</p>
            <PaymentElement />
            <button disabled={!stripe} className="bg-warmcharcoal mt-8 h-[42px] w-full text-[14px] font-norma font-medium text-offwhite text-center hover:opacity-60 cursor-pointer">
            {!stripe && !elements ? ( <span>Loading...</span> ) : paymentState }
            </button>
        </form>
    )
};

export default CheckoutForm;
