
'use client'
import { signOut, useSession } from "next-auth/react"

interface LogoutButtonProps {
}

const LogoutButton:React.FC<LogoutButtonProps> = ({}) => {

    const { data: session, status } = useSession()

    const user = session?.user as any;

    return (
        <span onClick={() => signOut()}>Log {user?.first_name} out?</span>
    )
}

export default LogoutButton
