'use client'; // Ensure this is at the top of the file for Next.js if using app-router

import { useState, useEffect } from 'react';
import { getCountryData } from '@/components/getCountryData';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import _ from 'lodash';


interface PayPalPaymentProps {
    total?: number | null;
    lang?: any | null;
    orderData?: any | null
    user?: any | null
}

const PayPalPayment: React.FC<PayPalPaymentProps> = ({ lang, total, orderData, user }) => {

    const { currency, priceColumn } = getCountryData(lang);
    // Renders errors or successfull transactions on the screen.
    type MessageProps = {
        content: string;
    };
      
      // Functional component with typed props
    const Message: React.FC<MessageProps> = ({ content }) => {
        return <p>{content}</p>;
    };
    
    const initialOptions = {
        //test
        // "client-id": "AXDLyRTWZP2_gwLv2W0O_jiJczpOrh-opLRp7I3wADSSox9oS-rcxXiy5PAL7PfidM9hqb0K4pKaBa3_",
        // prod
        "client-id": "AYW7CevxICB3fhJOWCTJ_i_g0IrItdZRuLry3bUZ4dH-OFXNoQC8r1KF0Mv99xIMz12FxAWtd0gbbDbF",
        "enable-funding": "paylater,venmo",
        "data-sdk-integration-source": "integrationbuilder_sc",
        currency: currency.replace("$",""),
        "data-page-type": "product-details",
    } as any
    
    const [message, setMessage] = useState("") as any

    const cartDataSet = [] as any

    orderData?.order?.lineItems?.map((item: any)=>{
        const lineItem = {
            item: item?.name,
            quantity: item?.quantity,
        } as any
        cartDataSet.push(lineItem)
    })
    
    return (
        <div className="App">
            {/* <div className="border-[1px] border-warmcharcoal p-6 font-norm text-warmcharcoal">
                <p className="font-medium mb-2">DEMO Payment: on the next screen use these login details:</p>
                <p>Email: sb-mw9h031809864@personal.example.com</p>
                <p>Password: N-2d*@Ib</p>
            </div> */}
            <div className="pt-12 pb-4 font-norma text-[16px] font-medium text-center">Pay {currency} {Number(total + orderData.order.freightTotal)?.toFixed(2)}</div>
        <PayPalScriptProvider options={initialOptions}>
            <PayPalButtons
            style={{
                shape: "rect",
                layout: "vertical",
                color: "black",
                label: "buynow",
            }}
            message={{amount: total as any}}
            createOrder={async () => {
                try {
                    // console.log('paypal: ', {
                    //     total: Number((total + orderData.order.freightTotal).toFixed(2)),
                    //     currency: currency.replace("$",""),
                    //     cart: cartDataSet
                    // })
                const response = await fetch("/api/payments/paypal", {
                    method: "POST",
                    headers: {
                    "Content-Type": "application/json",
                    },
                    // use the "body" param to optionally pass additional order information
                    // like product ids and quantities
                    body: JSON.stringify(
                        {
                            total: Number((total + orderData.order.freightTotal).toFixed(2)),
                            currency: currency.replace("$",""),
                            cart: cartDataSet
                        }),
                });
    
                const orderInfo = await response.json();
    
                if (orderInfo.id) {
                    return orderInfo.id;
                } else {
                    const errorDetail = orderInfo?.details?.[0];
                    const errorMessage = errorDetail
                    ? `${errorDetail.issue} ${errorDetail.description} (${orderInfo.debug_id})`
                    : JSON.stringify(orderInfo);
    
                    throw new Error(errorMessage);
                }
                } catch (error) {
                // console.log(error);
                setMessage(`Could not initiate PayPal Checkout...${error}`);
                }
            }}
            onApprove={async (
                data,
                actions
            ) => {
                try {
                const response = await fetch(
                    `/api/payments/paypal/${data.orderID}/capture`,
                    {
                    method: "POST",
                    body: JSON.stringify({gslId: orderData?.ref }),
                    headers: {
                        "Content-Type": "application/json",
                    },
                    }
                );
    
                const orderInfo = await response.json();
                // Three cases to handle:
                //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                //   (2) Other non-recoverable errors -> Show a failure message
                //   (3) Successful transaction -> Show confirmation or thank you message
    
                const errorDetail = orderInfo?.details?.[0];
    
                if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                    // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                    // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                    return actions.restart();
                } else if (errorDetail) {
                    // (2) Other non-recoverable errors -> Show a failure message
                    throw new Error(
                    `${errorDetail.description} (${orderInfo.debug_id})`
                    );
                } else {
                    // (3) Successful transaction -> Show confirmation or thank you message
                    // Or go to another URL:  actions.redirect('thank_you.html');
                    const transaction =
                    orderInfo.purchase_units[0].payments.captures[0];
                    setMessage(
                    `Transaction ${transaction.status}: ${transaction.id}. See console for all available details`
                    );

                    actions.redirect(`${process.env.NEXT_PUBLIC_URL}/${lang}/checkout/${orderData?.ref}?p=paypal`)
                }
                } catch (error) {
                // console.log(error);
                setMessage(
                    `Sorry, your transaction could not be processed...${error}`
                );
                }
            }} 
            />
        </PayPalScriptProvider>
            <div className="pt-12 font-norma text-[16px] text-center font-medium mx-auto max-w-[300px]">
                <Message content={message} />
            </div>
        </div>
    );
    }

    
    export default PayPalPayment;
    