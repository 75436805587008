'use client';
import { useState, useEffect } from 'react';
import { getCountryData } from '@/components/getCountryData';
import NextLink from 'next/link';
import NextImage from 'next/image';
import { useLocalStorage } from 'react-use';
import Minus from '@/assets/icons/item-minus.svg';
import Plus from '@/assets/icons/item-plus.svg';
import MinusSmall from '@/assets/icons/item-minus-small.svg';
import PlusSmall from '@/assets/icons/item-plus-small.svg';
import Trash from '@/assets/icons/trash.svg';
import _ from 'lodash';

interface LineItemProps {
    item?: any | null;
    setCartItems?: any | null;
    cartTotal?: any | null;
    setCartTotal?: any | null;
    lang?: any | null;
    index?: any | null;
    uuid?: any | null;
}

const LineItem: React.FC<LineItemProps> = ({ setCartItems, cartTotal, setCartTotal, item, lang, index, uuid }) => {

    // console.log(item)

    const { currency, priceColumn } = getCountryData(lang);

    const [quantity, setQuantity] = useState(item?.quantity || 0);
    const [savedCart, setSavedCart] = useLocalStorage('savedCart') as any;

    useEffect(() => {
        setQuantity(item?.quantity || 0);
    }, []);

    const updateQuantity = async (count: number) => {

        const newQuantity = (quantity + count) > 0 ? quantity + count : 1;
        let updateCartTotal = cartTotal;
        const savedCartString = localStorage.getItem('savedCart');
        const checkCart = savedCartString ? JSON.parse(savedCartString) : [];
    
        if (newQuantity >= 0) {
            if (newQuantity > quantity) {
                setQuantity(newQuantity);
                updateCartTotal += item?.discountPrice || 0;
            } else if (newQuantity < quantity) {
                setQuantity(newQuantity);
                updateCartTotal -= item?.discountPrice || 0;
            }
    
            const newCart = _.map(checkCart, (cartItem: any) => {
                if (cartItem.id === item?.options[0]?.id) {
                    return { ...cartItem, quantity: newQuantity };
                }
                return cartItem;
            });
    
            if (uuid) {
                try {
                    const cartUpdate = await fetch(`/api/cart?uuid=${uuid}&action=update&prod=${item?.options[0]?.id}&quant=${newQuantity}`);
                    const cartResult = await cartUpdate.json();
                    // console.log('Cart update result:', cartResult);
                } catch (error) {
                    console.log('Error updating cart:', error);
                }
            }
            localStorage.setItem('savedCart', JSON.stringify(newCart));
            setCartTotal(updateCartTotal);
        } else {
            // Handle the case where newQuantity is less than 0
            setQuantity(0);
    
            const newCart = _.map(checkCart, (cartItem: any) => {
                if (cartItem.id === item?.options[0]?.id) {
                    return { ...cartItem, quantity: 0 };
                }
                return cartItem;
            });
    
            if (uuid) {
                try {
                    const cartUpdate = await fetch(`/api/cart?uuid=${uuid}&action=update&prod=${item?.options[0]?.id}&quant=0`);
                    const cartResult = await cartUpdate.json();
                    // console.log('Cart update result:', cartResult);
                } catch (error) {
                    console.log('Error updating cart:', error);
                }
            }
            localStorage.setItem('savedCart', JSON.stringify(newCart));
            setCartTotal(cartTotal - (quantity * (item?.discountPrice || 0)));
        }
    };
    


    const removeItem = async (removeId: string) => {
        const itemTotalPrice = quantity * (item?.options[0]?.priceColumns?.[priceColumn] || 0);
        const itemTotalDiscount = quantity * (item?.discountPrice || 0);
        const savedCartString = localStorage.getItem('savedCart');
        const checkCart = savedCartString ? JSON.parse(savedCartString) : [];
        let newCart = [] as any

        if(item?.productdata?.orderType === "Gift Voucher") {
            // console.log('cc: ', checkCart);
            newCart = _.reject(checkCart, (cartItem: any, i: number) => i === index);
            // console.log('here:', newCart);
            setCartItems((prevCartItems: any) => _.reject(prevCartItems, (cartItem: any, i: number) => i === index));
        } 
        else {
            newCart = _.filter(checkCart, (cartItem: any) => cartItem.id !== item?.options[0]?.id) as any
            setCartItems((prevCartItems: any) => prevCartItems.filter((cartItem: any) => cartItem.id !== item?.options[0]?.id));
        }

        localStorage.setItem('savedCart', JSON.stringify(newCart));
        setCartTotal(cartTotal - (itemTotalDiscount > 0 ? itemTotalDiscount : itemTotalPrice));
        
        if (uuid) {
            const cartUpdate = await fetch(`/api/cart?uuid=${uuid}&action=remove&prod=${removeId}`);
            const cartResult = await cartUpdate.json();
            if(newCart?.length <=0 ) {
                const cartCleared = await fetch(`/api/klaviyo/cart/cleared?uuid=${uuid}`);
                const cartClearedResult = await cartCleared.json();
            }
        }
    };

    if (!item || !item.productdata) {
        return null; // Or return a placeholder if desired
    }

    const defaultImage = '/images/default-image.png'

    let liType ="" as any
    let liDesc ="" as any
    let liName="" as any;

    // console.log(item)
    
    switch(item?.extra_details?.voucherType) {
        case 'Digital Card':
            liType = <p>For: {item.extra_details?.name}</p>
            liDesc = <p>Email to: {item.extra_details?.email}</p>;
            liName = "Digital Gift Card"
        break;
        case 'Physical Card':
            liType = <p>For:  {item.extra_details?.name}</p>
            liDesc = <p>Post to:  {item.extra_details?.address ? item.extra_details.address : "checkout delivery address"}</p>;
            liName = "Physical Gift Card"
        break;
        break;
        default: 
            liType = <p>Colour: {item.options[0].option1}</p>
            liDesc = <p>Size: {item.options[0].option2}</p>;
            liName = item.productdata.name
    }

    return (
        <div className={"grid grid-cols-12 relative w-full gap-x-4 border-t-warmcharcoal pt-12 pb-7 md:py-7 " + (index > 0 ? "border-t-[1px]" : "")}>
            <div className="absolute right-0 top-4 md:top-6 z-[1] cursor-pointer hover:opacity-60 text-[12px] font-norma" onClick={() => removeItem(item.options[0].id)}>Remove
                <Trash 
                className="stroke-warmcharcoal inline-block ml-1 w-[20px] h-[22px]" />
            </div>

            <div className="col-span-3">
                <NextLink href={item?.productdata?.orderType === "Gift Voucher" ? '' : `/${lang}/${(item.productdata.productType + "/" + item.productdata.productSubtype + "/" + item.productdata.name + "/" + item.productdata.productOptions[0].option1)?.trim().toLowerCase().replace(/ /g, "-")}`}>
                    <NextImage src={item.options[0]?.image?.link ? item.options[0].image.link : item.productdata.images[0]?.link ? item.productdata.images[0].link : defaultImage} alt={item.productdata.name} width={110} height={110} className="max-h-[100px] object-cover aspect-square" />
                </NextLink>
            </div>
            <div className="col-span-9">
                <NextLink href={item?.productdata?.orderType === "Gift Voucher" ? '' : `/${lang}/${(item.productdata.productType + "/" + item.productdata.productSubtype + "/" + item.productdata.name + "/" + item.productdata.productOptions[0].option1)?.trim().toLowerCase().replace(/ /g, "-")}`}>
                    <>
                        <h5 className="font-norma text-[14px] font-medium leading-[18px]">{liName}</h5>
                        <div className="font-norma text-[14px] leading-[20px] w-full">
                            {liType}
                            {liDesc}
                            <div className="col-span-12 inline-block lg:hidden font-norma text-[14px] uppercase mt-4">
                                <span>
                                    <span className={item.discountPrice !== item.options[0].priceColumns?.[priceColumn] ? "line-through text-warmred  mr-4" : ""} > 
                                        {currency} {(quantity * item.options[0].priceColumns?.[priceColumn]).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </span>
                                    <span className={item.discountPrice === item.options[0].priceColumns?.[priceColumn] ? "hidden" : ""}>
                                        <span className="text-warmred mr-2">{item.discountLabel}</span>  {currency} {(item.discountPrice *quantity).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </span>
                                </span>                            
                            </div>
                        </div>
                    </>
                </NextLink>
                {liName?.indexOf("Gift Card") < 0 ?
                <div className="hidden lg:flex border-[1px] border-warmcharcoal w-[84px] h-[24px] justify-between items-center mt-4">
                    <button className="w-[26px] h-[24px] cursor-pointer inline-flex items-center justify-center" type="button" onClick={() => updateQuantity(-1)}><MinusSmall /></button>
                    <span className="text-[12px] font-norma font-medium">{quantity}</span>
                    <button className="w-[26px] h-[24px] cursor-pointer inline-flex items-center justify-center" type="button" onClick={() => updateQuantity(1)}><PlusSmall /></button>
                </div>
                : <></>}
            </div>
            <div className="hidden lg:flex absolute right-0 bottom-6 items-center justify-end font-norma text-[14px] uppercase">
            <span>
                <span className={item.discountPrice !== item.options[0].priceColumns?.[priceColumn] ? "line-through text-warmred  mr-4" : ""} > 
                    {currency} {(quantity * item.options[0].priceColumns?.[priceColumn]).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </span>
                <span className={item.discountPrice === item.options[0].priceColumns?.[priceColumn] ? "hidden" : ""}>
                    <span className="text-warmred mr-2">{item.discountLabel}</span>  {currency} {(item.discountPrice *quantity).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </span>
            </span>
            </div>
            <div className="flex lg:hidden border-[1px] border-warmcharcoal w-[140px] h-[40px] justify-between items-center mt-6">
                <button className="w-[42px] h-[30px] cursor-pointer inline-flex items-center justify-center" type="button" onClick={() => updateQuantity(-1)}><Minus className="w-[20px] h-[20px]" /></button>
                <span className="text-[12px] font-norma font-medium">{quantity}</span>
                <button className="w-[42px] h-[30px] cursor-pointer inline-flex items-center justify-center" type="button" onClick={() => updateQuantity(1)}><Plus className="w-[20px] h-[20px]" /></button>
            </div>
        </div>
    );
};

export default LineItem;

