'use client'
import { useState, Fragment} from 'react'
import { getCountryData } from '@/components/getCountryData'
import _ from 'lodash';

interface VoucherSetProps {
    voucherType?: any | null
    lang?:any | null
    vouchers?: any | null
    updateCart?: any | null
}

const VoucherSet:React.FC<VoucherSetProps>  = ({voucherType, lang, vouchers, updateCart}) => {

const { currency, priceColumn } = getCountryData(lang);
const [ voucher, setVoucher ] = useState(null) as any
const [ value, setValue ] = useState(null) as any
const [ savedStatus, setSavedStatus] = useState('Add to Cart')
const [ selectedProductIds, setSelectedProductIds ] = useState<{ id: number, quantity: number }[]>([]) as any;
const [ valError, setValError] = useState(null) as any

const [ name, setName ] = useState('') as any
const [ email, setEmail ] = useState('') as any
const [ message, setMessage ] = useState('') as any
const [ address, setAddress ] = useState('') as any

const [componentKey, setComponentKey] = useState(0);

const save = (e: any)=>{

    e.preventDefault();

    if(voucher > 0) {
        const voucherData = {
            voucherType: voucherType,
            voucher: voucher,
            value: value,
            details: {
                name: name,
                email: voucherType === 'Physical Card' ? null : email,
                address: voucherType === 'Physical Card' ? address : null,
                message: message,
                voucherType: voucherType
            }
        } as any

        updateCart(voucherData)
        setAddress('')
        setMessage('')
        setEmail('')
        setName('')
        setVoucher('')
        setValue(0)
    }
    else {
        setValError('- value required')
    }
}

return (
    <form onSubmit={(e:any)=>save(e)} key={componentKey}>
        <div className=" grid grid-cols-12 gap-6">
            <div className="col-span-12 lg:col-span-4 p-4 pr-12">
                {voucherType === 'Physical Card' ?
                    <>
                        <h4 className="font-medium text-[16px] leading-[21px] mb-4">Instore Gift Voucher details</h4>
                        <p className="font-normal text-[14px] text-warmcharcoal">Note: Instore gift vouchers can only be redeemed in person at any of our stores, they will be processed within 48hrs of purchase and sent to your checkout delivery address<br/><br/></p>
                        <p className="font-medium text-[14px] text-warmcharcoal pb-6">Please see terms and conditions for Instore gift cards.</p>
                    </> 
                    :
                    <>
                        <h4 className="font-medium text-[16px] leading-[21px] mb-4">Digital eGift Voucher details</h4>
                        <p className="font-normal text-[14px] text-warmcharcoal mb-4 ">Note: Digital eGift vouchers are dispatched to the recipients email address once payment has been confirmed.</p>
                        <p className="font-medium text-[14px] text-warmcharcoal pb-6">Please see terms and conditions for eGift cards.</p>
                    </>}
            </div>
            <div className="col-span-12 lg:col-span-4 p-4">
                <h5 className="font-medium mb-4"> Recipient details</h5>
                <input type="text" placeholder="Name" className="w-full mb-4 p-2 text-[16px] focus:outline-none focus:ring-[1px] focus:ring-warmcharcoal" value={name} onChange={(e:any)=>{setName(e.target.value);}}/>
                {voucherType === 'Physical Card' ? <></> :
                <input type="email" placeholder="Email" className="w-full mb-4 p-2 text-[16px] focus:outline-none focus:ring-[1px] focus:ring-warmcharcoal" value={email} required={true} onChange={(e:any)=>{setEmail(e.target.value);}}/>}
                {voucherType === 'Physical Card' ?
                <input type="text" placeholder="Optional Address (fee calculated at checkout)" className="w-full mb-4 p-2 text-[16px] focus:outline-none focus:ring-[1px] focus:ring-warmcharcoal" value={address} onChange={(e:any)=>{setAddress(e.target.value);}}/>
                : <></>}
                <textarea placeholder="Message (250 characters)" rows={4} className="w-full mb-4 p-2 text-[16px] focus:outline-none focus:ring-[1px] focus:ring-warmcharcoal" value={message} maxLength={250} onChange={(e:any)=>{setMessage(e.target.value)}}/>
            </div>
            <div className="col-span-12 lg:col-span-4 p-4 h-full relative ">
                <h5 className="font-medium mb-4">Voucher Value {valError !== null ? <span className="text-warmred text-[14px] font-medium">{valError}</span>:<></>}</h5>
                <div className="grid grid-cols-12 gap-4">
                {vouchers?.map((item: any, index: number)=>{
                    if(item.productdata?.productOptions[0]?.option1 === voucherType) {
                    return (
                        <Fragment key={index}>
                            {item.productdata?.productOptions?.map((value: any, vIndex: number)=>{
                                return (
                                    <div className="col-span-4" key={vIndex}>
                                        <button
                                            type="button"
                                            className={
                                                (voucher === value.id
                                                    ? "text-offwhite font-medium bg-warmcharcoal"
                                                    : "text-warmcharcoal bg-white") +
                                                " h-[42px] text-[16px] w-full text-center"
                                            }
                                            onClick={() => {
                                                    setSelectedProductIds([...selectedProductIds, { id: value.id, quantity: 1 }]);
                                                    setVoucher(value.id); // Store only the current voucher
                                                    setValue(value.priceColumns[priceColumn])
                                                    setValError(null)
                                            }}
                                        >
                                            {currency} {value.priceColumns[priceColumn]}
                                        </button>
                                    </div>
                                )
                            })
                        }
                        </Fragment>)}
                    })}
                </div>
                <input type="submit" className="w-full lg:w-auto mt-8 lg:absolute bottom-8 right-4 left-4  border-warmcharcoal border-[1px] h-[42px] font-medium text-[14px] text-offwhite bg-warmcharcoal text-center hover:opacity-60 cursor-pointer" value={savedStatus}/>
            </div>
        </div>
    </form>
)}
export default VoucherSet