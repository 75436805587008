'use client';

import { useEffect, useState } from 'react';
import { getCountryData } from '@/components/getCountryData';

interface ProductViewProps {
    lang?: any | null;
    product?: any | null;
    rules?: any | null;
}

const ProductViewEvent: React.FC<ProductViewProps> = ({ lang, product, rules }) => {

    const { currency, priceColumn } = getCountryData(lang);
    const [loaded, setLoaded] = useState(false)

    // console.log(lang, product, rules)

    const findBestRuleForProduct = (product: any, rules: any) => {
        let bestRule: any = null;
        let highestAmount = 0;
    
        rules?.forEach((rule: any) => {
            product && rule.rules_json?.products?.forEach((rulesProducts: any) => {
                if (rulesProducts.category?.id && product?.categoryIdArray?.includes(rulesProducts.category.id)) {
                    if (rule.rules_json.rules.amount > highestAmount) {
                        highestAmount = rule.rules_json.rules.amount;
                        bestRule = rule;
                    }
                }
                if (rulesProducts.projectname && product?.projectName === rulesProducts.projectname) {
                    if (rule.rules_json.rules.amount > highestAmount) {
                        highestAmount = rule.rules_json.rules.amount;
                        bestRule = rule;
                    }
                }
                if (rulesProducts.productdata && rulesProducts.productdata.id === product.id) {
                    if (rule.rules_json.rules.amount > highestAmount) {
                        highestAmount = rule.rules_json.rules.amount;
                        bestRule = rule;
                    }
                }
                if (rulesProducts === "all") {
                    if (rule.rules_json.rules.amount > highestAmount) {
                        highestAmount = rule.rules_json.rules.amount;
                        bestRule = rule;
                    }
                }
            });
        });
    
        return bestRule?.rules_json?.rules;
    };

    const loadDataLayer = () => {
        if (typeof window !== "undefined") {
            if (!(window as any).dataLayer) {
                (window as any).dataLayer = [];
            }
        }
    };

    useEffect(() => {
        loadDataLayer();

        if (!product || !rules || !product?.productOptions[0]) return;  // Exit early if product or rules are not defined

        const productOptions = product?.productOptions[0];
        const applicablePromotion = findBestRuleForProduct(productOptions, rules);

        let discountPrice = 0;
        let discounted = 0;

        if (applicablePromotion) {
            discounted = applicablePromotion.amount;
            discounted = applicablePromotion.type === "percentage" ? discounted / 100 : discounted;
            discountPrice = Number((productOptions?.priceColumns[priceColumn] - (productOptions?.priceColumns[priceColumn] * discounted)).toFixed(2));
        }


        const productViewData = {
            currency: currency.replace("$", ""),
            value: discountPrice > 0 ? Number(discountPrice.toFixed(2)) : Number(productOptions?.priceColumns[priceColumn]?.toFixed(2)),
            items: [{
                item_id: productOptions.id,
                item_name: product?.name,
                item_category: "Home & Garden > Linens & Bedding",
                item_variant: `${productOptions.option1} - ${productOptions.option2}`,
                value: discountPrice > 0 ? Number(discountPrice.toFixed(2)) : Number(productOptions.priceColumns[priceColumn]?.toFixed(2)),
                quantity: 1,
                discount: discounted ? discounted : null
            }]
        } as any;

        if (typeof window !== "undefined" && (window as any).dataLayer && loaded == false) {
            // console.log("Pushing to dataLayer:", productViewData);
            (window as any).dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            try {
                (window as any).dataLayer.push({
                    event: "view_item",
                    ecommerce: productViewData
                })
            } catch(error:any) {
                // console.log(error)
            }
            setLoaded(true)
        } else {
            // console.log("dataLayer is not defined");
        }

    }, [lang, product, rules]);  // Add dependencies if these props may change

    return null;  // return null instead of an empty fragment if no JSX is needed
}

export default ProductViewEvent;
