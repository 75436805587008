'use client'
import { useState, useEffect } from 'react';
import _ from 'lodash';

interface OtherPaymentProps {
    total?: any | null;
    lang?: any | null;
    type?: any | null;
    orderData?: any | null;
}

const OtherPayment: React.FC<OtherPaymentProps> = ({ lang, total, type, orderData}) => {
    return (
        <div className="p-4 my-4 bg-white">
            <p className="font-norma text-[14px] leading-[21px] font0-medium text-center px-12">Click &ldquo;Pay now with {type}&rdquo;, to complete your purchase on the {type} site.</p>
        </div>
    );
};

export default OtherPayment;
