'use client'; // Ensure this is at the top of the file for Next.js if using app-router

import { useState, useEffect } from 'react';
import { getCountryData } from '@/components/getCountryData';

interface VerifonePaymentProps {
    total?: number | null;
    lang?: any | null;
    orderData?: any | null;
    user?: any | null;
    payment?: any | null;
}

const VerifonePayment: React.FC<VerifonePaymentProps> = ({ lang, total, orderData, user, payment }) => {
    const [cardLink, setCardLink] = useState(null) as any
    const [paymentStatus, setPaymentStatus] = useState('Please wait - preparing payment')
    const { currency, priceColumn } = getCountryData(lang);

    useEffect(() => {
        const getIframe = async (total: any, lang: string) => {
            const body = {
                amount: Number((total + orderData.order.freightTotal).toFixed(2)),
                currency_code: lang === 'global' ? 'USD' : lang.toUpperCase() + 'D',
                lang: lang,
                orderData: orderData?.order,
                orderRef: orderData?.ref
            };
            // console.log('vfone: ', body)
            try {
                const getLink = await fetch('/api/payments/verifone', { 
                    method: 'POST', 
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body) 
                });

                const setLink = await getLink.json();

                // console.log('Please check your shipping details')

                if (!getLink.ok || setLink?.message?.details ) {
                    setPaymentStatus('Please check your shipping details')
                    throw new Error(`HTTP error! status: ${getLink.status}`);
                }
                // setCardLink(setLink?.link?.url + "&containerId=payment_form_container");
                setCardLink(setLink?.link?.url);
            } catch (error: any) {
                // console.log('Error fetching payment link:', error);
            }
        };

        if (total !== null && lang !== null) {
            getIframe(total, lang);
        }
    }, [payment]);

    return (
        <div className="my-4 bg-offwhite p-0 relative">
            {cardLink?.indexOf('https') > -1 ?
                <p className="mt-12 mb-8 py-6 font-norma text-[16px] font-medium text-center text-white bg-warmcharcoal cursor-pointer hover:opacity-60" onClick={()=>window.open(cardLink, "_self")}>Pay now with Verifone {currency} {Number(total+orderData?.order.freightTotal)?.toFixed(2)}</p>
                :  
                <p className="mt-12 mb-8 py-6 font-norma text-[16px] font-medium text-center text-white bg-warmcharcoal cursor-pointer hover:opacity-60">{paymentStatus}</p>
            }
        </div>
    );
};

export default VerifonePayment;

