
import { LinkPanelTile } from '@/components'

interface QuickFindProps {
  slice?: any | null
  updateResultList?: any | null
  results?: any | null
}

const QuickFind: React.FC<QuickFindProps> = ({ slice, updateResultList }) => {

  // console.log('FFF: ', slice)

  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className="py-[50px] pb-[134px] px-[54px]"
    >
      <h3 className="text-[24px] leading-[32px] text-warmcharcoal font-sangbleu font-medium mb-12">{slice.primary.heading}</h3>
      <div className="flex justify-between gap-8 max-w-[1280px] lg:w-full xl:max-w-none">
          {slice?.pageData.results.map((page: any, index:number)=>{
            return (
              <div className="bg-lightwarmgrey flex w-full pt-[10px] relative cursor-pointer hover:opacity-60" key={index}>
                  <LinkPanelTile page={page} updateResultList={updateResultList} />
              </div>
            )
          })}
      </div>
    </section>
  );

}

export default QuickFind;
