'use client'; // Ensure this is at the top of the file for Next.js if using app-router

import { useState, useEffect, useRef } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { CheckoutForm } from '@/components';
import { getCountryData } from '@/components/getCountryData';
import { loadStripe } from '@stripe/stripe-js';
import { useIntersection } from 'react-use';
import _ from 'lodash';

// const apiKey='pk_test_51Pdmbe2Ksl66b2zgZxHjU91FVjSHkctZNjBHXU8PpuXE5IB4CrYGEwrg8zTScusoOLCCvcnDAFnRrioyus7uZ2St00ywu9B10J'
const apiKey = 'pk_live_51Pdmbe2Ksl66b2zg1QxNYw1M17FAbMHrxS7RMw7tdC4lENJUQayha6kn8teUic884qofzifYofVC9a4KVl1PrnN3002oxHhdoE' as string;
const stripePromise = loadStripe(apiKey);

interface StripePaymentProps {
  total?: number | null;
  lang?: any | null;
  orderData?: any | null;
  user?: any | null;
  payment?: any | null;
}

const StripePayment: React.FC<StripePaymentProps> = ({ lang, total, orderData, user, payment }) => {
  const [clientSecret, setClientSecret] = useState("") as any;
  const [paymentStatus, setPaymentStatus] = useState("Loading payments...") as any;
  const { currency, priceColumn } = getCountryData(lang);

  // Ref to track component visibility
  const componentRef = useRef(null);

  // Intersection observer
  const intersection = useIntersection(componentRef, {
    root: null, // Use the viewport as the root
    rootMargin: '0px', // No margin
    threshold: 0.1, // Trigger when 10% of the component is visible
  });

  useEffect(() => {
    // Only fetch Stripe setup if the component is visible
    if (intersection && intersection.isIntersecting) {
      const getStripe = async () => {
        try {
          const stripeSetup = await fetch("/api/payments/stripe", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(orderData),
          });
          const stripeInitiated = await stripeSetup.json();

          // console.log(stripeInitiated)
          if(stripeInitiated?.clientSecret) {
            setClientSecret(stripeInitiated.clientSecret);
          } else {
            setClientSecret(null);
                setPaymentStatus("Please check your shipping details");
            }
        
        } catch (error: any) {
          setPaymentStatus("Please check your shipping details");
        }
      };

      getStripe();
    }
  }, [intersection, total, lang, orderData]);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  } as any;

  return (
    <div ref={componentRef}>
      {clientSecret ? (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm lang={lang} total={total + orderData.order.freightTotal} orderData={orderData} />
        </Elements>
      ) : (
        <p>{paymentStatus}</p>
      )}
    </div>
  );
};

export default StripePayment;
