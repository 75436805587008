export const getCroppedImg = (
    imageSrc: string,
    pixelCrop: any,
    outputWidth: number,
    outputHeight: number
): Promise<File> => {
    
    const createImage = (url: string): Promise<HTMLImageElement> =>
        new Promise((resolve, reject) => {
            const image = new Image();
            image.setAttribute('crossOrigin', 'anonymous');
            image.onload = () => resolve(image);
            image.onerror = (error) => reject(error);
            image.src = url;
        });

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    return createImage(imageSrc).then((image) => {
        canvas.width = outputWidth;
        canvas.height = outputHeight;

        ctx!.drawImage(
            image,
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height,
            0,
            0,
            outputWidth,
            outputHeight
        );

        return new Promise<File>((resolve, reject) => {
            canvas.toBlob((blob) => {
                if (blob) {
                    const file = new File([blob], 'promo-image.jpg', { type: 'image/jpeg' });
                    resolve(file);
                } else {
                    reject(new Error('Canvas is empty'));
                }
            }, 'image/jpeg');
        });
    });
};
