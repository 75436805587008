'use client';
import { useState, useEffect } from 'react';
import { useSession } from 'next-auth/react';
import WishList from '@/assets/icons/wishlist-empty.svg';

interface WishListButtonProps {
    productId?: any | null;
    wishlist?: any | null
    showText?: any | null
}

const WishListButton: React.FC<WishListButtonProps> = ({ productId, wishlist, showText }) => {
    const { data: session, update } = useSession() as any;

    let initialState = false;
    wishlist?.forEach((item: any) => {
        // console.log(item)
        if (Number(item) === productId) {
            initialState = true
        }
    });

    const [active, setActive] = useState(initialState);

    async function updateWishList(command: boolean, id: any, type: string) {
    switch (type) {
        case 'database':
        try {
            const updateWishList = await fetch(`/api/account/wishlist?id=${id}&command=${command}&uuid=${session?.user.uuid}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            });
            setActive(command);
        } catch(error: any) {
            // console.log('Failed to update wishlist:', error);
        }
        break;
        case 'local':
        // Handle local storage logic here
        break;
        default:
    }
    }

    const toggleWishList = async (id: any) => {
        const type = session?.user?.uuid ? 'database' : 'local';
        
        if (active) {
            await updateWishList(false, id, type);
            setActive(false);
        } else {
            await updateWishList(true, id, type);
            setActive(true);
        }
    };

    return (
        <div className="relative hover:opacity-60 cursor-pointer" onClick={() => toggleWishList(productId)}>
            <div className={showText === true ? "absolute hidden lg:block lg:right-6 font-norma text-[14px] -top-[4px] w-24" : "hidden"}>Save to wishlist</div>
            <WishList className={"w-[12px] h-[15px] stroke-warmcharcoal " +(active === true ? 'fill-warmcharcoal' : '')} />
        </div>
    );
};

export default WishListButton;
