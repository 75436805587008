'use client'

import { useSession } from 'next-auth/react';
import { usePathname } from 'next/navigation';
import { useEffect, useState, useRef, lazy, Suspense} from 'react';
import { ShippingMethods, PlacesSearch, ChangeDeliveryAddress, StripePayment} from '@/components';
const LazyVerifonePayment = lazy(() => import('@/components/checkout/paymentMethods/VerifonePayment'));
// const LazyStripePayment = lazy(() => import('@/components/checkout/paymentMethods/StripePayment'));
const LazyPayPalPayment = lazy(() => import('@/components/checkout/paymentMethods/PayPalPayment'));
// const LazyAfterPayPayment = lazy(() => import('@/components/checkout/paymentMethods/AfterPayPayment'));

import _ from 'lodash';
import { useForm, Controller } from 'react-hook-form'; 

import Credit from '@/assets/icons/credit-card.svg';
import AfterPay from '@/assets/icons/afterpay.svg';
import PayPal from '@/assets/icons/paypal.svg';

interface CheckoutPanelProps {
    setShipping?: any | null;
    cartTotal?: any | null;
    cartItems?: any | null;
    lang?: any | null;
    shipping?: any | null;
    setCheckout?: any | null;
    checkout?: any | null;
    orderVoucher?: any | null
    setActiveEvent?: any | null
    voucherDetails?: any | null
    payment?: any | null
    setPayment?: any | null
}

interface Address {
    formatted_address: string;
    address_components: any[];
}

const CheckoutPanel: React.FC<CheckoutPanelProps> = ({
    shipping,
    setShipping,
    lang,
    cartTotal,
    cartItems,
    setCheckout,
    checkout,
    orderVoucher,
    setActiveEvent,
    voucherDetails,
    payment,
    setPayment
    }) => {
    const { data: session } = useSession() as any;
    const user = session?.user;

    const [customer, setCustomer] = useState<any>(null);
    const [region, setRegion] = useState<any>(null);
    const [regionBilling, setRegionBilling] = useState<any>(null);
    
    const [billing, setBilling] = useState(true);
    const [saveAddress, setSaveAddress] = useState(false);
    const [activeAddress, setActiveAddress] = useState(0);
    const [orderData, setOrderData] = useState<any>({});
    const [marketing, setMarketing] = useState(false)

    const billingRef = useRef(null) as any
    const shippingRef = useRef(null) as any

    const [staticCountry, setStaticCountry] = useState<string>('');
    const [staticCountryCode, setStaticCountryCode] = useState<string>('');

    useEffect(() => {
        if (lang === 'nz') {
            setStaticCountry('New Zealand');
            setStaticCountryCode('NZ');
        } else if (lang === 'au') {
            setStaticCountry('Australia');
            setStaticCountryCode('AU');
        } else {
            setStaticCountry('');
            setStaticCountryCode('');
        }
    }, [lang]);

    const { register, handleSubmit, watch, reset, formState: { errors }, setValue } = useForm({
        defaultValues: {
            uuid: user?.uuid ? user.uuid : 'guest',
            delivery_first_name: '',
            delivery_last_name: '',
            delivery_email: '',
            delivery_phone: '',
            delivery_company: '',
            delivery_instructions: '',
            delivery_unit: '',    // Make sure this is included
            delivery_number: '',
            delivery_street: '',
            deliveryAddress: '',
            deliveryAddress2: '',
            deliveryCity: '',
            deliveryState: '',
            deliveryPostalCode: '',
            billing_first_name: '',
            billing_last_name: '',
            billing_email: '',
            billing_phone: '',
            billing_company: '',
            billing_unit: '',     // Make sure this is included
            billing_number: '',
            billing_street: '',
            billingAddress: '',
            billingAddress2: '',
            billingCity: '',
            billingState: '',
            billingPostalCode: '',
            deliveryCountry: lang === 'nz' ? 'New Zealand' : lang === 'au' ? 'Australia' : '',
            deliveryCountryCode: lang === 'nz' ? 'NZ' : lang === 'au' ? 'AU' : '',    
            billingCountry: lang === 'nz' ? 'New Zealand' : lang === 'au' ? 'Australia' : '',
            billingCountryCode: lang === 'nz' ? 'NZ' : lang === 'au' ? 'AU' : '',
        },
    });


    

    useEffect(()=>{
        if(!cartItems || cartItems?.length < 1) {
            setCheckout(false)
        }
    }, [cartItems])

    useEffect(() => {
        const getCustomer = async (uuid: any) => {
        if (!user?.uuid) return;

        const customerData = await fetch(`/api/account/profile?uuid=${uuid}`);
        const customerDataResult = await customerData.json();

        // console.log(customerDataResult)
        const currentAddress = _.findIndex(customerDataResult.addresses, { active: true })

        setActiveAddress(currentAddress)
        setCustomer(customerDataResult);
        };

        getCustomer(session?.user?.uuid);
    }, [session?.user?.uuid, user?.uuid]);

    useEffect(() => {
        if (customer && customer.addresses && activeAddress !== null) {
            const activeDelivery = customer?.addresses?.[activeAddress]?.delivery;
            const activeBilling = customer?.addresses?.[activeAddress]?.billing;
            
            reset({
                uuid: user?.uuid ? user.uuid : 'guest',
                delivery_first_name: activeDelivery?.deliveryFirstName || '',
                delivery_last_name: activeDelivery?.deliveryLastName || '',
                delivery_email: activeDelivery?.deliveryEmail || '',
                delivery_phone: activeDelivery?.deliveryPhone || '',
                delivery_company: activeDelivery?.deliveryCompany || '',
                deliveryCountry: activeDelivery?.country || (lang === 'nz' ? 'New Zealand' : lang === 'au' ? 'Australia' : ''),
                deliveryCountryCode: activeDelivery?.countryCode || (lang === 'nz' ? 'NZ' : lang === 'au' ? 'AU' : ''),
                billing_first_name: activeBilling?.billingFirstName || '',
                billing_last_name: activeBilling?.billingLastName || '',
                billing_email: activeBilling?.billingEmail || '',
                billing_phone: activeBilling?.billingPhone || '',
                billing_company: activeBilling?.billingCompany || '',
                billingCountry: activeBilling?.country || (lang === 'nz' ? 'New Zealand' : lang === 'au' ? 'Australia' : ''),
                billingCountryCode: activeBilling?.countryCode || (lang === 'nz' ? 'NZ' : lang === 'au' ? 'AU' : ''),
            });
    
            setRegion(activeDelivery?.deliveryAddress);
            setRegionBilling(activeBilling?.billingAddress);
        }
    }, [customer, activeAddress, reset, lang]);

    const shippingDetails = watch([
        'delivery_first_name',
        'delivery_last_name',
        'delivery_email',
        'delivery_phone',
        'delivery_company',
        'delivery_instructions',
    ]);

    const handlePaymentMethodClick = (method: string) => {
        if (payment !== method) {
            setPayment(method);
        }
    };

    useEffect(() => {
        if (billing) {
            setValue('billing_first_name', shippingDetails[0]);
            setValue('billing_last_name', shippingDetails[1]);
            setValue('billing_email', shippingDetails[2]);
            setValue('billing_phone', shippingDetails[3]);
            setValue('billing_company', shippingDetails[4]);
        }
    }, [shippingDetails, billing, setValue]);

    const pathname = usePathname();


    const handleAddressSelect = ({ formatted_address, address_components }: Address) => {
        setRegion({ formatted_address, address_components }); // Update delivery address from Google Places
        // Populate form fields directly with Google Places selected values
    };


    const handleBillingAddressSelect = ({ formatted_address, address_components }: Address) => {
        setRegionBilling({ formatted_address, address_components }); // Update billing address from Google Places
        // Populate form fields directly with Google Places selected values
    };
    
    const handleDeliveryInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName: any) => {
        // Handle manual input without affecting the Google Places dropdown
        const updatedValue = e.target.value;
    
        // Update the form manually, but don't update the Google Places component or state
        setValue(fieldName, updatedValue); // Directly update form field without touching the region state
    };
    
    const handleBillingInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName: any) => {
        // Handle manual input without affecting Google Places
        const updatedValue = e.target.value;
        
        // Same as above, directly update the form value
        setValue(fieldName, updatedValue);
    };

    useEffect(() => {
        const scrollableDiv = document.querySelector('.scrollable-div');
      
        const preventDefaultForOutsideScroll = (e:any) => {
          if (!scrollableDiv?.contains(e.target)) {
            e.preventDefault();
          }
        };
      
        document.addEventListener('touchmove', preventDefaultForOutsideScroll, { passive: false });
      
        return () => {
          document.removeEventListener('touchmove', preventDefaultForOutsideScroll);
        };
      }, []);
      

    useEffect(() => {
        const form = document.querySelector('form');
        const autofillEvent = () => {
            const inputs = form?.querySelectorAll('input');
            inputs?.forEach((input) => {
                if (input.value) {
                    const fieldName = input.getAttribute('name');
                    if (fieldName) {
                        handleDeliveryInputChange({ target: { value: input.value } } as any, fieldName);
                    }
                }
            });
        };
    
        // Listen for the form being autofilled
        form?.addEventListener('animationstart', autofillEvent);
        
        // Clean up the event listener
        return () => {
            form?.removeEventListener('animationstart', autofillEvent);
        };
    }, [setValue, handleDeliveryInputChange]);

    useEffect(() => {
        const deliveryComponentMap: Record<string, string> = {
            'subpremise': 'delivery_unit',
            'street_number': 'delivery_number',
            'route': 'delivery_street',
            'sublocality_level_1': 'deliveryAddress2', // Suburb mapping
            'locality': 'deliveryCity',
            'administrative_area_level_1': 'deliveryState',
            'country': 'deliveryCountry',
            'postal_code': 'deliveryPostalCode',
            'country_code': 'deliveryCountryCode', // Use short_name of 'country'
        };
    
        const billingComponentMap: Record<string, string> = {
            'subpremise': 'billing_unit',
            'street_number': 'billing_number',
            'route': 'billing_street',
            'sublocality_level_1': 'billingAddress2', // Suburb mapping
            'locality': 'billingCity',
            'administrative_area_level_1': 'billingState',
            'country': 'billingCountry',
            'postal_code': 'billingPostalCode',
            'country_code': 'billingCountryCode', // Use short_name of 'country'
        };
    
        const updateFormFields = (addressComponents: any[], componentMap: any) => {
            Object.entries(componentMap).forEach(([componentType, fieldName]: [string, any]) => {
                const foundComponent = addressComponents?.find((component: any) => component.types.includes(componentType));
    
                // For country_code, use the short_name of the country component
                if (componentType === 'country_code') {
                    const countryComponent = addressComponents?.find((component: any) => component.types.includes('country'));
                    setValue(fieldName, countryComponent ? countryComponent.short_name : '');
                } else {
                    // Use long_name for other components
                    setValue(fieldName, foundComponent ? foundComponent.long_name : '');
                }
            });
        };
    
        // Handle delivery address form updates
        if (region?.address_components) {
            updateFormFields(region.address_components, deliveryComponentMap);
        } else {
            // Clear delivery fields if no address components are found
            Object.values(deliveryComponentMap).forEach((fieldName: any) => setValue(fieldName, ''));
        }
    
        // Handle billing address form updates
        if (regionBilling?.address_components) {
            updateFormFields(regionBilling.address_components, billingComponentMap);
        } else {
            // Clear billing fields if no address components are found
            Object.values(billingComponentMap).forEach((fieldName: any) => setValue(fieldName, ''));
        }
    }, [region, regionBilling, setValue]);
    
    const componentMap = {
        subpremise: 'subpremise',
        number: 'street_number',
        street: 'route',
        suburb: 'sublocality_level_1',
        city: 'locality',
        state: 'administrative_area_level_1', // State
        country: 'country',
        country_code: 'country', // Short name for country
        post_code: 'postal_code'
    } as any

    const getComponent = (type: any, useShortName: boolean = false) => {
        const component = region?.address_components?.find((component: any) =>
            component.types.includes(type)
        );
        if (!component) return '';
        return useShortName ? component.short_name : component.long_name;
    };

    const getBillingComponent = (type: any, useShortName: boolean = false) => {
        const component = region?.address_components?.find((component: any) =>
            component.types.includes(type)
        );
        if (!component) return '';
        return useShortName ? component.short_name : component.long_name;
    };

    type FormValues = {
        delivery_unit?: string;
        delivery_number?: string;
        delivery_street?: string;
        deliveryAddress2?: string;
        deliveryCity?: string;
        deliveryState?: string;
        deliveryPostalCode?: string;
        deliveryCountry?: string;
        deliveryCountryCode?: string;
        billing_unit?: string;
        billing_number?: string;
        billing_street?: string;
        billingAddress2?: string;
        billingCity?: string;
        billingState?: string;
        billingPostalCode?: string;
        billingCountry?: string;
        billingCountryCode?: string;
    };


    useEffect(() => {
        if (lang === 'global') {
            setValue('deliveryCountry', '');
            setValue('deliveryCountryCode', '');
        } else {
            const country = lang === 'nz' ? 'New Zealand' : lang === 'au' ? 'Australia' : '';
            const countryCode = lang === 'nz' ? 'NZ' : lang === 'au' ? 'AU' : '';
            setValue('deliveryCountry', country);
            setValue('deliveryCountryCode', countryCode);
        }
    }, [lang, setValue]);

    const valueOfDeliveryCity = watch('deliveryCity');

    useEffect(() => {
        if (valueOfDeliveryCity) {
            // console.log(valueOfDeliveryCity)
        }
    }, [valueOfDeliveryCity]);

    const [creatingOrder, setCreatingOrder] = useState('') as any

    return (
        <>
        <div className="border-l-[1px] border-l-warmcharcoal w-screen md:w-[50vw] right-0 px-4 md:px-10 lg:pr-24 overflow-hidden overflow-y-scroll pb-24 h-screen bg-offwhite  scrollable-div">
            <p className="mb-12 font-norma cursor-pointer text-[12px]">
            <span onClick={() => { setPayment(null); setCheckout(false); }}>Your order &gt;</span>{' '}
            <span onClick={() => setPayment(null)}>Shipping</span> {payment ? ' > Payment' : ''}
            </p>
            <h2 className="text-[21px] leading-[32px] font-sangbleu font-medium text-warmcharcoal">Shipping</h2>
            <div className="mt-24 text-[12px] font-norma text-right underline mb-3">
            {!user ? (
                <a href="?auth=login">Have an account? Log in</a>
            ) : (
                <></>
                // <ChangeDeliveryAddress
                // addresses={customer?.addresses || []}
                // activeAddress={activeAddress}
                // setActiveAddress={setActiveAddress}
                // uuid={session?.user?.uuid}
                // />
            )}
            </div>
            <div className="relative">
            {Object.keys(errors).length > 0 ? <p className="text-warmred font-medium font-norma text-[16px] absolute -top-9">Please check your shipping details</p> :<></>}
            </div>
            <form
            onSubmit={handleSubmit(async (data: any) => {

                const billing2 = data.deliveryAddress2 === '' ? "N/A" : data.deliveryAddress2
                const delivery2 = data.deliveryAddress2 === '' ? "N/A" : data.deliveryAddress2
                const subPremise = data.delivery_unit ? data.delivery_unit +"/" :"";
                const subPremiseBill = data.billing_unit ? data.billing_unit +"/" :"";
                data.saveAddress = saveAddress;
                data.marketing = marketing;
                data.voucher = orderVoucher;
                data.deliveryAddress1 = subPremise + data.delivery_number+ " " + data.delivery_street;
                data.billingAddress1 = subPremiseBill + data.billing_number ? data.billing_number+ " " + data.billing_street : data.delivery_number+ " " + data.delivery_street;
                data.billingAddress2 = billing2
                data.deliveryAddress2 = delivery2
                data.billing_company = data.billing_company === '' ? data.delivery_company : data.billing_company;
                data.billing_email = data.billing_email === '' ? data.delivery_email : data.billing_email;
                data.billing_first_name = data.billing_first_name === '' ? data.delivery_first_name : data.billing_first_name;
                data.billing_last_name = data.billing_last_name === '' ? data.delivery_last_name : data.billing_last_name;
                data.billing_phone = data.billing_phone === '' ? data.delivery_phone : data.billing_phone;

                // duplicate if no billing
                if(billing === true) {
                    data.billingAddress1 = subPremise + data.delivery_number+ " " + data.delivery_street
                    data.billingAddress2 = data.deliveryAddress2
                    data.billingCity = data.deliveryCity
                    data.billingPostalCode = data.deliveryPostalCode
                    data.billingState = data.deliveryState
                    data.billingCountry = data.deliveryCountry
                    data.billingCountryCode = data.deliveryCountryCode
                }

                const confirmLang = lang === 'global' ? 'nz' : lang

                data.shipping = shipping;
                data.total = cartTotal;
                data.lang = lang;
                data.branch_email = 'gsl@gsl.com';
                data.currency_code = lang === 'global' ? 'USD' : lang.toUpperCase() + 'D';
                data.cartItems = cartItems;
                data.uuid = user?.uuid;
                data.member_id = user?.cin7_id;
                data.purchase_url = `${pathname}`;
                data.distributionBranchId = shipping.branch
                data.voucherDetails = voucherDetails
            
                if(data.deliveryAddress1 && data.delivery_number !=="" && data.delivery_street !=="" && (data.deliveryCountryCode.toLowerCase() === confirmLang || lang === "global")) {
                    setCreatingOrder('Creating Order ...')
                    const createOrder = await fetch('/api/orders', { method: 'post', body: JSON.stringify(data) });
                    const orderResult = await createOrder.json();
                    const klaviyoCreateOrder = await fetch('/api/klaviyo/orders', { method: 'post', body: JSON.stringify({uuid: user?.uuid, order_id: orderResult?.ref, marketing: marketing}) });
                    const klaviyoCreateOrderResponse = await klaviyoCreateOrder.json();
                    
                    setActiveEvent('add_shipping_info')
                    setPayment('choose');
                    setOrderData(orderResult);
                    setCreatingOrder('')
                } else {
                    alert(`This site's location only ships to ${lang} addresses`)
                }
            })}
            >
            <input type="hidden" {...register('uuid')} />
            <input
                className={`focus:outline-none focus:ring-[1px] h-[42px] text-[16px] md:text-[14px] font-norma inline-block px-5 mb-5 w-full md:w-[50%] md:mr-[16px] 
                    ${errors.delivery_first_name ? ' text-warmred bg-warmred/10 placeholder:text-warmred focus:ring-warmred border-[1px]' : ' focus:ring-warmcharcoal border-[1px] bg-white'}`}
                type="text"
                placeholder="First name"
                {...register('delivery_first_name', { required: 'Delivery first name is required' })}
            />

            <input
                className={` focus:outline-none focus:ring-[1px] h-[42px] text-[16px] md:text-[14px] font-norma inline-block px-5 mb-5 w-full md:w-[calc(50%_-_16px)] 
                    ${errors.delivery_last_name ? ' text-warmred bg-warmred/10 placeholder:text-warmred focus:ring-warmred border-[1px]' : ' focus:ring-warmcharcoal border-[1px] bg-white'}`}
                type="text"
                placeholder="Last name"
                {...register('delivery_last_name', { required: 'Delivery last name is required' })}
            />

            <input
                className={` focus:outline-none focus:ring-[1px] h-[42px] text-[16px] md:text-[14px] font-norma px-5 mb-5 w-full md:w-[50%] md:mr-[16px] 
                    ${errors.delivery_email ? ' text-warmred bg-warmred/10 placeholder:text-warmred focus:ring-warmred border-[1px]' : ' focus:ring-warmcharcoal border-[1px] bg-white'}`}
                type="email"
                placeholder="Email"
                {...register('delivery_email', {
                    required: 'Delivery email is required',
                    pattern: {
                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: 'Invalid delivery email address',
                    },
                })}
            />

            <input
                className={` focus:outline-none focus:ring-[1px] h-[42px] text-[16px] md:text-[14px] font-norma px-5 mb-5 w-full md:w-[calc(50%_-_16px)] 
                    ${errors.delivery_phone ? ' text-warmred bg-warmred/10 placeholder:text-warmred focus:ring-warmred border-[1px]' : ' focus:ring-warmcharcoal border-[1px] bg-white'}`}
                type="text"
                placeholder="Phone number (just in case)"
                {...register('delivery_phone', { required: 'Delivery phone number is required' })}
            />

            <input
                className={` focus:outline-none focus:ring-[1px] h-[42px] text-[16px] md:text-[14px] font-norma px-5 mb-5 w-full md:w-[50%] md:mr-[16px] 
                    ${errors.delivery_company ? ' text-warmred bg-warmred/10 placeholder:text-warmred focus:ring-warmred border-[1px]' : ' focus:ring-warmcharcoal border-[1px] bg-white'}`}
                type="text"
                placeholder="Company (optional)"
                {...register('delivery_company')}
            />

            <input
                className={` focus:outline-none focus:ring-[1px] h-[42px] text-[16px] md:text-[14px] font-norma px-5 mb-5 w-full md:w-[calc(50%_-_16px)] 
                    ${errors.delivery_instructions ? ' text-warmred bg-warmred/10 placeholder:text-warmred focus:ring-warmred border-[1px]' : ' focus:ring-warmcharcoal border-[1px] bg-white'}`}
                type="text"
                placeholder="Delivery instructions"
                {...register('delivery_instructions')}
            />

            <div className="relative">
            
            <p className="font-norma font-medium text-[14px] mt-6 lg:mt-2 mb-2 xxl:absolute ">{lang !=='global' ? lang.toUpperCase() : ''} Shipping Address <span className="font-normal text-[11px]"> - required for all sales {lang==='nz' ? '(edit for things like RD numbers etc)' : lang ==='au' ? '(edit for things like AusPost parcel lockers etc)' : '(edit if you need to)'}</span></p>
            <div className="w-full xxl:w-[50%] xxl:ml-[50%] mr-[16px] inline-block" ref={shippingRef}>
                <PlacesSearch
                input={{ name: 'delivery_address', type: 'address', label: 'D&#8204;elivery Address', required: true, data: customer?.addresses?.[activeAddress].delivery.deliveryAddress || '' }}
                lang={lang}
                onAddressSelect={handleAddressSelect}
                />
            </div>
            </div>
            <div className="grid grid-cols-12 gap-[1px] w-full">
            {Object.entries(componentMap).map(([fieldName, fieldType], index) => {
                const isShortNameField = fieldName === 'country_code' || fieldName === 'state';
                const componentValue = getComponent(fieldType, isShortNameField);
                
                let fieldLabel = '';
                let formDeliveryName = '' as keyof FormValues;
                let colSpanWidth = '';
                let staticValue = '' as any;
                let isReadOnly = false; // Default is not read-only
                let required = false as any; // Default auto value
                
                // Set labels, field names, and column widths based on field type
                switch (fieldName) {
                    case 'subpremise':
                        fieldLabel = 'Unit';
                        formDeliveryName = 'delivery_unit';
                        colSpanWidth = 'col-span-2 xl:col-span-1';
                        break;
                    case 'number':
                        fieldLabel = 'No.';
                        formDeliveryName = 'delivery_number';
                        colSpanWidth = 'col-span-2 xl:col-span-1';
                        required = true;
                        break;
                    case 'street':
                        fieldLabel = 'Street name';
                        formDeliveryName = 'delivery_street';
                        colSpanWidth = 'col-span-8 xl:col-span-10';
                        required = true;
                        break;
                    case 'suburb':
                        fieldLabel = 'Suburb / Parcel Locker';
                        formDeliveryName = 'deliveryAddress2';
                        colSpanWidth = 'col-span-12 md:col-span-6';
                        break;
                    case 'city':
                        fieldLabel = 'City';
                        formDeliveryName = 'deliveryCity';
                        colSpanWidth = 'col-span-12 md:col-span-6';
                        required = true;
                        break;
                    case 'state':
                        fieldLabel = 'State';
                        formDeliveryName = 'deliveryState';
                        colSpanWidth = 'col-span-12 md:col-span-6';
                        break;
                    case 'post_code':
                        fieldLabel = 'Post code';
                        formDeliveryName = 'deliveryPostalCode';
                        colSpanWidth = 'col-span-12 md:col-span-6';
                        break;
                    case 'country':
                        fieldLabel = 'Country';
                        formDeliveryName = 'deliveryCountry';
                        colSpanWidth = 'col-span-12 md:col-span-6';
                        staticValue = staticCountry;
                        isReadOnly = lang !== 'global';
                        break;
                    case 'country_code':
                        fieldLabel = 'Country code';
                        formDeliveryName = 'deliveryCountryCode';
                        colSpanWidth = 'col-span-12 md:col-span-6';
                        staticValue = staticCountryCode;
                        isReadOnly = lang !== 'global';
                        break;
                    default:
                        break;
                }

                const fieldError = errors[formDeliveryName];

                return (
                    <div className={`${colSpanWidth} w-full`} key={index}>
                        <label className="relative inline-block w-full">
                            <span className={"absolute left-3 text-[11px] top-1 "+(fieldError ? 'text-warmred':'text-warmcharcoal/60 ')}>
                                {fieldLabel}
                            </span>
                            <input
                                className={`bg-lightwarmgrey font-normal text-[14px] pt-6 pb-2 px-3 focus:outline-none w-full ${
                                    fieldError
                                        ? ' text-warmred bg-warmred/10 placeholder:text-warmred focus:ring-warmred border-[1px]'
                                        : ' text-warmcharcoal focus:ring-warmcharcoal border-[1px]'
                                } ${isReadOnly ? 'text-warmcharcoal/40' : ''}`}
                                type="text"
                                {...register(formDeliveryName, {
                                    required: required,
                                    onChange: (e) => handleDeliveryInputChange(e, fieldName),
                                })}
                                readOnly={isReadOnly}
                                defaultValue={staticValue || ''}
                            />
                        </label>
                        
                    </div>
                );
            })}

            </div>
            
            {user?.email ? (
                <label className="relative my-4 block">
                <span className="w-[30px] h-[30px] rounded-full inline-block cursor-pointer p-[6px] bg-white" onClick={() => setSaveAddress(!saveAddress)}>
                    <span className={(saveAddress === true ? 'bg-warmcharcoal' : 'bg-white') + ' w-full h-full rounded-full inline-block cursor-pointer'}></span>
                </span>
                <input className="hidden" type="radio" />
                {saveAddress === false ? (
                    <span className="inline-block font-norma text-[14px] font-medium warmgrey relative ml-2 -top-1">Save address for next time</span>
                ) : (
                    <span className="inline-block font-norma text-[14px] font-medium warmgrey relative ml-2 -top-1">Address will be saved for next time</span>
                )}
                </label>
            ) : (
                <></>
            )}
            <p className="font-norma font-medium text-[14px] mt-6 mb-2">Shipping Methods</p>
            <ShippingMethods lang={lang} cartTotal={cartTotal} cartItems={cartItems} deliveryCity={valueOfDeliveryCity} setShipping={setShipping} place={region || customer?.delivery_address || ''} />
                
            <p className="font-norma font-medium text-[14px] mt-16 mb-2">Billing details</p>
            <label className="relative my-4 block">
                <span className="w-[30px] h-[30px] rounded-full inline-block cursor-pointer p-[6px] bg-white" onClick={() => setBilling(!billing)}>
                <span className={(billing === true ? 'bg-warmcharcoal' : 'bg-white') + ' w-full h-full rounded-full inline-block cursor-pointer'}></span>
                </span>
                <input className="hidden" type="radio" />
                {billing === false ? (
                <span className="inline-block font-norma text-[14px] font-medium warmgrey relative ml-2 -top-1">Enter billing details</span>
                ) : (
                <span className="inline-block font-norma text-[14px] font-medium warmgrey relative ml-2 -top-1">Billing details are the same as shipping</span>
                )}
            </label>
            {billing === false ? (
                <div className={billing === false ? 'block' : 'hidden'} >
                <input
                    className={` focus:outline-none focus:ring-[1px] h-[42px] text-[16px] md:text-[14px] font-norma inline-block px-5 mb-5 w-full md:w-[50%] md:mr-[16px] 
                        ${errors.billing_first_name ? ' text-warmred bg-warmred/10 placeholder:text-warmred focus:ring-warmred border-[1px]' : ' focus:ring-warmcharcoal border-[1px] bg-white'}`}
                    type="text"
                    placeholder="First name"
                    {...register('billing_first_name', { required: 'Billing first name is required' })}
                />

                <input
                    className={` focus:outline-none focus:ring-[1px] h-[42px] text-[16px] md:text-[14px] font-norma inline-block px-5 mb-5 w-full md:w-[calc(50%_-_16px)] 
                        ${errors.billing_last_name ? ' text-warmred bg-warmred/10 placeholder:text-warmred focus:ring-warmred border-[1px]' : ' focus:ring-warmcharcoal border-[1px] bg-white'}`}
                    type="text"
                    placeholder="Last name"
                    {...register('billing_last_name', { required: 'Billing last name is required' })}
                />

                <input
                    className={` focus:outline-none focus:ring-[1px] h-[42px] text-[16px] md:text-[14px] font-norma px-5 mb-5 w-full md:w-[50%] md:mr-[16px] 
                        ${errors.billing_email ? ' text-warmred bg-warmred/10 placeholder:text-warmred focus:ring-warmred border-[1px]' : ' focus:ring-warmcharcoal border-[1px] bg-white'}`}
                    type="email"
                    placeholder="Billing Email"
                    {...register('billing_email', {
                        required: 'Billing email is required',
                        pattern: {
                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            message: 'Invalid billing email address',
                        },
                    })}
                />

                <input
                    className={` focus:outline-none focus:ring-[1px] h-[42px] text-[16px] md:text-[14px] font-norma px-5 mb-5 w-full md:w-[calc(50%_-_16px)] 
                        ${errors.billing_phone ? ' text-warmred bg-warmred/10 placeholder:text-warmred focus:ring-warmred border-[1px]' : ' focus:ring-warmcharcoal border-[1px] bg-white'}`}
                    type="text"
                    placeholder="Phone number"
                    {...register('billing_phone', { required: 'Phone number is required' })}
                />

                <input
                    className={` focus:outline-none focus:ring-[1px] h-[42px] text-[16px] md:text-[14px] font-norma px-5 mb-5 w-full md:w-[50%] md:mr-[16px] 
                        ${errors.billing_company ? ' text-warmred bg-warmred/10 placeholder:text-warmred focus:ring-warmred border-[1px]' : ' focus:ring-warmcharcoal border-[1px] bg-white'}`}
                    type="text"
                    placeholder="Company (optional)"
                    {...register('billing_company')}
                />
                <div className="grid grid-cols-12 gap-[1px] w-full">
                {Object.entries(componentMap).map(([fieldName, fieldType], index) => {
                    const isShortNameField = fieldName === 'country_code' || fieldName === 'state';
                    const componentValue = getBillingComponent(fieldType, isShortNameField);
                    
                    let fieldLabel = '';
                    let formBillingName = '' as keyof FormValues;
                    let colSpanWidth = '' as any;
                    let staticValue ='' as any
                    let isReadOnly = false; // Default is not read-only
                    let required = false as any; // Default auto value

                    // Set labels, field names, and column widths based on field type
                    switch (fieldName) {
                        case 'subpremise':
                            fieldLabel = 'Unit';
                            formBillingName = 'billing_unit';
                            colSpanWidth = 'col-span-2 xl:col-span-1';
                            break;
                        case 'number':
                            fieldLabel = 'No.';
                            formBillingName = 'billing_number';
                            colSpanWidth = 'col-span-2 xl:col-span-1';
                            required=true;
                            break;
                        case 'street':
                            fieldLabel = 'Street name';
                            formBillingName = 'billing_street';
                            colSpanWidth = 'col-span-8 xl:col-span-10';
                            required=true;
                            break;
                        case 'suburb':
                            fieldLabel = 'Suburb / Parcel Locker';
                            formBillingName = 'billingAddress2';
                            colSpanWidth = 'col-span-12 md:col-span-6';
                            break;
                        case 'city':
                            fieldLabel = 'City';
                            formBillingName = 'billingCity';
                            colSpanWidth = 'col-span-12 md:col-span-6';
                            required=true;
                            break;
                        case 'state':
                            fieldLabel = 'State';
                            formBillingName = 'billingState';
                            colSpanWidth = 'col-span-12 md:col-span-6';
                            break;
                        case 'post_code':
                            fieldLabel = 'Post code';
                            formBillingName = 'billingPostalCode';
                            colSpanWidth = 'col-span-12 md:col-span-6';
                            break;
                        case 'country':
                            fieldLabel = 'Country';
                            formBillingName = 'billingCountry';
                            colSpanWidth = 'col-span-12 md:col-span-6';
                            break;
                        case 'country_code':
                            fieldLabel = 'Country code';
                            formBillingName = 'billingCountryCode';
                            colSpanWidth = 'col-span-12 md:col-span-6';
                            break;
                        default:
                            break;
                    }

                    const fieldError = errors[formBillingName];

                    return (
                        <div className={`${colSpanWidth} w-full`} key={index}>
                            <label className="relative inline-block w-full">
                                <span className={"absolute left-3 text-[11px] top-1 "+(fieldError ? 'text-warmred':'text-warmcharcoal/60 ')}>
                                    {fieldLabel}
                                </span>
                                <input
                                    className={`bg-lightwarmgrey font-normal text-[14px] pt-6 pb-2 px-3 focus:outline-none w-full ${
                                        fieldError
                                            ? ' text-warmred bg-warmred/10 placeholder:text-warmred focus:ring-warmred border-[1px]'
                                            : ' text-warmcharcoal focus:ring-warmcharcoal border-[1px]'
                                    } ${isReadOnly ? 'text-warmcharcoal/40' : ''}`}
                                    type="text"
                                    {...register(formBillingName, {
                                        required: required,
                                        onChange: (e) => handleBillingInputChange(e, fieldName),
                                    })}
                                    readOnly={isReadOnly}
                                    defaultValue={staticValue || ''}
                                />
                            </label>
                        </div>
                    );
            })}
                </div>
                </div>
            ) : (
                <></>
            )}
            <div className="relative text-[14px] text-warmcharcoal font-norma mt-4" onClick={() => setMarketing(!marketing)}>
                <span className={"rounded-full h-[30px] w-[30px] inline-block mr-3 p-[6px] bg-white"}>
                    <span className={(marketing === true ? "bg-warmcharcoal" : "bg-white") + " w-full h-full rounded-full block cursor-pointer"}></span>
                </span>
                <span className="absolute top-2 inline-block leading-[18px]">Subscribe to receive communications from George Street Linen. </span>
            </div>
            <input
                type="submit"
                className="text-offwhite text-[14px] font-norma font-medium bg-warmcharcoal w-full h-[42px] mt-8 mb-16 cursor-pointer hover:opacity-60"
                value={creatingOrder === '' ? Object.keys(errors).length > 0 ? "Please complete the form" : "Confirm order detail" : creatingOrder}
            />
            </form>
        </div>
        <div className={(payment ? 'left-[1px] right-0 md:left-auto opacity-100' : '-right-[60vw] opacity-0') + ' pb-12 overflow-y-scroll scrollbar-hide absolute top-[40px] md:pt-[90px] bottom-0 bg-offwhite h-auto w-screen md:w-[49.5vw] px-4 lg:pl-8 lg:pr-24 transition-all duration-300 z-[1150]'}>
            <p className="text-[21px] leading-[32px] font-sangbleu font-medium text-warmcharcoal mt-4 md:mt-0 lg:mt-12">Payment Details</p>

            <div className="grid grid-cols-3 gap-3 mt-8 relative">
                {lang !== "global" && (<div
                        className={(payment === 'Card' ? 'border-[2px]' : 'border-[1px]') + ' border-warmcharcoal mb-6 h-[50px] px-2 pt-[6px] cursor-pointer bg-white'}
                        onClick={() => handlePaymentMethodClick('Card')}
                    >
                        <Credit />
                        <p className="text-[14px] text-warmcharcoal font-norma font-medium p-0 m-0">Card</p>
                    </div>
                )}
                {lang !== "au" && (
                    <div
                        className={(payment === 'PayPal' ? 'border-[2px]' : 'border-[1px]') + ' border-warmcharcoal mb-6 h-[50px] px-2 pt-[6px] cursor-pointer bg-white'}
                        onClick={() => handlePaymentMethodClick('PayPal')}
                    >
                        <PayPal />
                        <p className="text-[14px] text-warmcharcoal font-norma font-medium p-0 m-0">PayPal</p>
                    </div>
                )}
                {/* <div
                    className={(payment === 'AfterPay' ? 'border-[2px]' : 'border-[1px]') + ' border-warmcharcoal mb-6 h-[50px] px-2 pt-[6px] cursor-pointer bg-white'}
                    onClick={() => handlePaymentMethodClick('AfterPay')}>
                        <AfterPay />
                    <p className="text-[14px] text-warmcharcoal font-norma font-medium p-0 m-0">AfterPay</p>
                </div> */}
            </div>

            {payment === 'choose' && (
                <div className="p-4 my-4 bg-white">
                    <p className="font-norma text-[14px] leading-[21px] font-medium text-center px-12">Click on a payment type.</p>
                </div>
            )}
            <div className="">
                {payment === 'Card' && lang === "au" && <StripePayment total={cartTotal} lang={lang} orderData={orderData} user={user} payment={payment} />}
                <Suspense fallback={<div className="text-center font-norma font-medium text-[14px] mb-[100px]">Loading...</div>}>
                    {/* {payment === 'Card' && lang === "au" && <LazyStripePayment total={cartTotal} lang={lang} orderData={orderData} user={user} payment={payment} />} */}
                    {payment === 'Card' && lang === "nz" && <LazyVerifonePayment total={cartTotal} lang={lang} orderData={orderData} user={user} payment={payment} />}
                    {payment === 'PayPal' && <LazyPayPalPayment total={cartTotal} lang={lang} orderData={orderData} user={user} />}
                    {/* {payment === 'AfterPay' && <LazyAfterPayPayment total={cartTotal} lang={lang} orderData={orderData} />} */}
                </Suspense>
            </div>
    </div>
    {payment ? 
        <div className="bg-offwhite/80 w-[50vw] fixed top-0 left-0 bottom-0 hidden md:block"></div>
    :   <></>}
        
        </>
    );
};

export default CheckoutPanel;


