'use client';
import { signOut, useSession } from "next-auth/react"
import { PrismicNextImage } from '@prismicio/next'
import WishListEmpty from "@/assets/icons/wishlist-empty.svg";

interface WelcomePanelProps {
    welcomeImage: any | null
}

const WelcomePanel:React.FC<WelcomePanelProps> = ({ welcomeImage }) => {

    const { data: session, status } = useSession()
    const user = session?.user as any;

    const logout = async () => {
        await signOut(); 
        window.open("/", "_self")
    }

    return (
        <>
        <h2 className="font-sangbleu font-medium text-[21px] leading-[32px] mb-12 md:mb-[120px]">Welcome, {user?.first_name}</h2>
        <div className="grid grid-cols-12 w-full">
            <div className="col-span-12 md:col-span-5 pr-12">
                <p className="text-[14px] text-warmcharcoal font-norma mb-4 max-w-[66%] md:max-w-none">{!user?.admin ? "" : "Your account details are also available in the top menu."}</p>
                <ul>
                    {user?.admin ? <li className="font-norma font-medium text-[14px] mt-12 leading-[18px] py-2 md:py-4 cursor-pointer hover:opacity-60"><a href="/manage">Manage</a></li> :<></>}
                    <li className="font-norma font-medium text-[14px] leading-[18px] py-2 md:py-4 cursor-pointer hover:opacity-60"><a href="/account/orders">Orders</a></li>
                    <li className="font-norma font-medium text-[14px] leading-[18px] py-2 md:py-4 cursor-pointer hover:opacity-60"><a href="/account/wishlist">Wishlist <WishListEmpty className="inline-block w-[10px] h-[10px] ml-1 -mt-[2px]"/></a></li>
                    <li className="font-norma font-medium text-[14px] leading-[18px] py-2 md:py-4 cursor-pointer hover:opacity-60"><a href="/account/profile">Profile</a></li>
                    <li className="font-norma font-medium text-[14px] leading-[18px] py-2 md:py-4 cursor-pointer hover:opacity-60" onClick={() => logout()}>Logout</li>
                </ul>
            </div>
            <div className="col-span-12 md:col-span-7 w-full mt-12">
                <PrismicNextImage field={welcomeImage} className="w-screen max-h-[40vh] object-cover object-center" fallbackAlt=""/>
            </div>
        </div>
        </>
    )
}
export default WelcomePanel
