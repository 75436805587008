"use client";

import TagManager, { DataLayerArgs } from "@sooro-io/react-gtm-module";
import { useLayoutEffect } from "react";

function DataLayer(args: DataLayerArgs) {
    useLayoutEffect(() => {
        TagManager.dataLayer({ ...args });
    }, [args]);

    return <></>;
}

export default DataLayer