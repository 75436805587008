"use client"
import { useState } from 'react'
import { useSession } from "next-auth/react"
import { PlacesSearch } from '@/components'
import GreyTick from '@/assets/icons/grey-tick.svg'
import Loading from '@/assets/icons/loading.svg'

interface FormFieldProps {
    input?: any | null
    lang?: any | null
}

const FormField: React.FC<FormFieldProps> = ({ input, lang }) => {

    const [checked, setChecked] = useState(input.data === "true" ? true : false)
    const [updated, setUpdated] = useState(false)
    const [loading, setLoading] = useState(false)

    const { data: session, status } = useSession()

    const user = session?.user as any;

    const updateData = async (field: any, value: any) => {

        const user = session?.user as any;
        const newData = {
                            field: field,
                            value: value,
                            uuid: user?.uuid
                        } as any;

        setUpdated(false);
        setLoading(true);

        const update = await fetch('/api/account/profile', {method: 'post', body: JSON.stringify(newData)})
        const updateResult = await update.json()

        // console.log(updateResult)
        
        setLoading(false);

        updateResult?.status === 200 ? setUpdated(true) : setUpdated(false);

        // setTimeout(()=>setUpdated(false), 1000)
        
        return null
    }


    switch (input.type) {
        case "select":
            return (
                <div className={"relative bg-white  md:w-full  h-[42px] " + (input.cols === 6 ? "col-span-12 md:col-span-6" : "col-span-12")}>
                    <GreyTick className={(input.readonly === true || updated === false ? "hidden" : "") + " absolute right-0 m-3"}/>
                    <Loading className={(loading === false ? "hidden" : "fill-warmcharcoal") + " w-[30px] h-[30px] absolute right-0  my-[6px] mx-2"}/>
                    <select className="h-[42px] max-w-[88%] px-4 bg-white w-full   text-[16px] md:text-[14px]" onChange={(e:any) => updateData(input.name, e.target.value)}>
                        <option className="bg-white" value="">{input.label}</option>
                        {input.options?.map((item: any, index: number) => {
                            if(item === input.data) { return (
                                <option className="bg-white w-full" value={item} key={index} selected>{item}</option>
                            )} else {
                                return (
                                <option className="bg-white" value={item} key={index}>{item}</option>
                                )
                            }
                        })}
                    </select>
                </div>
            )
        break;
        case "checkbox":
            // console.log("cb: ", input)
            return (
                <div className={"relative " + (input.cols === 6 ? "col-span-12 md:col-span-6" : "col-span-12")}>
                    <GreyTick className={(input.readonly === true || updated === false ? "hidden" : "") + " absolute right-0 m-3 -top-[5px]"}/>
                    <Loading className={(loading === false ? "hidden" : "fill-warmcharcoal") + " w-[30px] h-[30px] absolute right-0 my-0 mx-0"}/>
                    <label className="flex items-center">
                        <span
                            className=" w-[30px] h-[30px] rounded-full inline-block cursor-pointer p-2 bg-white"
                            onClick={() => setChecked(!checked)}
                        ><span className={(checked ? "bg-warmcharcoal" : "bg-white") + " w-[14px] h-[14px] md:w-full md:h-full rounded-full block cursor-pointer"}
                        ></span></span>
                        <input
                            className="hidden"
                            type={input.type}
                            name={input.name}
                            required={input.required}
                            checked={checked}
                            onChange={() => updateData(input.name, checked)}
                        />
                        <span className="inline-block ml-3 text-[14px]">{input.label}</span>
                    </label>
                </div>
            )
        break;
        case 'button':
            return (
                <div className={"relative " + (input.cols === 6 ? "col-span-12 md:col-span-6" : "col-span-12")}>
                    <button className="h-[42px] w-full px-4 font-medium text-[14px] bg-warmcharcoal text-offwhite" type={input.type} onClick={()=> updated=== false && loading === false ? window.open(input.destination, "_self") : null}>{input.label}</button>
                </div>
            )
        break;
        case 'address':
            return (
                <div className={"relative " + (input.cols === 6 ? "col-span-12 md:col-span-6" : "col-span-12")}>
                    <GreyTick className={(input.readonly === true || updated === false ? "hidden" : "") + " absolute right-0 m-3 z-9"}/>
                    <Loading className={(loading === false ? "hidden" : "fill-offwhite") + " w-[30px] h-[30px] absolute right-0  my-[6px] mx-2"}/>
                    {/* <PlacesSearch input={input} lang={lang}/> */}
                </div>
            )
        break;
        default:
            return (
                <div className={"relative " + (input.cols === 6 ? "col-span-12 md:col-span-6" : "col-span-12")}>
                    <GreyTick className={(input.readonly === true || updated === false ? "hidden" : "") + " absolute right-0 m-3"}/>
                    <Loading className={(loading === false ? "hidden" : "fill-warmcharcoal") + " w-[30px] h-[30px] absolute right-0  my-[6px] mx-2"}/>
                    <input className={"h-[42px]  text-[16px] md:text-[14px] w-full px-4 focus:outline-none focus:ring-warmcharcoal focus:ring-[1px] " +(input.readonly === true ? "bg-transparent border-[1px] border-warmgrey/50 cursor-pointer" : "bg-white")} type={input.type} name={input.name} required={input.required} placeholder={input.label} defaultValue={input.data} onClick={()=> input.readonly === true ? window.open('mailto:support@georgestreetlinen.com?subject=Change account email&body=Please change my email to:', '_self') : null} onBlur={(e: any) => input.readonly !== true ?updateData(input.name, e.target.value) : null} readOnly={input.readonly}/>
                </div>
            )
    }
}

export default FormField
