'use client'
import { useState, Fragment } from 'react' 
import Link from 'next/link'
import Delete from '@/assets/icons/trash.svg'
import _ from 'lodash'

interface CouponLineItemProps {
    coupon?: any | null
    row?: any | null
    setCouponData?: any | null
}

const CouponLineItem:React.FC<CouponLineItemProps>  = ({ coupon, row, setCouponData }) => {

    const [couponCodes, setCouponCodes] = useState(null) as any
    const [showCoupons, setShowCoupons] = useState(false) as any
    const [updating, setUpdating] = useState(false)
    const [removing, setRemoving] = useState(false)

    const getCouponCodes = async (id: any) => {
        setUpdating(true)
        const getCodes = await fetch(`/api/klaviyo/coupons/coupon-list?id=${id}`)
        const couponResults = await getCodes.json();
        setCouponCodes(couponResults?.result?.data)
        setUpdating(false)
    }

    const deleteCoupon = async (id: any) => {
        setRemoving(true)
        const removeCode = await fetch(`/api/klaviyo/coupons/coupon-remove?id=${id}`)
        const couponRemoved = await removeCode.json();
        const updateCoupons = await fetch(`/api/klaviyo/coupons`)
        const newCoupons = await updateCoupons.json();
        setCouponData(newCoupons)
        setRemoving(false)
    }

    return (
        <div className="grid grid-cols-12 gap-8 border-b-[1px] border-warmcharcoal py-3">
            {!removing ?
            <>
            <div className="col-span-12 md:col-span-1">
                {row+1}
            </div> 
            <div className="col-span-12 md:col-span-3 font-medium hover:underline hover:underline-offset-4 cursor-pointer" onClick={()=>{couponCodes?.length >= 1 ? null : getCouponCodes(coupon.id); setShowCoupons(!showCoupons)}}>
                {coupon.id}
            </div> 
            <div className="col-span-12 md:col-span-7">
                {coupon.attributes?.description}
            </div>
            <div className="col-span-12 md:col-span-1 flex justify-end" onClick={()=>{
                                                                                        if (window.confirm("Are you sure you want to delete this coupon from Klaviyo?")) {
                                                                                            deleteCoupon(coupon.id);
                                                                                        }}}>
                <Delete className="w-[32px] h-[32px] absolute cursor-pointer hover:rotate-6 duration-300 origin-center" />
            </div>
            {couponCodes?.length > 0 && showCoupons === true ? 
                couponCodes?.map((coupon: any, index: number)=>{
                    return (
                        <Fragment key={index}>
                            <div className="col-span-4"></div>
                            <div className="col-span-4">{coupon?.attributes.uniqueCode}</div>
                            <div className="col-span-4">{coupon?.profile?.email}</div>
                        </Fragment>
                    )
                })
            : <></>}
            </> : <div className="col-span-12 font-medium text-center ">Removing</div>}
            {updating ? <div className="col-span-12 font-medium text-center ">Getting active codes</div>: <></>}
        </div>
    )

}

export default CouponLineItem
