'use client'
import { useState, Fragment} from 'react'
import { useSession } from 'next-auth/react';
import { useLocalStorage } from 'react-use';
import { VoucherLineItems } from '@/components'
import _ from 'lodash';

interface VoucherSetProps {
    vouchers?: any | null
    lang?:any | null
}

const VoucherSet:React.FC<VoucherSetProps>  = ({vouchers, lang }) => {

    const [ voucher, setVoucher ] = useState([]) as any
    const [ voucherType, setVoucherType ] = useState(null) as any
    const [ voucherDetails, setVoucherDetails ] = useState([]) as any
    const { data: session, update } = useSession() as any;
    const [ selectedProductIds, setSelectedProductIds ] = useState<{ id: number, quantity: number }[]>([]) as any;
    const [ savedCart, setSavedCart ] = useLocalStorage('savedCart') as any;

    const updateCart = async (voucherData: any) => {
        // console.log("vd upadte: ", voucherData); // Log for debugging
        if (session?.user?.uuid && selectedProductIds.length > 0) {
            // console.log("Updating cart for user UUID:", session.user.uuid); // Log for debugging
            const setCart = await fetch('/api/cart', { 
                method: 'post', 
                body: JSON.stringify({
                    ids: vouchers[voucher].id,
                    uuid: session.user.uuid
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const cartResponse = await setCart.json();
            document.getElementById("cartPanel")?.classList.add("left-0","right-0","lg:left-auto");
        } else {
            const cartArray = savedCart?.length > 0 ? [...savedCart] : [] as any;

            cartArray.push({ id: voucherData.voucher, quantity: 1, details: voucherData.details });

            setSavedCart(cartArray);
            document.querySelector("#cartPanel")?.classList.add("left-0", "right-0", "lg:left-auto");
            document.querySelector("#cartDetail")?.classList.add("update-cart");
        }
    };

    return (
        <section className="font-norma px-4 md:px-8 lg:px-[50px] -mt-24 mb-24 block" style={{display: 'block !important'}}>
            <div className="grid grid-cols-12 gap-6">
                <div className="col-span-6 lg:col-span-3">
                    <button type="button" className={(voucherType === 'Digital Card' ? "text-white bg-warmcharcoal" : "text-warmcharcoal bg-white") + " h-[42px] text-[16px] w-full text-center duration-300"} onClick={()=>{setVoucherType('Digital Card');}}>{voucherDetails?.length == 0 ? "Add Digital eGift voucher" : "Digital eGift vouchers"}</button>
                </div>
                <div className="col-span-6 lg:col-span-3">
                    <button type="button" className={(voucherType === 'Physical Card' ? "text-white bg-warmcharcoal" : "text-warmcharcoal bg-white") + " h-[42px] text-[16px] w-full text-center duration-300"} onClick={()=>{setVoucherType('Physical Card');}}>{voucherDetails?.length == 0 ? "Add Instore gift voucher" : "Instore gift vouchers"}</button>
                </div>
            </div>
            {voucherType !== null ? 
            <div className="border-[1px] border-warmcharcoal col-span-6 p-5">
                <VoucherLineItems voucherType={voucherType} vouchers={vouchers} lang={lang} updateCart={updateCart}/>                    
            </div>
            : <></>}
        </section>
    )
}

export default VoucherSet
