'use client'
import { useState, useEffect } from 'react'
import Arrow from '@/assets/icons/right-arrow.svg'
import Close from '@/assets/icons/close.svg'
import { useLocalStorage } from 'react-use'
import Moment from 'moment'
import { FlowActionResponseObjectResourceAttributes } from 'klaviyo-api'

interface PromoCTAProps {
   rule?: any | null
   user?: any | null
   zLevel?: any | null
}

const PromoCTA:React.FC<PromoCTAProps>  = ({rule, user, zLevel}) => {
  
   const [showPopOver, setShowPopOver] = useState(false)
   const [emailValue, setEmailValue] = useState(false)
   const [local, setLocal] = useLocalStorage('nopromo') as any
   const [signedUp, setSignedUp] = useState(false);

   const hidePopover = ()=> {
      setLocal({time: Moment().add(30, 'days').format("YYYY-MM-DD")})
   }

   useEffect(()=>{
      if(!local?.time || (local?.time && Moment(local?.time) <  Moment())) {
         setTimeout(()=>{setShowPopOver(true)}, 1500);
      }
   },[])

   const signUp = async () => {
      const signup = await fetch(`api/klaviyo/signup?e=${emailValue}&p=${user?.ordered ? 'Website Already Purchased' : 'Website Not Purchased'}`)
      const signupResult = await signup.json();
      setSignedUp(true)
   }

   let showRules = true;

   switch(rule?.rules_json?.hide_offer) {
      case 'newsletter':
         !user || user?.customer?.marketing === 'false' ? showRules = true : showRules = false
      break;
      case 'signup':
         !user?.uuid ? showRules = true : showRules = false
      break;
      case 'customer':
         user?.uuid ? showRules = false : showRules = true
      break;
      case 'not_customer':
         !user?.uuid ? showRules = true : showRules = false
      break;
      case 'hide_pop_up':
         showRules = false
      break;
      default:
         showRules = true;
   }
   
   // showRules = true;

   return (
      <>
      {showPopOver === true && showRules === true && rule?.details_json?.image? 
         <div className="z-40 fixed top-0 left-0 w-screen h-screen bg-warmcharcoal/10  flex items-end lg:items-center justify-center p-4 md:p-6 py-12">
            <div style={{marginRight: `${zLevel*30}px`, marginBottom: `${zLevel*30}px`}} className={"max-w-[880px] md:w-screen md:h-[463px] p-4 md:p-6 relative shadow-lg shadow-warmcharcoal " + (rule?.details_json?.theme === true ? "bg-offwhite text-warmcharcoal" : "bg-warmcharcoal text-offwhite")}>
               <p onClick={()=>{setShowPopOver(!showPopOver); hidePopover()}} className="cursor-pointer hover:opacity-60 absolute right-4 top-4 z-[102]  text-[14px] font-norma ">Close <Close className={"inline-block ml-2 " + (rule?.details_json?.theme === true ? "stroke-warmcharcoal" : "stroke-offwhite")}/></p>
               <img src={rule?.details_json?.image} className="hidden md:inline-block object-cover mt-10 md:mt-0 w-[55%] h-full lg:w-auto"/>
               <div className="block md:inline-block md:absolute top-0 right-12 py-5 md:p-6 md:w-[280px] md:pt-[80px] h-full">
                  {rule?.details_json?.email === true ? 
                     signedUp !== true ?
                     <>
                        <h2 className="font-sangbleu font-medium text-[21px] leading-[32px] mb-8">{rule?.details_json?.name}</h2>
                        <p className="font-norma font-normal text-[16px] leading-[22px]" 
                           dangerouslySetInnerHTML={{ 
                              __html: rule?.details_json?.description.replace(/\r?\n|\r/g, "<br/><br/>") 
                           }} 
                        />
                        {rule?.details_json?.code && rule?.details_json?.code !=="" ? 
                        <p className="font-norma text-[24px] leading-[22px] mt-12">{rule?.details_json?.code}</p>
                        : <></>}
                        {rule?.details_json?.email === true ? 
                        <input type="text" className=" h-[40px] mt-8 border-none p-4 w-full font-norma text-[14px] focus:outline-none focus:ring-[1px] focus:ring-warmcharcoal focus:ring-opacity-80 text-warmcharcoal" placeholder="Email address" onChange={(e: any) => { setEmailValue(e.target.value) }}/>   
                        : <></>}         
                        <button type="button" onClick={()=>signUp()} className={"mt-8 md:mt-0 md:absolute bottom-7 left-6 border-[1px] h-[40px] px-4 text-[16px] font-norma font-medium w-48 text-left " + (rule?.details_json?.theme === true ? "border-warmcharcoal text-warmcharcoal " : "border-offwhite text-offwhite")}>{rule?.details_json?.button} <Arrow className={" inline-block rotate-180 ml-[60px] " + (rule?.details_json?.theme === true ? "stroke-warmcharcoal" : "stroke-offwhite")}/></button>
                     </> :
                     <>
                        <h2 className="font-sangbleu font-medium text-[21px] leading-[32px] mb-8">Thank you!</h2>
                        <p className="font-norma text-[16px] leading-[22px]">You&apos;re set to go we&apos;ve emailed you your code.</p>
                           
                        <button type="button" onClick={()=>{hidePopover(); setShowPopOver(false)}} className={"mt-8 md:mt-0 md:absolute bottom-7 left-6 border-[1px] h-[40px] px-4 text-[16px] font-norma font-medium text-left " + (rule?.details_json?.theme === true ? "border-warmcharcoal text-warmcharcoal" : "border-offwhite text-offwhite ")}>Close <Arrow className={" inline-block rotate-180 ml-[60px] " + (rule?.details_json?.theme === true ? "stroke-warmcharcoal" : "stroke-offwhite")}/></button>
                     </>
                     : <>
                     <h2 className="font-sangbleu font-medium text-[21px] leading-[32px] mb-8">{rule?.details_json?.name}</h2>
                     <p className="font-norma font-normal text-[16px] leading-[22px]" 
                           dangerouslySetInnerHTML={{ 
                              __html: rule?.details_json?.description.replace(/\r?\n|\r/g, "<br/>") 
                           }} 
                        />
                     <button type="button" onClick={()=>{hidePopover(); window.open(rule?.details_json?.link, "_self")}} className={"mt-8 md:mt-0 md:absolute bottom-7 left-6 border-[1px] h-[40px] px-4 text-[16px] font-norma font-medium text-left inline-block " + (rule?.details_json?.theme === true ? "border-warmcharcoal text-warmcharcoal " : "border-offwhite text-offwhite")}>{rule?.details_json?.button} <Arrow className={" inline-block rotate-180 ml-[60px] " + (rule?.details_json?.theme === true ? "stroke-warmcharcoal" : "stroke-offwhite")}/></button>
                  </>
                  }
               </div>
            </div>
         </div> 
      :  <></>}
      </>
   )

return <></>
}

export default PromoCTA
