'use client';

import { useState, useEffect } from 'react';
import { signIn, signOut, getCsrfToken, useSession } from "next-auth/react";
import { useRouter } from "next/navigation";
import { WelcomePanel } from '@/components';
import Arrow from '@/assets/icons/right-arrow.svg';
import Close from '@/assets/icons/close.svg';
import EyeOff from '@/assets/icons/eye-off-icon.svg'
import EyeOn from '@/assets/icons/eye-icon.svg'

interface LoginFormProps {
    uid?: any | null;
    theme?: any | null;
    lang?: any | null;
    authState?: any | null;
    token?: any | null;
    vtoken?: any | null;
    setAccount?: any | null;
    welcomeImage?: any | null;
    profile?: any | null;
}

const LoginForm: React.FC<LoginFormProps> = ({ authState, token, vtoken, setAccount, welcomeImage, profile, lang }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [terms, setTerms] = useState(false);
    const [verified, setVerified] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const [marketing, setMarketing] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [isLogin, setIsLogin] = useState(true);
    const [isRegister, setIsRegister] = useState(false);
    const [isReset, setIsReset] = useState(false);
    const [isChangePassword, setIsChangePassword] = useState(false);
    const [showExistingUser, setShowExistingUser] = useState(false);
    const [accountsMenu, setAccountsMenu] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false); // New state to handle button disabling
    const [showPassword, setShowPassword] = useState(false)

    const router = useRouter();
    const { data: session, status } = useSession();
    const user = session?.user as any;

    const logout = async () => {
        await signOut(); 
        window.open("/", "_self")
    }

    const handleLoginSubmit = async (e: any) => {
        e.preventDefault();
        setIsSubmitting(true); // Disable the button

        const csrfToken = await getCsrfToken();
        try {
            const res = await signIn('credentials', {
                redirect: false,
                email: email,
                password: password,
                csrfToken: csrfToken
            });

            if (res?.error) {
                setError(res.error);
            } else {
                setError('');
                setIsLogin(false);
                setAccountsMenu(true);
            }
        } catch (error: any) {
            // console.log(error);
            setError(error);
        } finally {
            setIsSubmitting(false); // Enable the button after the request completes
        }
    };

    const handleExistingUsers = async (emaildata: any) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24}$/;

        if (emailRegex.test(emaildata)) {
            const checkifUser = await fetch('/api/account/verify', { method: 'post', body: JSON.stringify({ email: emaildata }) });
            const checkifUserResult = await checkifUser.json();
            setFirstName(checkifUserResult?.name);
            setShowExistingUser(checkifUserResult.status);
        }
    };

    const handleForgotPasswordSubmit = async (e: any) => {
        e.preventDefault();
        setIsSubmitting(true); // Disable the button

        const res = await fetch('/api/account/send-password-reset', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, lang }),
        });

        if (res.ok) {
            setMessage('Password reset email sent, please check your inbox.');
            setError('');
            setShowExistingUser(false);
            
        } else {
            const data = await res.json();
            setError(data.error || 'An error occurred');
            setMessage('');
        }
        setIsSubmitting(false); // Enable the button after the request completes
        
    };

    const handleRegisterSubmit = async (e: any) => {
        e.preventDefault();
        setIsSubmitting(true); // Disable the button

        if (password !== confirmPassword) {
            setError('Passwords do not match');
            setIsSubmitting(false); // Enable the button in case of error
            return;
        }

        try {
            const registerUser = await fetch('/api/account/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password, firstName, lastName, verified: false, marketing: marketing, lang: lang }),
            });

            const registerResult = await registerUser.json();

            if (registerResult?.status ===200 ) {

                setMessage(registerResult.message);
                setIsLogin(false);
                setIsRegister(true);
                setIsReset(false);
                setIsChangePassword(false);
            } else {
                setError(registerResult?.error || 'An error occurred');
            }
        } catch (error) {
            // console.log(error);
            setError('An error occurred');
        }
        setIsSubmitting(false); // Enable the button after the request completes
    };

    const handleChangePassword = async (e: any) => {
        e.preventDefault();
        setIsSubmitting(true); // Disable the button

        if (password !== confirmPassword) {
            setError('Passwords do not match');
            setIsSubmitting(false); // Enable the button in case of error
            return;
        }

        const res = await fetch('/api/account/reset-password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token, password }),
        });

        if (res.ok) {
            router.push('/');
            setIsLogin(true);
            setIsRegister(false);
            setIsReset(false);
            setIsChangePassword(false);
        } else {
            const data = await res.json();
            setError(data.error || 'An error occurred');
        }
        setIsSubmitting(false); // Enable the button after the request completes
    };

    useEffect(() => {
        const checkToken = async (vtoken: any) => {
            const validate = await fetch(`/api/account/verify?vtoken=${vtoken}`);
            const validated = await validate.json();
            validated.status === 200 ? setVerified(true) : setVerified(false);
            setWaiting(false);
        };

        switch (authState) {
            case 'reset':
                setIsLogin(false);
                setIsRegister(false);
                setIsReset(false);
                setIsChangePassword(true);
            break;
            case 'login':
                if (vtoken !== '') {
                    setWaiting(true);
                    checkToken(vtoken);
                } else {
                    setIsLogin(true);
                    setIsRegister(false);
                    setIsReset(false);
                    setIsChangePassword(false);
                }
                break;
            case 'logged-out':
                setIsLogin(true);
                setIsRegister(false);
                setIsReset(false);
                setIsChangePassword(false);
                break;
            default:
        }
    }, [authState]);

    return (
        <div className="fixed left-0 md:left-auto w-screen md:w-[50vw] bg-offwhite right-0 bottom-0 top-0 shadow-lg z-[1001]">
            <Close onClick={() => { setAccount(false); document.body.style.overflow = "visible" }}
                className="absolute right-4 md:right-[54px] top-4 md:top-[26px] cursor-pointer hover:opacity-60 stroke-warmcharcoal" />
            <div className="py-24 md:py-0 md:flex items-center md:h-screen">
                <div className="overflow-y-scroll h-[80vh] scrollbar-hide">
                    <div className="px-10 md:px-[50px]">
                        {isLogin && !user?.uuid ?
                            <>
                                {waiting !== true && showExistingUser === false ?
                                    <>
                                        <form onSubmit={handleLoginSubmit}>
                                            <h2 className="font-sangbleu font-medium text-[21px] leading-[32px] mb-[6px] md:mb-[120px]">{verified === true ? 'Thanks for verifying – please login' : 'Log in to your account'}</h2>
                                            {error && <p className="text-warm-charcoal text-[14px] mt-4 font-medium font-norma">{error === "true" ? "Please check your password" : error}</p>}
                                            <input
                                                type="email"
                                                className="w-full text-[16px] md:text-[14px] font-norma p-2 my-4 focus:outline-none focus:ring-warmcharcoal focus:ring-[1px]"
                                                value={email}
                                                onChange={(e: any) => setEmail(e.target.value)}
                                                onBlur={(e: any) => handleExistingUsers(e.target.value)}
                                                placeholder="Email"
                                                required
                                            />
                                            <div className="relative ">
                                                <input
                                                    type={showPassword === true ? "text" : "password"}
                                                    className="w-full text-[16px] md:text-[14px] font-norma p-2 mb-4 focus:outline-none focus:ring-warmcharcoal focus:ring-[1px]"
                                                    value={password}
                                                    onChange={(e: any) => setPassword(e.target.value)}
                                                    placeholder="Password"
                                                    required
                                                />
                                                {showPassword === false ? <EyeOff className="fill-warmcharcoal absolute right-4 top-[10px]" onClick={()=>setShowPassword(!showPassword)}/> : <EyeOn className="fill-warmcharcoal absolute right-4 top-[12px]"  onClick={()=>setShowPassword(!showPassword)}/>}
                                            </div>
                                            <button type="submit" className="bg-warmcharcoal h-[42px] w-full text-offwhite font-norma font-medium text-[14px]" disabled={isSubmitting}>Login</button>
                                        </form>
                                        <button type="button" className="mt-8 font-norma text-[12px]" onClick={() => { setIsLogin(false); setIsRegister(false); setIsReset(true); setIsChangePassword(false) }}>Forgotton your password?, or are you an existing customer having login problems? <br/><span className="font-medium underline underline-offset-8 pointer-cursor">Please reset your password.</span></button>
                                        <h2 className="font-sangbleu font-medium text-[21px] leading-[32px] mt-[60px] md:mt-[100px] mb-[40px]">New to George Street Linen?</h2>
                                        <button type="button" onClick={() => { setIsLogin(false); setIsRegister(true); setIsReset(false); setIsChangePassword(false) }} className="border-[1px] border-warmcharcoal p-3 text-[14px] leading-[14px] font-norma inline-block font-medium mb-6">Create account<Arrow className="rotate-180 inline-block ml-[50px] stroke-warmcharcoal" /></button>
                                    </>
                                    :
                                    showExistingUser === false ? <h2 className="font-sangbleu font-medium text-[21px] leading-[32px] mb-[6px] md:mb-[120px]">Just verifying your account</h2> : <></>
                                }
                            </>
                            : <></>}
                    </div>
                    <div className="px-8 md:px-[50px] w-full">
                        {isReset ?
                            <form onSubmit={handleForgotPasswordSubmit}>
                                <h2 className="font-sangbleu font-medium text-[21px] leading-[32px] mb-[6px] md:mb-[120px]">Reset your password</h2>
                                <p className="font-norma pr-12 my-4 block mb-4">Reset password link sent.</p>
                                {error && <p className="red-500 text-[14px] mt-4 font-medium font-norma">{error}</p>}
                                {message === '' ?
                                    <>
                                        <input
                                            type="email"
                                            className="w-full text-[16px] md:text-[14px] font-norma p-2 my-4 focus:outline-none focus:ring-warmcharcoal focus:ring-[1px]"
                                            value={email}
                                            onChange={(e: any) => setEmail(e.target.value)}
                                            placeholder="Email"
                                            required
                                        />
                                        <button type="submit" className="bg-warmcharcoal h-[42px] w-full text-offwhite font-norma font-medium text-[14px]" disabled={isSubmitting}>Send Reset Link</button>
                                    </> : <></>}
                                <button type="button" className="mt-8 font-norma text-[12px]" onClick={() => { setIsLogin(true); setIsRegister(false); setIsReset(false); setIsChangePassword(false) }}>Back to Login &gt;</button>
                            </form>
                            : <></>}
                    </div>
                    <div className="px-8 md:px-[50px] w-full">
                        {isRegister ?
                            <form onSubmit={handleRegisterSubmit}>
                                <h2 className="font-sangbleu font-medium text-[21px] leading-[32px] mb:md:mb-[120px]">{message === '' ? 'Create an account' : message}</h2>
                                {error && <p className="red-500 text-[14px] mt-4 font-medium font-norma">{error}</p>}
                                {message === '' ?
                                    <>
                                        <input
                                            type="text"
                                            className="w-full text-[16px] md:text-[14px] font-norma p-2 my-4 focus:outline-none focus:ring-warmcharcoal focus:ring-[1px]"
                                            value={firstName}
                                            onChange={(e: any) => setFirstName(e.target.value)}
                                            placeholder="First Name"
                                            required
                                        />
                                        <input
                                            type="text"
                                            className="w-full text-[16px] md:text-[14px] font-norma p-2 mb-4 focus:outline-none focus:ring-warmcharcoal focus:ring-[1px]"
                                            value={lastName}
                                            onChange={(e: any) => setLastName(e.target.value)}
                                            placeholder="Last Name"
                                            required
                                        />
                                        <input
                                            className="w-full text-[16px] md:text-[14px] font-norma p-2 mb-4 focus:outline-none focus:ring-warmcharcoal focus:ring-[1px]"
                                            type="email"
                                            value={email}
                                            onChange={(e: any) => setEmail(e.target.value)}
                                            placeholder="Email"
                                            required
                                        />
                                        <div className="relative">
                                            <input
                                                type={showPassword === true ? "text" : "password"}
                                                className="w-full text-[16px] md:text-[14px] font-norma p-2 mb-4 focus:outline-none focus:ring-warmcharcoal focus:ring-[1px]"
                                                value={password}
                                                onChange={(e: any) => setPassword(e.target.value)}
                                                placeholder="Password"
                                                required
                                            />
                                        {showPassword === false ? <EyeOff className="fill-warmcharcoal absolute right-4 top-[10px]" onClick={()=>setShowPassword(!showPassword)}/> : <EyeOn className="fill-warmcharcoal absolute right-4 top-[12px]"  onClick={()=>setShowPassword(!showPassword)}/>}
                                        </div>
                                        <div className="relative">
                                            <input
                                                type={showPassword === true ? "text" : "password"}
                                                className="w-full text-[16px] md:text-[14px] font-norma p-2 mb-6 focus:outline-none focus:ring-warmcharcoal focus:ring-[1px]"
                                                value={confirmPassword}
                                                onChange={(e: any) => setConfirmPassword(e.target.value)}
                                                placeholder="Confirm Password"
                                                required
                                            />
                                            {showPassword === false ? <EyeOff className="fill-warmcharcoal absolute right-4 top-[10px]" onClick={()=>setShowPassword(!showPassword)}/> : <EyeOn className="fill-warmcharcoal absolute right-4 top-[12px]"  onClick={()=>setShowPassword(!showPassword)}/>}
                                        </div>
                                        <div className="relative text-[14px] text-warmcharcoal font-norma mb-4" onClick={() => setTerms(!terms)}>
                                            <span className={"rounded-full h-[30px] w-[30px] inline-block mr-3 p-2 bg-white"}>
                                                <span className={(terms === true ? "bg-warmcharcoal" : "bg-white") + " w-full h-full rounded-full block cursor-pointer"}></span>
                                            </span>
                                            <span className="absolute top-1 inline-block leading-[18px]">I agree to the terms and conditions</span>
                                        </div>
                                        <div className="relative text-[14px] text-warmcharcoal font-norma mb-12" onClick={() => setMarketing(!marketing)}>
                                            <span className={"rounded-full h-[30px] w-[30px] inline-block mr-3 p-2 bg-white"}>
                                                <span className={(marketing === true ? "bg-warmcharcoal" : "bg-white") + " w-full h-full rounded-full block cursor-pointer"}></span>
                                            </span>
                                            <span className="absolute top-1 inline-block leading-[18px]">Subscribe to receive communications from George Street Linen. </span>
                                        </div>
                                        <button type="submit" className="bg-warmcharcoal h-[42px] w-full text-offwhite font-norma font-medium text-[14px]" disabled={isSubmitting}>Create account</button>
                                    </> : <></>}
                                <button type="button" className="mt-8 font-norma text-[12px]" onClick={() => { setIsLogin(true); setIsRegister(false); setIsReset(false); setIsChangePassword(false) }}>Back to Login &gt;</button>
                            </form>
                            : <></>}
                    </div>
                    <div className="pl-[50px] w-full">
                        {user?.uuid ?
                            <WelcomePanel welcomeImage={welcomeImage} />
                            : <></>}
                    </div>
                    <div className="px-[50px] w-full">
                        {isChangePassword ?
                            <form onSubmit={handleChangePassword}>
                                <h2 className="font-sangbleu font-medium text-[21px] leading-[32px] mb-[120px]">Change your password</h2>
                                {error && <p className="red-500 text-[14px] mt-4 font-medium font-norma">{error}</p>}
                                <div className="relative  w-full">
                                    <input
                                    type={showPassword === true ? "text" : "password"}
                                    value={password}
                                    className="w-full text-[16px] md:text-[14px] font-norma p-2 mb-6 focus:outline-none focus:ring-warmcharcoal focus:ring-[1px]"
                                    onChange={(e: any) => setPassword(e.target.value)}
                                    placeholder="New Password"
                                    required
                                />
                                {showPassword === false ? <EyeOff className="fill-warmcharcoal absolute right-4 top-[10px]" onClick={()=>setShowPassword(!showPassword)}/> : <EyeOn className="fill-warmcharcoal absolute right-4 top-[12px]"  onClick={()=>setShowPassword(!showPassword)}/>}
                                </div>
                                <div className="relative w-full">
                                    <input
                                        type={showPassword === true ? "text" : "password"}
                                        value={confirmPassword}
                                        className="w-full text-[16px] md:text-[14px] font-norma p-2 mb-6 focus:outline-none focus:ring-warmcharcoal focus:ring-[1px]"
                                        onChange={(e: any) => setConfirmPassword(e.target.value)}
                                        placeholder="Confirm New Password"
                                        required
                                    />
                                    {showPassword === false ? <EyeOff className="fill-warmcharcoal absolute right-4 top-[10px]" onClick={()=>setShowPassword(!showPassword)}/> : <EyeOn className="fill-warmcharcoal absolute right-4 top-[12px]"  onClick={()=>setShowPassword(!showPassword)}/>}
                                </div>
                                <button type="submit" className="bg-warmcharcoal h-[42px] w-full text-offwhite font-norma font-medium text-[14px]" disabled={isSubmitting}>Change Password</button>
                                <button type="button" className="mt-8 font-norma text-[12px]" onClick={() => { setIsLogin(true); setIsRegister(false); setIsReset(false); setIsChangePassword(false) }}>Back to Login &gt;</button>
                            </form>
                            : <></>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginForm;
