'use client';
import { useState, useEffect, useRef } from 'react';
import { useWindowScroll } from 'react-use';
import NextLink from 'next/link'
import { PrismicNextImage } from '@prismicio/next'
import _ from 'lodash';
import Tick from '@/assets/icons/tick.svg';
import Close from '@/assets/icons/close.svg';
import Image from "next/image";
import ChevronDown from '@/assets/icons/chevron.svg'
import FilterIcon from '@/assets/icons/filters-icon.svg'

interface ProductGridTilesProps {
    products?: any | null
    filterData?: any | null
    rules?: any | null
    currency?: any | null
    priceColumn?: any | null
    lang?: any | null
    quickFind?: any | null
    collectionProjectsArray?: any | null
    swatches?: any | null
}

const ProductGridTiles:React.FC<ProductGridTilesProps>  = ({ products, filterData, rules, currency, priceColumn, lang, quickFind, collectionProjectsArray, swatches}) => {

    const [filters, setFilters] = useState([]) as any;
    const [filterSet, setFilterSet] = useState({}) as any;
    const [resultSet, setResultSet] = useState(products) as any;
    const [origResultSet] = useState(products) as any;
    const [filterOpen, setFilterOpen] = useState(-1);
    const [mobileFilterOpen, setMobileFilterOpen] = useState(false);
    const [priceFilter, setPriceFilter] = useState(-1);
    const filterRef = useRef(null) as any;
    const resultsRef = useRef(null) as any
    const [productChosen, setProductChosen] = useState(false);

    const [currentIndex, setCurrentIndex] = useState(0);
    const tileRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [tileWidth, setTileWidth] = useState(0);

    const [availableSizes, setAvailableSizes] = useState([]) as any
    const [availableColors, setAvailableColors] = useState([]) as any
    const [availableFabrics, setAvailableFabrics] = useState([]) as any

    const [sortField, setSortField] = useState("price"); // Tracks the sorting field: "price" or "name"



    const updateAvailableFilters = () => {
        const sizes = new Set() as any;
        const colors = new Set() as any;
        const fabrics = new Set() as any;
    
        const chooseSet = productChosen === true ? resultSet : origResultSet
        // Use the original result set (origResultSet) to gather all available filters
        chooseSet.forEach((productWrapper: any) => {
            const product = productWrapper.productdata;
            product.productOptions.forEach((option: any) => {
                if (option.option2) sizes.add(option.option2);
            });
            if (product.customFields?.products_1007) colors.add(product.customFields.products_1007);
            if (product.projectName) fabrics.add(convertString(product.projectName)); // Ensure fabric names are formatted correctly
        });
        setAvailableSizes([...sizes]);
        setAvailableColors([...colors]);
        setAvailableFabrics([...fabrics]);
    };

    function findBestRuleForProduct(product: any, rules: any) {
        let bestRule: any = null;
        let highestAmount = 0;

        rules.forEach((rule: any) => {
            rule.rules_json?.products.forEach((rulesProducts: any) => {
                if (rulesProducts.category?.id && product.categoryIdArray?.includes(rulesProducts.category.id)) {
                    if (rule.rules_json.rules.amount > highestAmount) {
                        highestAmount = rule.rules_json.rules.amount;
                        bestRule = rule;
                    }
                }
                if (rulesProducts.projectname && product.projectName === rulesProducts.projectname) {
                    // console.log('proj: ', rulesProducts.projectname);
                    if (rule.rules_json.rules.amount > highestAmount) {
                        highestAmount = rule.rules_json.rules.amount;
                        bestRule = rule;
                    }
                }
                if (rulesProducts.productdata && rulesProducts.productdata.id === product.id) {
                    // console.log('prods: ', rulesProducts.productdata.id);
                    if (rule.rules_json.rules.amount > highestAmount) {
                        highestAmount = rule.rules_json.rules.amount;
                        bestRule = rule;
                    }
                }
                if (rulesProducts === "all") {
                    // console.log('all: ', rulesProducts.category?.id);
                    if (rule.rules_json.rules.amount > highestAmount) {
                        highestAmount = rule.rules_json.rules.amount;
                        bestRule = rule;
                    }
                }
            });
        });
        return bestRule?.rules_json?.rules;
    };

    const convertString = (str: string) => {
        return str
          .replace(/-{2,}/g, ' + ') // Replace multiple dashes with ' + '
          .replace(/-/g, ' ')        // Replace single dashes with spaces
          .replace(/\b\w/g, (match: any) => match.toUpperCase()); // Capitalize first letter of each word
    }


    // const isItemChecked = (item: string, type: string) => {
    //     return filters[type]?.includes(item);
    // };

    const isItemChecked = (item: string, type: string) => {
        if (type === 'colours') {
            return filters['colours']?.includes(item);
        }
        if (type === 'projectName' || type === 'fabrics') {
            return filters['projectName']?.includes(item);
        }
        return filters[type]?.includes(item);
    };
    

    const getPriceBySize = (product: any) => {
        const selectedSize = filters.option2 && filters.option2.length > 0 ? filters.option2[0] : null;
        const option = product.productOptions.find((option: any) => option.option2 === selectedSize);
        return option ? option.priceColumns[priceColumn] : _.min(product.productOptions.map((opt: any) => opt.priceColumns[priceColumn]));
    };

    const filterProducts = (products: any, filters: any) => {
        return products.filter((productWrapper: any) => {
            const product = productWrapper.productdata;
    
            const matchesProductSubtype = !filters.productSubtype || filters.productSubtype.length === 0 ||
                filters.productSubtype.some((subtype: any) => subtype.toLowerCase() === product.productSubtype.toLowerCase());
    
            const matchesProjectName = !filters.projectName || filters.projectName.length === 0 ||
                filters.projectName.some((name: any) => name.toLowerCase() === convertString(product.projectName).toLowerCase());
    
            const matchesOption2 = !filters.option2 || filters.option2.length === 0 ||
                product.productOptions.some((option: any) => filters.option2.includes(option.option2));
    
            const matchesColours = !filters.colours || filters.colours.length === 0 ||
                filters.colours.includes(product.customFields?.products_1007);
    
            return matchesProductSubtype && matchesProjectName && matchesOption2 && matchesColours;
        });
    };

    const updateResultList = () => {
        const filteredResults = filterProducts(origResultSet, filters);
        setResultSet(filteredResults);
    };

    const clearAllFilters = () => {
        const filterEdit = { ...filterSet };
        Object.keys(filterEdit).forEach(key => {
            filterEdit[key] = [];
        });

        document.querySelectorAll('.bg-warmcharcoal').forEach(element => {
            element.classList.remove('bg-warmcharcoal');
            if (element.children[0]) {
                element.children[0].classList.add('hidden');
            }
        });

        setProductChosen(false);
        setFilterSet(filterEdit);
        updateResultList();
    };

    const addFilter = (e: any, item: string, type: string, source: any) => {
        const filterEdit = { ...filters };
        if (!filterEdit[type]) filterEdit[type] = [];
    
        // Special handling for fabrics and collections
        if (type === 'projectName' || type === 'fabrics') {
            if (collectionProjectsArray?.length > 0 && source=="quickfind") {
                // Iterate over collectionProjectsArray to check if the fabric belongs to a specific collection
                collectionProjectsArray.forEach((uidType: any) => {
                    if (uidType?.primary?.collection_section.uid.toLowerCase() === item.replace(/ /g, "-").replace(/\+/g, "-").toLowerCase()) {
                        uidType?.items.forEach((colUid: any) => {
                            const uid = colUid?.collection_page?.uid.replace(/---/g, " & ").replace(/-/g, " ");
    
                            if (filterEdit['projectName'].includes(uid)) {
                                // Remove if already in filters
                                filterEdit['projectName'] = filterEdit['projectName'].filter((i: string) => i !== uid);
                            } else {
                                // Add if not in filters
                                filterEdit['projectName'].push(uid);
                            }
                        });
                    }
                });
            } else {
                // Simple toggle for fabrics if no collectionProjectsArray is provided
                if (filterEdit['projectName'].includes(item)) {
                    filterEdit['projectName'] = filterEdit['projectName'].filter((fabric: string) => fabric !== item);
                } else {
                    filterEdit['projectName'].push(item);
                }
            }
        } else if (type === 'productSubtype' && item === 'Bed Sheets') {
            // Special handling for "bed sheets"
            const bedSheetTypes = ['Flat Sheets', 'Sheet Sets', 'Fitted Sheets'];
    
            bedSheetTypes.forEach(sheetType => {
                if (filterEdit[type].includes(sheetType)) {
                    filterEdit[type] = filterEdit[type].filter((i: string) => i !== sheetType);
                } else {
                    filterEdit[type].push(sheetType);
                }
            });
        } else if (type === 'colours') {
            // Toggle color filter
            if (!filterEdit['colours']) filterEdit['colours'] = [];
            if (filterEdit['colours'].includes(item)) {
                filterEdit['colours'] = filterEdit['colours'].filter((color: string) => color !== item);
            } else {
                filterEdit['colours'].push(item);
            }
        } else {
            // Standard filter behavior for other types
            if (filterEdit[type].includes(item.replace(/\+/g, "&"))) {
                filterEdit[type] = filterEdit[type].filter((i: string) => i !== item);
            } else {
                filterEdit[type].push(item);
            }
        }
    
        // Update filters state and result list
        setFilters(filterEdit);
        updateResultList();
    };
    

    const { x, y } = useWindowScroll();

    const removeItem = (item: any, type: string) => {
        const filterEdit = { ...filters };
        filterEdit[type] = filterEdit[type].filter((i: string) => i !== item);
    
        // Update the filters state with the modified filter set
        setFilters(filterEdit);
    };

    const sortBy = (direction: number) => {
        let sortedData;
        
        // Update the sorting field based on direction
        if (direction === 0 || direction === 1) {
            setSortField("price");
            sortedData = _.orderBy(
                resultSet,
                [(product: any) => product.productdata.productOptions[0]?.priceColumns[priceColumn]],
                [direction === 1 ? "desc" : "asc"]
            );
        } else if (direction === 2 || direction === 3) {
            setSortField("name");
            sortedData = _.orderBy(
                resultSet,
                [(product: any) => product.productdata.name],
                [direction === 3 ? "desc" : "asc"]
            );
        }
    
        // Set the sorting direction
        setPriceFilter(direction);
        setResultSet(sortedData);
    };

    const addQuickFind = (item: string, type: string) => {
        let searchType = '';
    
        // Map quickFind types to filter types
        switch (type) {
            case 'product':
                searchType = 'productSubtype';
                break;
            case 'collection_section':
                searchType = 'projectName';
                break;
            default:
                console.error("Invalid filter type:", type);
                return;
        }
    
        // Call addFilter, passing in the mapped type and item
        addFilter(null, convertString(item), searchType, "quickfind");
    
        setFilterOpen(-1);
        resultsRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    
    const moveLeft = () => {
    if (containerRef.current && tileWidth) {
        // Adjust the scroll position by exactly one tile + gap (scrolling one tile)
        const newScrollPos = containerRef.current.scrollLeft - tileWidth - 20; // Account for 20px gap
        // console.log("Scrolling Left: New Scroll Position:", newScrollPos); // Debug scroll position
        containerRef.current.scrollTo({ left: newScrollPos, behavior: 'smooth' });
        setCurrentIndex((prev) => Math.max(prev - 1, 0));
    }
    };

    const moveRight = () => {
    if (containerRef.current && tileWidth) {
        // Adjust the scroll position by exactly one tile + gap (scrolling one tile)
        const newScrollPos = containerRef.current.scrollLeft + tileWidth + 20; // Account for 20px gap
        // console.log("Scrolling Right: New Scroll Position:", newScrollPos); // Debug scroll position
        containerRef.current.scrollTo({ left: newScrollPos, behavior: 'smooth' });
        setCurrentIndex((prev) => Math.min(prev + 1, quickFind.pageData.results.length - 1));
    }
    };


    useEffect(() => {
        updateResultList(); // Update results whenever filters change
    }, [filters]);


    useEffect(() => {
        updateAvailableFilters()
        if (tileRef.current) {
            // Get the width of the tile
            const tileElement = tileRef.current.getBoundingClientRect();
            
            // Set the tile width without adding the gap here
            setTileWidth(tileElement.width); 
    
            // console.log("Tile Width:", tileElement.width); // Debug the width
        }
        const handleResize = () => {
            if (tileRef.current) {
            const tileElement = tileRef.current.getBoundingClientRect();
            setTileWidth(tileElement.width/2); // Recalculate tile width
            //   console.log("Resized Tile Width:", tileElement.width); // Debug resized width
            }
        };
    
        window.addEventListener('resize', handleResize);
        handleResize(); // Initial calculation
    
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (y >= 100) {
            filterRef?.current?.classList.add("fixed", "px-4","lg:px-[50px]", "shadow-lg");
        } else {
            filterRef?.current?.classList.remove("fixed", "px-4","lg:px-[50px]", "shadow-lg");
        }
    }, [y]);

    useEffect(() => {
        updateAvailableFilters(); // Update available filters when resultSet changes
    }, [resultSet]);

    useEffect(() => {
        updateResultList(); // Update results whenever filters change
    }, [filters]);

    const filterCount = _.some(filters, value => _.isArray(value) && value.length > 0);
    const totalArrayItems = _.reduce(filters, (sum, value) => {
        return _.isArray(value) ? sum + value.length : sum;
    }, 0);

    return (
        <>
        {origResultSet?.length > 9 ? 
            <>
            {quickFind ?
                <section
                    data-slice-type={quickFind.slice_type}
                    data-slice-variation={quickFind.variation}
                    className="px-4 md:px-0 py-8"
                    >
                    <div className="relative w-full" ref={containerRef}>
                        <div className="grid grid-cols-12 lg:flex items-between gap-4 lg:w-auto">
                            {quickFind?.pageData.results.map((page: any, index:number)=>{
                            return (
                                <div className="border-[1px] border-warmcharcoal lg:border-none lg:bg-lightwarmgrey col-span-6 md:col-span-4 lg:flex lg:pt-[10px] relative cursor-pointer hover:opacity-60 lg:w-full lg:m-0 h-[40px] lg:h-auto text-center" ref={index === 0 ? tileRef : null}  key={index} onClick={(e: any) => { setProductChosen(true); e.target.classList.toggle("border-warmcharcoal"); addQuickFind(page.uid, page.type)}}>
                                    <div className="p-3 py-2 lg:p-0 lg:m-0 lg:pb-3">
                                        <h4 className="capitalize font-norma text-warmcharcoal text-[12px] md:text-[14px] lg:text-[16px] lg:leading-[24px] lg:mb-3 font-medium lg:px-[10px] text-center lg:text-left">{page.uid?.replace(/---/g, " + ").replace(/-/g, " ")}</h4>
                                        <div className="block px-[10px]">
                                            <PrismicNextImage field={page.data.meta_image} fallbackAlt="" className="hidden lg:block aspect-square object-cover"/>
                                        </div>
                                    </div>
                                </div>
                            )
                            })}
                        </div>
                    </div>
                    {/* <div className="mt-6 w-[100px] mx-auto flex justify-between lg:hidden">
                        <button className="" onClick={moveLeft}><ArrowRight className="stroke-warmcharcoal"/></button>
                        <button className="" onClick={moveRight}><ArrowRight className="stroke-warmcharcoal rotate-180"/></button>
                    </div>       */}
                </section>
            : <></>} 
            <section className="border-t-[1px] border-t-warmcharcoal px-4 lg:px-[54px] w-full font-norma text-[14px] left-0 top-[70px] bg-offwhite z-[100] " ref={filterRef}>
                <div className="grid grid-cols-12 py-4 z-[100]">
                    <div className="col-span-6 lg:col-span-2 cursor-pointer md:cursor-default" onClick={()=>setMobileFilterOpen(!mobileFilterOpen)}>
                        {mobileFilterOpen === true ? 
                        <p className="" onClick={() => {setFilterOpen(-1)}} >Close <Close className="stroke-warmcharcoal inline-block relative -top-[1px] ml-2"/></p>
                        :
                        <p className="">Filter {filterCount ? "("+totalArrayItems+")" : ""} <FilterIcon className="stroke-warmcharcoal inline-block relative -top-[1px] ml-2"/></p>}
                    </div>
                    <div className="col-span-6 lg:col-span-2 flex justify-end lg:order-2">
                        <p className="font-medium text-right">{resultSet?.length} Products</p>
                    </div>
                    <div className={(mobileFilterOpen == true ? "flex": "hidden") + " lg:flex col-span-12 lg:col-span-8 lg:order-1 w-full mt-6 lg:mt-0"}>
                        <div className="grid grid-cols-12 gap-x-4 gap-y-3 md:gap-4 w-full">
                            {filterData?.type?.length > 0 ? 
                            <>
                            <div className="col-span-4 md:col-span-3 lg:col-span-2">
                                <p className="cursor-pointer flex justify-between" onClick={() => filterOpen === 1 ? setFilterOpen(-1) : setFilterOpen(1)}>Product <ChevronDown className={"stroke-warmcharcoal relative top-2 duration-300 mr-[15%] lg:mr-[20%] "+(filterOpen === 1 ? "" : "rotate-180")}/></p>
                                <div className={(filterOpen === 1 ? "block" : "hidden") + " bg-lightwarmgrey pt-4 px-4 md:px-[50px] absolute left-0 right-0 shadow-2xl z-[20] mt-4 pb-8 top-[130px] lg:top-auto max-h-[calc(100vh_-_200px)] overflow-y-scroll "}>
                                    <Close onClick={() => {setFilterOpen(-1)}} className="absolute z-[1002] right-4 lg:right-[50px] top-4 lg:top-6 stroke-warmcharcoal cursor-pointer hover:opacity-60"/>
                                    <p className="underline underline-offset-4 mb-8 cursor-pointer hover:opacity-60" onClick={() => clearAllFilters()}>Clear all</p>
                                    <ul className="filter-list">
                                        {filterData?.type?.map((type: any, index: number) => {
                                            return (
                                                <li key={index} className="text-warmcharcoal text-[12px] md:text-[14px] inline-block w-[50%] md:w-[33.33%] capitalize mb-1 relative">
                                                    <div className="absolute bg-[rgba(0_0_0_0.0001)] left-0 right-0 h-[19px] top-0 z-10 cursor-pointer" onClick={(e: any) => { setProductChosen(true); addFilter(e, type, "productSubtype", null)}}></div>
                                                    <span className={`border-[1px] border-warmcharcoal w-[19px] h-[19px] inline-block mr-2 p-[3px] pt-[4px] ${isItemChecked(type, "productSubtype") ? 'bg-warmcharcoal' : ''}`}>
                                                        <Tick className={`${isItemChecked(type, "projectName") ? '' : 'hidden'} relative `} />
                                                    </span>
                                                    <span className="relative -top-[5px]">{type}</span>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                    <button onClick={() => {setFilterOpen(-1)}} className="border-[1px] border-warmcharcoal h-[40px] text-center px-12 text-[14px] mt-4">Done</button>
                                </div>
                            </div>
                            {/* <div className="col-span-1"></div> */}
                            </>
                            : <></>}
                            {availableFabrics?.length > 0 && (
                                <div className="col-span-4 md:col-span-3 lg:col-span-2">
                                    <p className="cursor-pointer flex justify-between" onClick={() => filterOpen === 2 ? setFilterOpen(-1) : setFilterOpen(2)}>Fabric <ChevronDown className={"stroke-warmcharcoal relative top-2 duration-300 mr-[15%] lg:mr-[20%] "+(filterOpen === 2 ? "" : "rotate-180")}/></p>
                                    <div className={(filterOpen === 2 ? "block" : "hidden") + " bg-lightwarmgrey pt-4 px-4 md:px-[50px] absolute left-0 right-0 shadow-2xl z-[20] mt-4 pb-8 top-[130px] lg:top-auto max-h-[calc(100vh_-_200px)] overflow-y-scroll"}>
                                        <Close onClick={() => {setFilterOpen(-1)}} className="absolute z-[1002] right-4 lg:right-[50px] top-4 lg:top-6 stroke-warmcharcoal cursor-pointer hover:opacity-60"/>
                                        <p className="underline underline-offset-4 mb-8 cursor-pointer hover:opacity-60" onClick={() => clearAllFilters()}>Clear all</p>
                                        <ul className="filter-list">
                                            {availableFabrics.map((fabric: string, index: number) => (
                                                <li key={index} className="text-warmcharcoal text-[12px] md:text-[14px] inline-block w-[50%] md:w-[33.33%] capitalize mb-1 relative">
                                                    <div className="absolute bg-[rgba(0_0_0_0.0001)] left-0 right-0 h-[19px] top-0 z-10 cursor-pointer" onClick={(e: any) => { addFilter(e, fabric, "projectName", null) }}></div>
                                                    <span className={`border-[1px] border-warmcharcoal w-[19px] h-[19px] inline-block mr-2 p-[3px] pt-[4px] ${isItemChecked(fabric, "projectName") ? 'bg-warmcharcoal' : ''}`}>
                                                        <Tick className={`${isItemChecked(fabric, "projectName") ? '' : 'hidden'} relative`} />
                                                    </span>
                                                    <span className="relative -top-[5px]">{fabric}</span>
                                                </li>
                                            ))}
                                        </ul>
                                        <button onClick={() => {setFilterOpen(-1)}} className="border-[1px] border-warmcharcoal h-[40px] text-center px-12 text-[14px] mt-4">Done</button>
                                    </div>
                                </div>
                            )}
                            {availableSizes?.length > 0 ? 
                            <>
                            <div className="col-span-4 md:col-span-3 lg:col-span-2">
                                <p className="cursor-pointer flex justify-between" onClick={() => filterOpen === 3 ? setFilterOpen(-1) : setFilterOpen(3)}>Size <ChevronDown className={"stroke-warmcharcoal relative top-2 duration-300 mr-[15%] lg:mr-[20%] "+(filterOpen === 3 ? "" : "rotate-180")}/></p>
                                <div className={(filterOpen === 3 ? "block" : "hidden") + " bg-lightwarmgrey pt-4 px-4 md:px-[50px] absolute left-0 right-0 shadow-2xl z-[20] mt-4 pb-8 top-[130px] lg:top-auto max-h-[calc(100vh_-_200px)] overflow-y-scroll"}>
                                    <Close onClick={() => {setFilterOpen(-1)}} className="absolute z-[1002] right-4 lg:right-[50px] top-4 lg:top-6 stroke-warmcharcoal cursor-pointer hover:opacity-60"/>
                                    <p className="underline underline-offset-4 mb-8 cursor-pointer hover:opacity-60" onClick={() => clearAllFilters()}>Clear all</p>
                                    <ul className="filter-list">
                                        {availableSizes?.map((size: any, index: number) => {
                                            return (
                                                <li key={index} className="text-warmcharcoal text-[12px] md:text-[14px] inline-block w-[50%] md:w-[33.33%] capitalize mb-1 relative">
                                                    <div className="absolute bg-[rgba(0_0_0_0.0001)] left-0 right-0 h-[19px] top-0 z-10 cursor-pointer" onClick={(e: any) => { addFilter(e, size, "option2", null)}}></div>
                                                    <span className={`border-[1px] border-warmcharcoal w-[19px] h-[19px] inline-block mr-2 p-[3px] pt-[4px] ${isItemChecked(size, "option2") ? 'bg-warmcharcoal' : ''}`}>
                                                        <Tick className={`${isItemChecked(size, "option2") ? '' : 'hidden'} relative `} />
                                                    </span>
                                                    <span className="relative -top-[5px]">{size}</span>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                    <button onClick={() => {setFilterOpen(-1)}} className="border-[1px] border-warmcharcoal h-[40px] text-center px-12 text-[14px] mt-4">Done</button>
                                </div>
                            </div>
                            {/* <div className="col-span-1"></div> */}
                            </>
                            : <></>}
                            {availableColors?.length > 0 ? 
                            <>
                            <div className="col-span-4 md:col-span-3 lg:col-span-2">
                                <p className="cursor-pointer flex justify-between" onClick={() => filterOpen === 4 ? setFilterOpen(-1) : setFilterOpen(4)}>Colour <ChevronDown className={"stroke-warmcharcoal relative top-2 duration-300 mr-[15%] lg:mr-[20%] "+(filterOpen === 4 ? "" : "rotate-180")}/></p>
                                <div className={(filterOpen === 4 ? "block" : "hidden") + " bg-lightwarmgrey pt-4 px-4 md:px-[50px] absolute left-0 right-0 shadow-2xl z-[20] mt-4 pb-8 top-[130px] lg:top-auto max-h-[calc(100vh_-_200px)] overflow-y-scroll"}>
                                    <p className="underline underline-offset-4 mb-8 cursor-pointer hover:opacity-60" onClick={() => clearAllFilters()}>Clear all</p>
                                    <ul className="filter-list">
                                        {availableColors?.map((colour: any, index: number) => {
                                            const matchingSwatch = swatches.find((swatch: any) => swatch.colour_name.toLowerCase() === colour.toLowerCase().replace(/\&/g, "+"));
                                            return (
                                                <li
                                                key={index}
                                                className="text-warmcharcoal text-[12px] md:text-[14px] inline-block w-[50%] md:w-[33.33%] capitalize mb-1 relative"
                                            >
                                                <div
                                                    className="absolute bg-[rgba(0_0_0_0.0001)] left-0 right-0 h-[19px] top-0 z-10 cursor-pointer"
                                                    onClick={(e: any) => {
                                                        addFilter(e, colour, "colours", null);
                                                    }}
                                                ></div>
                                                <span
                                                    className={`dot border-[1px] border-warmcharcoal w-[19px] h-[19px] inline-block mr-2 p-[3px] pt-[4px] rounded-full ${isItemChecked(colour, "colours") ? '' : ''}`}
                                                    style={{
                                                        backgroundColor: matchingSwatch?.color || "transparent",
                                                        backgroundImage: matchingSwatch?.image?.url ? `url(${matchingSwatch.image.url})` : "none",
                                                        backgroundSize: matchingSwatch?.image?.url ? "cover" : "initial",
                                                    }}
                                                >
                                                    <Tick className={`${isItemChecked(colour, "colours") ? '' : 'hidden'} relative`} />
                                                    {/* <Tick className={`${isItemChecked(colour, "option1") ? '' : 'hidden'} relative `} /> */}
                                                </span>
                                                
                                                <span className="relative -top-[5px]">
                                                    {colour.replace(/\&/g, "+")}
                                                </span>
                                            </li>
                                            )
                                        })}
                                    </ul>
                                    <button onClick={() => {setFilterOpen(-1)}} className="border-[1px] border-warmcharcoal h-[40px] text-center px-12 text-[14px] mt-4">Done</button>
                                </div>
                            </div>
                            {/* <div className="col-span-1"></div> */}
                            </>
                            : <></>}
                            <div className="col-span-4 md:col-span-3 lg:col-span-2">
                                <p className="cursor-pointer flex justify-between" onClick={() => filterOpen === 5 ? setFilterOpen(-1) : setFilterOpen(5)}>Sort by <ChevronDown className={"stroke-warmcharcoal relative top-2 duration-300 mr-[15%] lg:mr-[20%] "+(filterOpen === 5 ? "" : "rotate-180")}/></p>
                                <div className={(filterOpen === 5 ? "block" : "hidden") + " bg-lightwarmgrey pt-4 px-4 md:px-[50px] absolute left-0 right-0 shadow-2xl z-[20] mt-4 pb-8 top-[130px] lg:top-auto max-h-[calc(100vh_-_190px)] overflow-y-scroll"}>
                                    <p className="underline underline-offset-4 mb-8 cursor-pointer hover:opacity-60" onClick={() => clearAllFilters()}>Clear all</p>
                                    <ul className="filter-list">
                                        <li className="text-warmcharcoal text-[12px] md:text-[14px] inline-block w-[50%] md:w-[33.33%] capitalize mb-1 relative">
                                            <div className="absolute bg-[rgba(0_0_0_0.0001)] left-0 right-0 h-[19px] top-0 z-10 cursor-pointer" onClick={() => { sortBy(0); }}></div>
                                            <span className={`border-[1px] border-warmcharcoal w-[19px] h-[19px] inline-block mr-2 p-[3px] pt-[4px] ${sortField === "price" && priceFilter === 0 ? 'bg-warmcharcoal' : ''}`}>
                                                <Tick className={`${sortField === "price" && priceFilter === 0 ? '' : 'hidden'} relative`}/>
                                            </span>
                                            <span className="relative -top-[5px]">Price Lowest</span>
                                        </li>
                                        <li className="text-warmcharcoal text-[12px] md:text-[14px] inline-block w-[50%] md:w-[33.33%] capitalize mb-1 relative">
                                            <div className="absolute bg-[rgba(0_0_0_0.0001)] left-0 right-0 h-[19px] top-0 z-10 cursor-pointer" onClick={() => { sortBy(1); }}></div>
                                            <span className={`border-[1px] border-warmcharcoal w-[19px] h-[19px] inline-block mr-2 p-[3px] pt-[4px] ${sortField === "price" && priceFilter === 1 ? 'bg-warmcharcoal' : ''}`}>
                                                <Tick className={`${sortField === "price" && priceFilter === 1 ? '' : 'hidden'} relative`}/>
                                            </span>
                                            <span className="relative -top-[5px]">Price Highest</span>
                                        </li>
                                        <li className="text-warmcharcoal text-[12px] md:text-[14px] inline-block w-[50%] md:w-[33.33%] capitalize mb-1 relative">
                                            <div className="absolute bg-[rgba(0_0_0_0.0001)] left-0 right-0 h-[19px] top-0 z-10 cursor-pointer" onClick={() => { sortBy(2); }}></div>
                                            <span className={`border-[1px] border-warmcharcoal w-[19px] h-[19px] inline-block mr-2 p-[3px] pt-[4px] ${sortField === "name" && priceFilter === 2 ? 'bg-warmcharcoal' : ''}`}>
                                                <Tick className={`${sortField === "name" && priceFilter === 2 ? '' : 'hidden'} relative`}/>
                                            </span>
                                            <span className="relative -top-[5px]">Name A-Z</span>
                                        </li>
                                        <li className="text-warmcharcoal text-[12px] md:text-[14px] inline-block w-[50%] md:w-[33.33%] capitalize mb-1 relative">
                                            <div className="absolute bg-[rgba(0_0_0_0.0001)] left-0 right-0 h-[19px] top-0 z-10 cursor-pointer" onClick={() => { sortBy(3); }}></div>
                                            <span className={`border-[1px] border-warmcharcoal w-[19px] h-[19px] inline-block mr-2 p-[3px] pt-[4px] ${sortField === "name" && priceFilter === 3 ? 'bg-warmcharcoal' : ''}`}>
                                                <Tick className={`${sortField === "name" && priceFilter === 3 ? '' : 'hidden'} relative`}/>
                                            </span>
                                            <span className="relative -top-[5px]">Name Z-A</span>
                                        </li>
                                    </ul>
                                    <button onClick={() => {setFilterOpen(-1)}} className="border-[1px] border-warmcharcoal h-[40px] text-center px-12 text-[14px] mt-4">Done</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full pb-2 ">
                    <ul className="w-full">
                        {Object.keys(filters).map((filterType) => (
                            filters[filterType].map((item: any, index: any) => (
                                <li
                                    key={`${filterType}-${index}`}
                                    className="list-none inline-block pr-6 hover:opacity-60 cursor-pointer capitalize"
                                    onClick={() => removeItem(item, filterType)}
                                >
                                    <Close className="stroke-warmcharcoal inline-block mr-2 ml-6 first:ml-0" />
                                    {item}
                                </li>
                            ))
                        ))}
                        {priceFilter !== -1 && (
                            <li
                                className="list-none inline-block pr-6 hover:opacity-60 cursor-pointer capitalize"
                                onClick={() => setPriceFilter(0)} // Reset price filter when clicked
                            >
                                <Close className="stroke-warmcharcoal inline-block mr-2 ml-6 first:ml-0" />
                                {priceFilter === 0 ? "Sort: Low-Hight" : priceFilter === 1 ? "Sort: High-Low" : priceFilter === 2 ? "Sort: A-Z" : "Sort: Z-A"}
                            </li>
                        )}
                    </ul>
                </div>
            </section>
            </>
        :<></>}
        <section>
            <div className="grid grid-cols-12 py-4 gap-5 lg:gap-8 font-norma scroll-mt-[270px] px-4" ref={resultsRef}>
                {resultSet.map((item: any, index: any) => {
                    const lowestRetail = getPriceBySize(item.productdata);
                    const applicablePromotion = findBestRuleForProduct(item.productdata, rules);

                    let discountPrice = lowestRetail;
                    let discountLabelType = "";

                    if (applicablePromotion) {
                        const discount = applicablePromotion.type === "percentage" ? applicablePromotion.amount / 100 : applicablePromotion.amount;
                        discountPrice = lowestRetail - lowestRetail * discount;
                        discountLabelType = applicablePromotion.type === "percentage" ? `${applicablePromotion.amount}% off` : `${currency} ${applicablePromotion.amount} off`;
                    }

                    if (item.productdata.images[0]?.link && discountPrice > 0) {
                        return (
                            <div className={(resultSet?.length > 4 ? "col-span-6" : "col-span-12") +" md:col-span-6 lg:col-span-4 relative"} key={index}>
                                {discountLabelType && (
                                    <div className="absolute top-0 right-0 bg-warmgrey text-white font-medium flex items-center justify-center text-[14px] h-[30px] px-4 z-[10]">
                                        {discountLabelType}
                                    </div>
                                )}
                                
                                <NextLink href={(`/${lang}/${item.productdata.productType}/${item.productdata.productSubtype}/${item.productdata.name}/${item.color}?s=`).replace(/ /g, "-").toLowerCase().replace(/\&/g, "")}>
                                    <div className="relative w-full aspect-square">
                                        <div className="opacity-0 md:hover:opacity-100 duration-300 cursor-pointer aspect-square bg-warmcharcoal/15 absolute top-0 left-0 right-0 bottom-0 z-10 flex items-center justify-center mix-blend-color-burn/80"><p className="text-white font-norma font-medium text-[16px]">See product</p></div>
                                        <Image
                                            src={item.productdata.images[0].link}
                                            className="w-full object-cover aspect-square"
                                            fill
                                            sizes="(max-width: 419px) 380px, (max-width: 640px) 300px, 600px"
                                            alt={`${item.productdata.name}. From $${lowestRetail.toFixed(2)}. Available at George Street Linen`}
                                        />
                                        {/* <div className="absolute bottom-0 left-0 bg-warmgrey text-white font-medium flex items-center justify-center text-[14px] h-[30px] px-4 z-[10]">
                                        {item.color}
                                        </div> */} 
                                    </div>
                                    <div className="grid grid-cols-12 pt-4 pb-6 h-[100px] mb-8 lg:my-0">
                                        <div className="col-span-12 md:col-span-6 lg:col-span-7">
                                            <p className="font-medium text-[16px] leading-[20px] mb-2 tracking-tight">{item.productdata.name} &ndash; {item.color}</p>
                                        </div>
                                        <div className="col-span-12 md:col-span-6  lg:col-span-5 flex md:justify-end">
                                            <p className="md:text-right">
                                                {discountPrice !== lowestRetail && (
                                                    <>
                                                        <span className="line-through text-warmred">{currency} {lowestRetail.toFixed(2)}</span><br />
                                                    </>
                                                )}
                                                From {currency} {discountPrice.toFixed(2)}
                                            </p>
                                        </div>
                                    </div>
                                    {/* <button className="w-full py-[13px] text-offwhite bg-warmcharcoal text-center">See product</button> */}
                                </NextLink>
                            </div>
                        );
                    }
                    return null;
                })}
            </div>
        </section>
        </>
    )
}

export default ProductGridTiles