import { createClient } from "@/prismicio";
import { PrismicNextLink, PrismicNextImage } from '@prismicio/next';
import { TopButton, LinkButton } from '@/components';
import NextLink from 'next/link'
import Moment from 'moment'
import GeorgeStreetLinenLogo from '@/assets/icons/george-street-linen-logo.svg'

interface FooterSectionProps {
    uid?: any | null
    hideTop?: boolean | null
    chrome?: any | null
}


async function getMenus(uid: any, chrome:any) {
    const client = createClient();
    const menus = await client.getSingle("menus");
    
    return (
        <section className={chrome===true ? "mt-24" : ""}>
            <TopButton hideTop={true}/>
            <div className="w-screen p-5 lg:p-[44px] bg-warmcharcoal text-offwhite font-norma font-medium">
                <div className="grid grid-cols-12 gap-y-8 gap-4 lg:gap-0 w-full">
                    <div className="col-span-12 lg:col-span-2">
                        <NextLink href="/" title="George Street Linen - Home"><GeorgeStreetLinenLogo className="fill-offwhite w-[45px] lg:w-[68px] mt-3 mb-4" alt="George Street Linen - Home"/></NextLink>
                    </div>
                    <div className="col-span-6 md:col-span-3 lg:col-span-2">
                        <ul>
                            {menus.data.footer_navigation?.map((item: any, index: number)=>{
                                if(index < 5) return (
                                    <li className="block text-[14px] py-1" key={index}><LinkButton item={item} /></li>
                                )
                            })}    
                        </ul>
                    </div>
                    <div className="col-span-6 md:col-span-3 lg:col-span-2">
                        <ul>
                            {menus.data.footer_navigation?.map((item: any, index: number)=>{
                                if(index > 4 && index < 9) return (
                                    <li className="block text-[14px]  py-1" key={index}><LinkButton item={item} /></li>
                                )
                            })}    
                        </ul>
                    </div>
                    <div className="col-span-6 md:col-span-3 lg:col-span-2">
                        <ul>
                            {menus.data.footer_navigation?.map((item: any, index: number)=>{
                                if(index > 8 && index < 13) return (
                                    <li className="block text-[14px]  py-1" key={index}><LinkButton item={item} /></li>
                                )
                            })}    
                        </ul>
                    </div>
                    <div className="col-span-6 md:col-span-3 lg:col-span-2">
                        <ul>
                            {menus.data.footer_navigation?.map((item: any, index: number)=>{
                                if(index > 12) return (
                                    <li className="block text-[14px]  py-1" key={index}><LinkButton item={item} /></li>
                                )
                            })}    
                        </ul>
                    </div>
                    <div className="col-span-12 lg:col-span-2 flex lg:justify-end">
                        <ul>
                            {menus.data.socials?.map((item: any, index: number)=>{
                                return (
                                    <li className="inline-block lg:block text-[14px] py-1 mr-5" key={index}><LinkButton item={item} /></li>
                                )
                            })}    
                        </ul>
                    </div>
                </div>
                <div className="text-offwhite text-[12px] mt-12 items-start font-normal">{menus.data.copyright_text?.replace("[YYYY]", Moment().format("YYYY"))}</div>
            </div>
        </section>
    )
}

const FooterSection:React.FC<FooterSectionProps>  = ({ uid, chrome }) => {
    return getMenus(uid, chrome)
}

export default FooterSection