'use client'
import React, { useState, useEffect } from 'react' 
import NextImage from 'next/image'
import { useRouter } from 'next/router';
import _ from 'lodash'
import Loading from '@/assets/icons/loading.svg'


interface ProductPanelAdminProps {
    lang?: any | null
}



const ProductPanelAdmin:React.FC<ProductPanelAdminProps>  = ({ lang}) => {

        const [ results, setResults ] = useState() as any 
        const [ showProduct, setShowProduct ] = useState() as any
        const [ loading, setLoading ] = useState('Update products') as any

        useEffect(() => {
            if (window.location) {
                setShowProduct(window.location.search.replace("?pid=","").replace(/%2F/g, "/"))
            }
        }, []);


        const searchProducts = async (name: string) => {
            if(name.length >=3) {
                const searchProducts = await fetch(`/api/promotions/products?prod=${name}`);
                const productsResult = await searchProducts.json();

                // console.log(productsResult);

                setResults(productsResult)
            }
        }
        
        const updateAllProducts = async () => {
            setLoading('Updating products')
            const updateProducts = await fetch(`/api/product-runner`);
            const updateProductsResult = await updateProducts.json();
            setLoading(updateProductsResult?.status === 200 ? 'Products Updated' : 'Error')
            setTimeout(()=>setLoading('Update products'), 350)
        }

        return (
            <div className="grid grid-cols-12 gap-5">
                <div className="col-span-3 pl-4 w-full">
                    <button type="button" className="inline-block mb-4 mt-8 h-[40px] border-none p-2  bg-warmcharcoal text-offwhite font-norma text-[14px] w-full" onClick={() => updateAllProducts() }>
                        {loading} <Loading className={"fill-offwhite " + (loading === "Update products" || loading === "Products updated" ? "hidden" : "inline-block")} />
                    </button>
                    <div className="w-full">
                        <input type="text" className="h-[40px] border-none p-4 w-full font-norma text-[14px] focus:outline-none focus:ring-[1px] focus:ring-warmcharcoal focus:ring-opacity-80" placeholder="Enter product name or id" onChange={(e) => {searchProducts(e.target.value); }}/>
                        <div className="h-[70vh] overflow-y-scroll">
                            {results?.length > 0 && results.map((item:any, index: number) => { 
                                const iframeSrc =(`/${lang}/${item.productdata.productType}/${item.productdata.productSubtype}/${item.productdata.name.trim().toLowerCase().replace(/ /g, "-")}/${item.productdata?.productOptions[0]?.option1}`).trim().toLowerCase().replace(/ /g, "-")
                                return (
                                    <div className="grid grid-cols-12 bg-white my-2 cursor-pointer opacity-80 hover:opacity-100 p-2" onClick={() => { setShowProduct(iframeSrc); }} key={index}>
                                        <div className="col-span-2">
                                            <img src={item.productdata.images[0]?.link} className="rounded-md aspect-square w-full object-cover font-norma text-[16px] text-warmcharcoal"/>
                                        </div>
                                        <div className="col-span-10 flex items-center pl-4">
                                            <p className="font-norma text-[14px] text-warmcharcoal">{item.productdata.name}{item.productdata.productOptions[0].option1 ? " – " + item.productdata.productOptions[0].option1 : ""}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="col-span-9">
                    <iframe className="w-full h-[110vh] origin-top-left scrollbar-hide overflow-x-hidden" src={showProduct}/>
                </div>
            </div>
        )

}

export default ProductPanelAdmin