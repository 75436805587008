
'use client'
import { PrismicNextLink } from "@prismicio/next"
import { linkResolver } from '@/prismicio'

interface LinkButtonProps {
    item?: any | null
}

const LinkButton:React.FC<LinkButtonProps>  = ({ item }) => {

    switch(item.link.url) {
        case "?collections":
            <span onClick={()=>console.log('collections')}>{item.link_text}</span>
        break;
        default: 
        return (
            <PrismicNextLink linkResolver={linkResolver} field={item.link}>{item.link_text}</PrismicNextLink>
        )
    }
}

export default LinkButton