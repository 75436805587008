"use client"
import { useState } from 'react'
import Moment from 'moment'

interface OrderPanelProps {
    item?: any | null
}

const OrderPanel:React.FC<OrderPanelProps> = ({item}) => {

    const [showOrder, setShowOrder] = useState(false)

    return (
            <div className="w-full border-b-[2px] border-b-warmcharcoal">
                <div className="md:grid grid-cols-12 gap-4 pt-8 font-norma text-[16px] leading-[22px] py-4 pb-8 cursor-pointer hover:opacity-60" onClick={()=>setShowOrder(!showOrder)}>
                    <div className="col-span-2">
                        {Moment(item.modifiedDate, "YYYY-MM-DDTHH:mm:ssZ").format("D MMM YYYY - HH:mm")}
                        <button type="button" className="block pt-3 text-[12px]">{showOrder === true ? "Hide Order" : "View Order"} &gt;</button>
                    </div>
                    <div className="mt-4 md:mt-0 mb-4 md:mb-0 col-span-2 font-medium">{item.reference}</div>
                    <div className="hidden md:flex col-span-2">{item.stage}</div>
                    <div className="hidden md:flex col-span-2">{item.dispatchedDate ? Moment(item.dispatchedDate, "YYYY-MM-DDTHH:mm:ssZ").format("D MMM YYYY - HH:mm") : "Pending"}</div>
                    <div className="hidden md:flex col-span-1">{item.trackingCode ? <a href={"https://parcelsapp.com/en/tracking/"+item.trackingCode}>{item.trackingCode}</a> : 'Unavailable'}</div>
                    <div className="md:hidden col-span-2 py-2">Order Status: {item.stage}</div>
                    <div className="md:hidden col-span-2 py-2">Dispatch: {item.dispatchedDate ? Moment(item.dispatchedDate, "YYYY-MM-DDTHH:mm:ssZ").format("D MMM YYYY - HH:mm") : "Pending"}</div>
                    <div className="md:hidden col-span-1 py-2">{item.trackingCode ? <a href={"https://parcelsapp.com/en/tracking/"+item.trackingCode}>Tracking: {item.trackingCode}</a> : 'Tracking Unavailable'}</div>
                    <div className="col-span-2 flex justify-end">${Number(item.total)?.toFixed(2)}</div>
                </div>
                {showOrder === true ? 
                <>
                <div className="w-full py-4 mb-12">
                   <h3 className="text-[16px] leading-[22px] font-medium mb-2">Order items</h3>
                        {item.lineItems?.map((line: any, lindex: number)=>{
                            // console.log(line)
                            return (
                                <div className="md:grid grid-cols-12 w-full font-norma text-[14px] gap-4 mb-4" key={lindex}> 
                                    <div className="col-span-2">{lindex+1} - Prod: #{line.code}</div>
                                    <div className="col-span-5">{line.name} <br/> Colour: {line.option1} <br/>Size: {line.option2}</div>
                                    <div className="col-span-2">{line.lineComments}</div>
                                    <div className="col-span-">x {line.qty}</div>
                                    <div className="col-span-1 flex justify-end text-[16px]">${Number(line.unitPrice - line.discount)?.toFixed(2)}</div>
                                </div>
                            )
                        })}
                </div>
                <div className="md:grid grid-cols-12 font-norma font-medium text-[16px] py-4 border-t-[1px] border-t-warmcharcoal mt-4">
                            <div className="col-span-9 flex justify-end">Total (includes taxes)</div>
                            <div className="col-span-2 flex justify-end">${item.total?.toFixed(2)}</div>
                        </div>
                </>
                :<></>}
            </div>
    )

}
export default OrderPanel



