'use client';
import Slider from 'react-slick'
import { PrismicRichText } from '@prismicio/react'
import { PrismicNextImage, PrismicNextLink } from "@prismicio/next";
import { ReactPlayerModule } from '@/components'
import ArrowRight from '@/assets/icons/right-arrow.svg'
import ArrowDown from '@/assets/icons/arrow-down.svg'

interface SliderPanelCenteredProps {
    items?: any | null
    height?: any | null
}

const SliderPanelCentered: React.FC<SliderPanelCenteredProps> = ({ items, height }) => {

    const settings = {
        infinite: true,           
        speed: 250,                
        slidesToShow: 1,          
        slidesToScroll: 1,
        autoplay: true,           
        autoplaySpeed: 3000,    
        cssEase: "linear",   
        pauseOnHover: false,
        swipeToSlide: true,
        arrows: false,
        dots: false,
        fade: true
        } as any

    return (
        <Slider {...settings}>
            {items?.map((item: any, index: number)=> {
                // console.log(items)
                return (
                    <div className={"flex items-center justify-center md:h-[600px] "+height} key={index}>
                    {!item.video.url ? 
                        <>
                        <picture>
                            <source
                                media="(max-width: 639px)"
                                srcSet={item.image.mobile?.url?.split('?').shift()+'?auto=format&fit=max&w=640'}
                            />
                            <source
                                media="(min-width: 640px) and (max-width: 1023px)"
                                srcSet={item.image.tablet?.url?.split('?').shift()+'?auto=format&fit=max&w=1023'}
                            />
                            <PrismicNextImage
                                field={item.image}
                                fallbackAlt=""
                                priority
                                sizes="(max-width: 639px) 100vw, (max-width: 1023px) 100vw, (max-width: 1920px) 100vw"
                                fill
                                className={"object-cover md:h-[600px] "+height}
                            />
                        </picture>                    
                        </>
                    : 
                    <div className="absolute top-0 bottom-0 left-0 right-0 object-cover opacity-60 overflow-hidden ">
                        <div className="w-[196vh] lg:w-full flex items-center justify-center -ml-[60vh] lg:ml-0">
                        <ReactPlayerModule playerData={item} background="home"/>
                        </div>
                    </div>
                    }
                    <div className="absolute w-full h-full text-center top-[30%] md:top-[40%]">
                        <article className="text-offwhite text-[32px] leading-[37px] md:text-[48px] md:leading-[64px] font-sangbleu font-medium z-10 prose-em:line-through prose-em:not-italic"><PrismicRichText field={item.heading}/></article>
                    </div>
                    <div className="absolute left-5 lg:left-[54px] bottom-5 lg:bottom-[54px] lg:max-w-[400px]">
                        <article className="mb-5 lg:mb-12 text-offwhite text-[16px] font-sangbleu z-10"><PrismicRichText field={item.exclusion_text}/></article>
                        <p className="border-[1px] border-offwhite py-2 h-[40px] inline-block text-offwhite text-[14px] px-4 font-norma font-medium "><PrismicNextLink field={item.button_link} className="flex items-center justify-between w-full"><span>{item.button_link_text}</span> <ArrowRight className="stroke-offwhite inline-block rotate-180 relative -top-[1px] ml-4"/></PrismicNextLink></p>
                    </div>
                        <div className="absolute bottom-4 md:bottom-12 flex justify-center w-full"><ArrowDown/></div>
                    </div>
                )
            })}
        </Slider>
    )
}

export default SliderPanelCentered