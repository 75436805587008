
'use client'

import { useState } from 'react'
import { AddressTile } from '@/components'
import _ from 'lodash'

interface ChangeDeliveryAddressProps {
    addresses?: any | null
    setActiveAddress?: any | null
    activeAddress?: any | null
    uuid?: any | null
}

const ChangeDeliveryAddress:React.FC<ChangeDeliveryAddressProps>  = ({ addresses, activeAddress, setActiveAddress, uuid }) => {
    const [showDeliverSet, setShowDeliverSet] = useState(false)

    const [hideTile, setHideTile] = useState(-1)

    const defaultToggle = async (event: any, index: any) => {
        event.stopPropagation();
        // const defaultAddress = await fetch(`/api/account/addresses?cur=${index}&uuid=${uuid}`);
        // const defaultAddressResult = await defaultAddress.json();
        setActiveAddress(index)
    }

    if(addresses) {

        hideTile > -1 ? _.pullAt(addresses, hideTile) : null

        return (
            <div className="bg-offwhite ">
                <p className="cursor-pointer hover:text-warmgrey" onClick={()=>setShowDeliverSet(!showDeliverSet)}>{showDeliverSet === true ? "Close" : "Show saved addresses"}</p>
                {showDeliverSet ? 
                    <div className="bg-offwhite absolute w-[calc(100vw_-_16px)] md:w-[calc(50vw_-_130px)] h-[360px] py-12">
                        <div className="w-full overflow-y-scroll">
                            <ul className="text-left pb-5" style={{width: addresses?.length * 265}}>
                                {addresses?.map((address: any, index: number)=>{
                                        return (
                                            <li className={(activeAddress === index ?"border-warmcharcoal" : "border-warmcharcoal/10") + " mr-3 inline-block relative border-[1px] w-[250px] text-left p-4 font-norma text-[14px] hover:shadow-md hover:border-warmcharcoal cursor-pointer shadow-sm transition-all duration-300 h-[220px] align-top"} onClick={()=>{setShowDeliverSet(false); setActiveAddress(index)}} key={index}>
                                                <AddressTile address={address} activeAddress={activeAddress} setActiveAddress={setActiveAddress} index={index} uuid={uuid} setHideTile={setHideTile} defaultToggle={defaultToggle}/>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div> 
                : <></>}
            </div>
        )
    } return <></>
} 

export default ChangeDeliveryAddress
