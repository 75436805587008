'use client'
import { useState } from 'react' 
import Moment from 'moment'
import Delete from '@/assets/icons/trash.svg'
import _ from 'lodash'

interface VoucherLineItemProps {
    voucher?: any | null
    row?: any | null
    setVoucherData?: any | null
}

const VoucherLineItem:React.FC<VoucherLineItemProps>  = ({ voucher, row, setVoucherData }) => {

    const [voucherCodes, setVoucherCodes] = useState(null) as any
    const [showvouchers, setShowvouchers] = useState(false) as any
    const [updating, setUpdating] = useState(false)
    const [removing, setRemoving] = useState(false)

    // const getvoucherCodes = async (id: any) => {
    //     setUpdating(true)
    //     const getCodes = await fetch(`/api/klaviyo/vouchers/voucher-list?id=${id}`)
    //     const voucherResults = await getCodes.json();
    //     setVoucherCodes(voucherResults?.result?.data)
    //     setUpdating(false)
    // }

    // const deletevoucher = async (id: any) => {
    //     setRemoving(true)
    //     const removeCode = await fetch(`/api/klaviyo/vouchers/voucher-remove?id=${id}`)
    //     const voucherRemoved = await removeCode.json();
    //     const updatevouchers = await fetch(`/api/klaviyo/vouchers`)
    //     const newvouchers = await updatevouchers.json();
    //     setVoucherData(newvouchers)
    //     setRemoving(false)
    // }

    return (
        <>
            <div className="grid grid-cols-12 gap-8 py-5">
                {!removing ?
                <>
                <div className="col-span-12 md:col-span-1">
                    {row+1}
                </div> 
                <div className="col-span-12 md:col-span-3 font-medium hover:underline hover:underline-offset-4 cursor-pointer" onClick={()=>{voucherCodes?.length >= 1 ? null : setShowvouchers(!showvouchers)}}>
                    {voucher.coupon_code}
                </div> 
                <div className="col-span-12 md:col-span-5">Sent to: {voucher.coupon_type.replace("Voucher - ", "")}</div>
                <div className="col-span-12 md:col-span-3 text-[14px] text-right">Balance: ${Number(voucher?.value).toFixed(2)}</div>
                {/* <div className="col-span-12 md:col-span-1 flex justify-end" onClick={()=>{
                                                                                            if (window.confirm("Are you sure you want to delete this voucher?")) {
                                                                                                deletevoucher(voucher.id);
                                                                                            }}}> */}
                    {/* <Delete className="w-[32px] h-[32px] absolute cursor-pointer hover:rotate-6 duration-300 origin-center" /> */}
                {/* </div> */}
                </> : <></>}
            </div>
            <div className="grid grid-cols-12 gap-8 border-b-[1px] border-b-warmcharcoal text-[14px] leading-[18px]">
                
                <div className="col-span-12">
                    {voucher.history?.length > 0 && showvouchers === true ? 
                    <div className="grid grid-cols-12 w-full my-6">
                        <div className="col-span-1"></div>
                        <div className="col-span-11 font-medium">Transactions</div>
                    </div>
                    : <></>}
                {voucher.history?.length > 0 && showvouchers === true ? 
                    voucher.history?.map((voucher: any, index: number)=>{
                        return (
                            <div className="grid grid-cols-12 w-full my-6" key={index}>
                                <div className="col-span-1"></div>
                                <div className="col-span-3">{Moment(voucher?.date).format("DD/MM/YYYY - h:mm a")}</div>
                                <div className="col-span-6">{voucher.orderNumber ? <span>{voucher.orderNumber} - ${voucher.orderTotal.toFixed(2)} </span>: "Voucher created"}</div>
                                <div className="col-span-2 flex justify-end">{voucher?.deducted ? <span>- ${voucher?.deducted.toFixed(2)}</span>: <span>+ ${Number(voucher?.details.code?.replace("GIFTD", "")).toFixed(2)}</span>}</div>
                            </div>
                        )
                    })
                : <></>}
                {voucher.history?.length > 0 && showvouchers === true ? 
                    <div className="grid grid-cols-12 w-full my-6">
                        <div className="col-span-9"></div>
                        <div className="col-span-2 font-medium">Balance</div>
                        <div className="col-span-1 flex justify-end font-medium">${Number(voucher?.value).toFixed(2)}</div>
                    </div>
                    : <></>}
                </div>
            </div>
        </>
    )

}

export default VoucherLineItem
