
'use client'
interface TopButtonProps {
    hideTop?: boolean | null
}

const TopButton:React.FC<TopButtonProps>  = ({ hideTop }) => {
    return (
        <div className={(hideTop === true ? "hidden" : "") + " font-norma text-[14px] text-right font-medium text-warmcharcoal px-4 lg:px-[50px] mt-24 mb-6"} 
                onClick={()=>window.scrollTo({
                                            top: 0,
                                            behavior: 'smooth'
                                        })}>
        Top
        </div>
    )
}

export default TopButton