'use client';

import { useEffect, useState } from'react';

interface PurchaseEventProps {
    order?: any | null;
    valid?: any | null
}

const PurchaseEvent:React.FC<PurchaseEventProps> = ({ order, valid }) => {
    
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (typeof window !== "undefined") {
            if (!(window as any).dataLayer) {
                (window as any).dataLayer = [];
                setLoaded(true);
            } else {
                setLoaded(true);  // Ensure `loaded` is true if `dataLayer` already exists
            }
        }
    }, []);  // Empty dependency array ensures this runs only on mount
    
    useEffect(() => {
        if (order && loaded && valid === "Awaiting Payment") {
            confirmPurchase(order);
        }
    }, [order, loaded]);  // Only runs when `order` or `loaded` changes
    
    const confirmPurchase = (order: any) => {
        const purchaseLineItems = order?.lineItems?.map((item: any, index: number) => ({
            item_id: item.code,
            item_name: item.name,
            affiliation: "Google Merchandise Store",
            coupon: item.lineComments,
            discount: item.discount,
            index: index,
            item_brand: "George Street Linen",
            item_category: "Home & Garden > Linens & Bedding",
            item_variant: `${item.option1} - ${item.option2}`,
            price: Number((item.unitPrice - item.discount).toFixed(2)),
            quantity: item.qty
        })) || [];

        try {
            (window as any).dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            (window as any).dataLayer.push({
                event: "purchase",
                ecommerce: {
                    transaction_id: order.reference,
                    currency: order.currencyCode,
                    value: Number(order.total.toFixed(2)),
                    tax: Number((order.total * order.taxRate).toFixed(2)),
                    coupon: order.voucherCode,
                    items: purchaseLineItems,
                    shipping: Number(order.freightTotal.toFixed(2))
                }
            });
        } catch (error: any) {
            console.log(error);
        }
    };

    return null;
};

export default PurchaseEvent;
