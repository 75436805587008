'use client'
import { useState, useEffect } from 'react'
import LinkArrow from '@/assets/icons/link-arrow.svg'

import CloseIcon from '@/assets/icons/close.svg'
import {
    EmailShareButton,
    FacebookShareButton,
    PinterestShareButton,
    TwitterShareButton
} from "react-share";

interface SharePanelProps {
    page?: any | null
    image?: any | null
}

const SharePanel:React.FC<SharePanelProps>  = ({ page, image }) => {

    const [showShare, setShowShare] = useState(false)
    const [pageUrl, setPageUrl] = useState("")

    useEffect(()=>{
        setPageUrl(String(window.location))
    },[])

    return (
        <>
        <div className="absolute right-5 lg:right-[50px] top-[2px] text-[14px] font-norma inline cursor-pointer" onClick={()=>setShowShare(true)}>Share <LinkArrow className="inline-block ml-2 relative -top-[2px]"/></div>
        {showShare === true ? 
            <div className="absolute right-5 lg:right-[30px] text-[14px] font-norma inline bg-offwhite px-5 z-[110] top-0">
                <CloseIcon className={"stroke-warmcharcoal absolute z-50 right-5 cursor-pointer hover:opacity-60 "} onClick={()=>setShowShare(false)}/>
                <div className="pt-7">
                    <FacebookShareButton url={pageUrl} className="inline-block ml-5">
                        Facebook
                    </FacebookShareButton>
                    <TwitterShareButton url={pageUrl} className="inline-block ml-5">
                        X
                    </TwitterShareButton>
                    <PinterestShareButton url={pageUrl}  media={image} className="inline-block ml-5">
                        Pinterest
                    </PinterestShareButton>
                    <EmailShareButton url={pageUrl} className="inline-block ml-5">
                        Email
                    </EmailShareButton>
                </div>
            </div>
        :<></>}
        </>
    )
}

export default SharePanel
