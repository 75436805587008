'use client'
import {useState, useEffect} from 'react'

interface ClearCartProps {
}

const ClearCart:React.FC<ClearCartProps>  = ({}) => {
    useEffect(()=>{
        localStorage?.setItem('savedCart', [] as any);
    }, [])
    return <></>
} 

export default ClearCart
