"use client";

import React, { useState, useCallback, useRef, forwardRef, useImperativeHandle } from 'react';
import Cropper from 'react-easy-crop';
import { useDropzone } from 'react-dropzone';
import { getCroppedImg } from './cropImage';

const ImageCropper = forwardRef((props: any, ref: any) => {
    const [imageSrc, setImageSrc] = useState(props.promotionImage ? props.promotionImage : null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);
    const [croppedImage, setCroppedImage] = useState<string | null>(props.promotionImage ? props.promotionImage : null);
    const [showModal, setShowModal] = useState(false);
    const fileRef = useRef<File | null>(null);

    const outputWidth = 516; // set the desired output width
    const outputHeight = 410; // set the desired output height

    useImperativeHandle(ref, () => ({
        getFile: () => fileRef.current,
    }));

    const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const showCroppedImage = useCallback(async () => {
        try {
            if (!imageSrc || !croppedAreaPixels) {
                // console.log("No image source or cropped area pixels");
                return;
            }
    
            const croppedFile = await getCroppedImg(
                imageSrc,
                croppedAreaPixels,
                outputWidth,
                outputHeight
            );
            // console.log('Cropped File:', croppedFile);
    
            const croppedImageUrl = URL.createObjectURL(croppedFile);
            setCroppedImage(croppedImageUrl);
            props?.setImage(croppedFile); // Use cropped file for the parent component
    
            // Update fileRef with the cropped file
            fileRef.current = croppedFile;
    
            setShowModal(false);
        } catch (e) {
            // console.log(e);
        }
    }, [croppedAreaPixels, imageSrc, outputWidth, outputHeight, props]);
    
    

    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            const reader = new FileReader();
            reader.readAsDataURL(acceptedFiles[0]);
            reader.onload = () => {
                setImageSrc(reader.result as string);
                setShowModal(true);
                fileRef.current = acceptedFiles[0];
            };
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: { 'image/*': [] }
    });

    const onFileChange = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = () => {
                setImageSrc(reader.result as string);
                setShowModal(true);
                fileRef.current = e.target.files[0];
            };
        }
    };

    const removeImage = () => {
        setImageSrc(null);
        setCroppedImage(null);
        setCroppedAreaPixels(null);
        fileRef.current = null;
    };

    return (
        <div className="flex flex-col items-center justify-center pt-4">
            {!croppedImage ? (
                <div {...getRootProps({ className: 'dropzone w-full border-dashed border-2 border-gray-300 flex flex-col items-center justify-center cursor-pointer p-4 rounded-md' })}>
                    <input {...getInputProps()} className="hidden" />
                    <p className="text-warmgrey text-[14px] font-norma font-medium text-center">
                        Drag an image here, or click to select one
                    </p>
                </div>
            ) : (
                <div {...getRootProps({ className: 'relative mt-4 w-full h-auto rounded-md overflow-hidden cursor-pointer' })}>
                    <input {...getInputProps()} className="hidden" />
                    <img src={croppedImage} alt="Cropped" className="max-w-full h-auto rounded-md" />
                    <button
                        className="absolute top-2 right-2 w-8 h-8 text-offwhite border-warmcharcoal border-[1px] bg-warmcharcoal rounded-md font-norma font-medium text-[14px]"
                        onClick={(e) => { e.stopPropagation(); removeImage(); }}
                    >
                    &times;
                    </button>
                </div>
            )}
            {showModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
                    <div className="bg-offwhite p-4 w-[40vw] h-[30vw]">
                        <div className="relative w-full h-[26vw]">
                            {imageSrc && (
                                <Cropper
                                    image={imageSrc}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={outputWidth / outputHeight} // Set aspect ratio for cropping
                                    onCropChange={setCrop}
                                    onZoomChange={setZoom}
                                    onCropComplete={onCropComplete}
                                    initialCroppedAreaPixels={{ x: 0, y: 0, width: outputWidth, height: outputHeight }} // Default crop size
                                />
                            )}
                        </div>
                        <div className="flex flex-col items-center mt-4">
                            <input
                                type="range"
                                min="1"
                                max="3"
                                step="0.1"
                                value={zoom}
                                onChange={(e) => setZoom(Number(e.target.value))}
                                className="w-full mb-4"
                            />
                            <div className="flex justify-end w-full mt-6 relative left-6">
                                <button
                                    className="px-4 py-2 text-warmcharcoal border-warmcharcoal border-[1px] bg-offwhite mr-2 font-norma font-medium text-[14px]"
                                    onClick={() => setShowModal(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="px-4 py-2 text-offwhite border-warmcharcoal border-[1px] bg-warmcharcoal mr-2 font-norma font-medium text-[14px]"
                                    onClick={showCroppedImage}
                                >
                                    Crop and Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
});


ImageCropper.displayName = "ImageCropper";

export default ImageCropper;
