'use client'
import { useState } from 'react'
import NextLink from 'next/link'
import RightArrow from '@/assets/icons/right-arrow.svg'
import CloseIcon from '@/assets/icons/close.svg'
import LoadingIcon from '../assets/icons/loading-icon.svg';

interface SearchMenuProps {
  showSearch?: any | null
  setShowSearch?: any | null
  lang?: any | null
}

const SearchMenu: React.FC<SearchMenuProps> = ({ showSearch, setShowSearch, lang }) => {
  const [searchResults, setSearchResults] = useState(null) as any
  const [searchTerm, setSearchTerm] = useState(null) as any
  const [loading, setLoading] = useState(false);

  const searchText = async (e: any, search_term: string) => {
    setSearchTerm(search_term)
    setSearchResults(null);
    setLoading(false)

    if (search_term.length > 2) {
      setLoading(true)
      const resultsSearch = await fetch('/api/search?term=' + search_term);
      const results = await resultsSearch.json();
      // console.log(results)
      setSearchResults(results?.hits)
      setLoading(false)
    }
    else {
      setSearchResults(null);
      setLoading(false)
    }
  }


  function stripHtmlTagsAndEntities(input: any) {
    // Replace <p> tags with a space before and after
    input = input.replace(/<\/?p[^>]*>/g, ' ');

    // Create a new DOM parser
    const parser = new DOMParser();
    
    // Parse the input as HTML
    const doc = parser.parseFromString(input, 'text/html');
    
    // Get the text content from the parsed document
    let text = doc.body.textContent || "";

    // Replace multiple spaces with a single space
    text = text.replace(/\s+/g, ' ').trim();

    return text;
}

  return (
    <div className="w-full font-norma">
      <CloseIcon className={"stroke-warmcharcoal absolute z-50 right-4 lg:right-[54px] top-4 md:top-[26px] cursor-pointer hover:opacity-60 "} onClick={() => {setShowSearch(false); document.body.style.overflow="visible"}} />
      <div className="px-4 md:px-12 lg:pl-[50px] lg:pr-[100px]">
        <h2 className="mt-24 md:mt-[170px] mb-12 md:mb-[110px] text-[21px] leading-[28px] font-sangbleu font-medium text-warmcharcoal">Search</h2>
        <div className="w-full max-w-[540px]">
          <input className="h-[42px] w-full px-4 focus:outline-none focus:ring-warmcharcoal focus:ring-[1px] text-[14px]" placeholder="Search item" onKeyUp={(e: any) => searchText(e, e.target.value)} />
        </div>
        <div className="font-norma">
          <div className="mt-8 overflow-y-scroll mb-12 max-h-[50vh] pr-4 scrollbar-hide">
            {searchResults && searchResults?.length > 0 && searchTerm !== "" ?
              <ul>
                {searchResults?.map((item: any, index: number) => {
                  if(item?.title?.length > 1) {
                  return (
                    <li className="text-[14px] hover:opacity-60 cursor-pointer" key={index}>
                      <NextLink href={`/${lang}/${item.slug}`} onClick={() => {setShowSearch(false); document.body.style.overflow="visible"}} >
                        <h3 className="text-[14px] font-medium text-warmcharcoal mt-6">{item.title} <RightArrow className="inline-block ml-2 rotate-180 stroke-warmcharcoal" /></h3>
                        <p>{typeof item.description === 'string' ? stripHtmlTagsAndEntities(item.description).substring(0, 110)+"...": ''}</p>
                      </NextLink>
                    </li>
                  )}
                })}
              </ul>
              : searchResults?.length === 0 ? <h3><br />Sorry no results for <em>{searchTerm}</em></h3> : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchMenu;
